export const dataHooks = {
  app: {
    root: 'appRootDataHook',
    header: 'appRootHeader',
    backBtn: 'app.backButton',
  },
  sso: {
    enterEmailDialog: {
      container: 'ssoEnterEmailDialog',
      emailInput: 'ssoEnterEmailDialogEmailInput',
      submitButton: 'ssoEnterEmailDialogSubmitButton',
    },
    emailAlreadyExistDialog: {
      container: 'emailAlreadyExistDialog',
    },
    mainDialog: {
      container: 'mainSsoDialog',
      title: 'mainSsoDialog.title',
      submitButton: 'mainSsoDialog.submitButton',
    },
  },
  dialogHeader: {
    container: 'dialogHeaderContainer',
    title: 'dialogHeaderTitle',
    backButton: 'dialogHeaderBackButton',
  },
  mainHeader: {
    container: 'mainHeaderContainer',
  },
  modalHeader: {
    container: 'modalHeaderContainer',
  },
  inputField: {
    default: 'inputField',
    visibilityIcon: 'inputField.visibilityIcon',
  },
  logo: {
    default: 'logoSvg',
  },
  approveAndContinueDialog: {
    container: 'approveAndContinueDialog',
    title: 'approveAndContinueDialog.title',
  },
  blockedAccountDialog: {
    container: 'blockedAccountDialog',
  },
  selectAccountDialog: {
    container: 'selectAccountDialog',
  },
  selectWorkspaceDialog: {
    container: 'selectWorkspaceDialog',
  },
  emailStep: {
    container: 'emailStepDialog',
    header: 'emailStep.header',
    title: 'emailStep.title',
    emailInput: 'emailStep.emailInput',
    submitButton: 'emailStep.submitButton',
    forgotEmailLink: 'emailStep.forgotEmailLink',
    continueToSsoLink: 'emailStep.continueToSsoLink',
  },
  login: {
    container: 'loginDialog',
    emailInput: 'login.emailInput',
    passwordInput: 'login.passwordInput',
    submitButton: 'login.submitButton',
    header: 'login.header',
    title: 'login.title',
    subtitle: 'login.subtitle',
    presetSubtitle: 'login.presetSubtitle',
    resendPopover: 'login.resend.popover',
    switchToSignupBtn: 'signup.switchToSignupBtn',
    forgotPassword: 'login.forgotPassword'
  },
  signup: {
    container: 'signupDialog',
    header: 'signup.header',
    title: 'signup.title',
    subtitle: 'signup.subtitle',
    presetSubtitle: 'signup.presetSubtitle',
    email: 'signup.email',
    confirmEmail: 'signup.confirmEmail',
    password: 'signup.password',
    confirmPassword: 'sigup.confirmPassword',
    submit: 'signup.submit',
    switchToLoginBtn: 'signup.switchToLoginBtn',
  },
  enterCode: {
    container: 'enetrCodeDialog',
    codeInput: 'enetrCodeDialogCodeInput',
    verifyButton: 'enetrCodeDialogVerifyButton',
    resendButton: 'enetrCodeDialogResendButton',
  },
  resetPassword: {
    container: 'resetPassword.container',
    password: 'resetPassword.passwordField',
    rePassword: 'resetPassword.rePasswordField',
  },
  resetPasswordConfirmation: {
    container: 'resetPasswordConfirmation.container',
  },
  resetPasswordInvalid: {
    container: 'resetPasswordInvalid.container',
    actionLink: 'resetPasswordInvalid.actionLink',
    notification: 'resetPasswordInvalid.notification',
  },
  enterPassword: {
    container: 'enterPassword.container',
    password: 'enterPassword.passwordField',
    submitButton: 'enterPassword.submitButton',
  },
  accountVerification: {
    container: 'accountVerification.container',
  },
  enterEmailForSocial: {
    container: 'enterEmailForSocial.container',
  },
  postLogin: {
    common: {
      modalContainer: 'postLogin.modalContainer',
      header: 'postLogin.ConfirmDetails.header',
      sideActionTextButton: 'postLogin.sideActionTextButton',
      accountSettingsTextButton: 'postLogin.accountSettingsTextButton',
      verifyCodeContainer: 'postLogin.verifyCodeContainer',
      skip: 'postLogin.skip',
    },
    confirmEmail: {
      email: 'postLogin.ConfirmDetails.email',
      changeEmailLink: 'postLogin.ConfirmDetails.changeEmailLink',
    },
    confirmDetails: {
      editEmailButton: 'postLogin.confirmDetails.editLinkButton',
    },
    changeAccountEmail: {
      emailInput: 'postLogin.changeAccountEmail.emailInput',
      confirmEmailInput: 'postLogin.changeAccountEmail.confirmEmailInput',
    },
  },
  floatingNotification: {
    main: 'mainFloatingNotification',
  },
  policiesSection: {
    container: 'policiesSection.container',
    minimalContent: 'policiesSection.minimal.content',
    expandedContent: 'policiesSection.expanded.content',
    recaptchaSection: 'policiesSection.recaptcha',
  },
  loadingDialog: {
    container: 'loadingDialog.container',
  },
  deviceLogin: {
    container: 'deviceLoginDialog',
    title: 'deviceLoginTitle',
    bodyContent: 'deviceLoginBodyContent',
    codeInput: 'deviceLoginCodeInput',
    footerContent: 'deviceLoginBodyFooter',
    connect: 'deviceLoginConnectCta',
    anotherAccountCta: 'deviceLoginAnotherAccountCta',
  },
  connectionSuccessful: {
    title: 'connectionSuccessful.Title',
    disconnect: 'connectionSuccessful.Disconnect',
  },
  disconnectSuccessful: {
    title: 'disconnectSuccessful.Title',
    customerCareLink: 'disconnectSuccessful.customerCareLink',
  },
  socials: {
    googleBtn: 'socials.googleBtn',
    facebookBtn: 'socials.facebookBtn',
    appleBtn: 'socials.appleBtn',
    googleIcon: 'socials.googleIcon',
    facebookIcon: 'socials.facebookIcon',
    appleIcon: 'socials.appleIcon',
  },
  loginEmailStep: {
    switchToSignupBtn: 'loginEmailStep.switchToSignupBtn',
  },
  signupEmailStep: {
    switchToLoginBtn: 'signupEmailStep.switchToLoginBtn',
  },
  ownerAppModal: {
    main: 'ownerAppModal',
    options: 'ownerAppModal.options',
    deviceName: 'ownerAppModal.deviceName',
  },
};
