import { X } from '@wix/wix-ui-icons-common';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Text,
  TextButton,
} from '@wix/design-system';
import { AppContextProvider } from '../AppLoader';
import s from './TransitionToStudioModal.scss';

export const TransitionToStudioModal: FC<{}> = observer(() => {
  const {
    rootStore: {
      displayStore: { isMobile },
      socialAuthStore,
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  const redirectToDiscoverWixStudio = () => {
    window.location.href = 'https://www.wix.com/studio';
  };
  return (
    <Modal isOpen={socialAuthStore.showEditorXSignupDisabledModal}>
      <div className={s.signupToStudioModal}>
        <div
          className={classNames(s.transitionToStudioWrapper, {
            [s.mobile]: isMobile,
          })}
        >
          <div className={s.topDecorator} />
          <div className={s.bodyWrapper}>
            <div className={s.contentWrapper}>
              <h1 className={s.transitionToStudioTitle}>
                {t('trantision.to.studio.title')}
              </h1>
              <span className={s.transitionToStudioContent}>
                {t('trantision.to.studio.content')}
              </span>
              <div className={s.discoverStudioBtnWrapper}>
                <Button
                  className={s.discoverStudioBtn}
                  onClick={redirectToDiscoverWixStudio}
                >
                  {t('discover.wix.studio')}
                </Button>
              </div>
              <div className={s.editorXLogin}>
                <Trans
                  i18nKey="transition_to_studio.editor_x_login"
                  shouldUnescape={false}
                >
                  <Text className={s.editorXLoginText} size="small">
                    1
                  </Text>
                  <TextButton
                    size="small"
                    className={s.editorXLoginLink}
                    skin="dark"
                    underline="always"
                    href="https://users.editorx.com/signin"
                    as="a"
                  >
                    2
                  </TextButton>
                </Trans>
              </div>
            </div>
            <div className={s.sideDecorator}>
              <IconButton
                className={s.closeBtn}
                size="large"
                priority="primary"
                skin="inverted"
                onClick={() => {
                  socialAuthStore.showEditorXSignupDisabledModal = false;
                }}
              >
                <X />
              </IconButton>
              <div className={s.bottom}>
                <Box className={s.circle} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});
