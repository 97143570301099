import { action, makeObservable, computed } from 'mobx';
import { RootStore } from './root';
import { CUSTOMER_CARE_BLOCKED_ACCOUNT_BOT_URL } from './constants';
import { blockedAccountPageCtaClicksSrc5Evid378 } from '@wix/bi-logger-identity-data/v2';

export class BlockedAccountStore {
  private readonly rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      titleKey: computed,
      subtitleKeys: computed,
      buttonKey: computed,
      onBackButtonClicked: action.bound,
    });
  }

  public onBackButtonClicked() {
    const { navigationStore } = this.rootStore;
    this.rootStore.biLogger.report(blockedAccountPageCtaClicksSrc5Evid378({
      button_clicked: 'Back',
    }))
    return navigationStore.goBack();
  }

  public contactCustomerCare() {
    const { navigationStore } = this.rootStore;
    this.rootStore.biLogger.report(blockedAccountPageCtaClicksSrc5Evid378({
      button_clicked: 'Contact customer care',
    }))
    return navigationStore.redirect(CUSTOMER_CARE_BLOCKED_ACCOUNT_BOT_URL);
  }

  get titleKey(): string {
    return 'blockedAccount.title';
  }

  get subtitleKeys(): string[] {
    return ['blockedAccount.subtitle.fstSection', 'blockedAccount.subtitle.sndSection'];
  }

  get buttonKey(): string {
    return 'blockedAccount.button.contactCustomerCare';
  }
}
