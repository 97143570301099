import { observable, action, makeObservable } from 'mobx';
import { ModalTheme } from '@wix/design-system/dist/types/BaseModalLayout';
import { i18n as i18nType } from 'i18next';

interface IOpen {
  title: string;
  content: string | JSX.Element;
  okTitle?: string;
  cancelTitle?: string;
  newStyleTheme?: ModalTheme;
  onOk?(close: () => void): void;
  onClose?(): void;
  onX?(): void;
  sideActions?: JSX.Element;
  className?: string;
}

export class ConfirmationModalStore {
  public isOpen: boolean = false;
  public title?: string;
  public content?: string | JSX.Element;
  public okTitle?: string;
  public cancelTitle?: string;
  public newStyleTheme?: ModalTheme;
  public onOk?: (close: () => void) => void;
  public onClose: () => void;
  public onX: () => void;
  public sideActions?: JSX.Element;
  public className?: string;

  constructor(private i18n: i18nType) {
    this.close = this.close.bind(this);
    makeObservable(this, {
      isOpen: observable,
      open: action,
      close: action,
    });
  }

  public open({
    title,
    content,
    okTitle = this.i18n.t('confirmationModal.default.confirm'),
    cancelTitle = this.i18n.t('confirmationModal.default.cancel'),
    onOk = (close) => close(),
    onClose = this.close,
    onX = this.onX,
    newStyleTheme = 'standard',
    sideActions,
    className,
  }: IOpen) {
    this.title = title;
    this.content = content;
    this.okTitle = okTitle;
    this.cancelTitle = cancelTitle;
    this.newStyleTheme = newStyleTheme;
    this.onOk = onOk;
    this.onClose = onClose;
    this.onX = onX ?? onClose;
    this.isOpen = true;
    this.sideActions = sideActions;
    this.className = className;
  }

  public close() {
    this.isOpen = false;
    this.title = undefined;
    this.content = undefined;
    this.okTitle = undefined;
    this.cancelTitle = undefined;
    this.newStyleTheme = undefined;
    this.onOk = undefined;
    this.onClose = this.close;
    this.onX = this.close;
    this.sideActions = undefined;
    this.className = undefined;
  }
}
