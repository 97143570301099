import { InputFieldWithLabel } from '../InputField';
import React, { useContext, useEffect } from 'react';
import s from './DeviceLogin.scss';
import { AppContextProvider } from '../AppLoader';
import { Box } from '@wix/design-system';
import { dataHooks } from '../../dataHooks';
import { DialogFormContainer } from '../DialogContainer';
import { keys } from '../../translationsKeys';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import { ThemedHeading, ThemedButton, ThemedText } from '../ThemedComponents';
import { ButtonWithLoader } from '../ButtonWithLoader/ButtonWithLoader';
import { ROUTES } from '../../routes';

interface DeviceLoginProps {}

export const DeviceLogin: React.FC<DeviceLoginProps> = observer(() => {
  const {
    rootStore: {
      deviceLoginStore,
      navigationStore: { curryNavigate: navigate },
      displayStore: { isMobile },
      userDataStore,
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  const subtitle_font_size = 'medium';
  const email_font_size = 'small';
  return (
    <Box direction="vertical" width="100%" align="center">
    <DialogFormContainer
      dataHook={dataHooks.deviceLogin.container}
      formClasses={[s.deviceLoginConnect]}
      mainHeaderProps={{showBackButton: false}}
    >
      <ThemedHeading appearance={isMobile ? "H2" : "H1"} dataHook={dataHooks.deviceLogin.title}>
        {t(keys['device.login.connect.code.title'])}
      </ThemedHeading>
      <Box
        marginBottom="40px"
        marginTop={1}
        textAlign="center"
        display="grid"
        width="100%"
      >
        <ThemedText size={subtitle_font_size}>
          {t(keys['device.login.connect.code.content.first'])}
        </ThemedText>
        <ThemedText size={subtitle_font_size}>
          {t(keys['device.login.connect.code.content.second'])}
        </ThemedText>
      </Box>
      <Box direction="vertical" align="center" className={s.codeInput}>
        <InputFieldWithLabel
          required
          formField={deviceLoginStore.code}
          label={t(keys['device.login.connect.code.label'])}
          type="text"
          marginBottom={0}
          wrapperClassName={s.emailInput}
          dataHook={dataHooks.deviceLogin.codeInput}
        />
      </Box>
      <Box marginBottom={0} direction="vertical" verticalAlign="middle">
        <Trans
          i18nKey={deviceLoginStore.contentKey}
          values={{ currentLoggedInEmail: deviceLoginStore?.currentLoggedInEmail }}
        >
          <ThemedText size={email_font_size}>
            You’re currently logged in as
          </ThemedText>
          <ThemedText size={email_font_size} weight="bold">
            email
          </ThemedText>
        </Trans>
      </Box>
      <Box verticalAlign="middle" paddingTop="36px">
        <Box
          gap="SP2"
          verticalAlign="middle"
          display={isMobile ? 'grid' : 'flex'}
          width="100%"
        >
          <ThemedButton
            size="large"
            onClick={() => {
              navigate(
                ROUTES.LOGIN_PASSWORD +
                  `?redirectTo=${
                    window.location.origin + '/login' + ROUTES.DEVICE_LOGIN
                  }`,
              )();
            }}
            priority="secondary"
            skin='standard'
            dataHook={dataHooks.deviceLogin.anotherAccountCta}
          >
            {t(keys['device.login.connect.another.account.button'])}
          </ThemedButton>
          <ButtonWithLoader
            className={s.connectButton}
            disabled={!deviceLoginStore.code.value.length}
            onClick={deviceLoginStore.submit}
            dataHook={dataHooks.deviceLogin.connect}
            skin='standard'
          >
            {t(keys['device.login.connect.button'])}
          </ButtonWithLoader>
        </Box>
      </Box>
    </DialogFormContainer>
</Box>
  );
});
