import { observer } from 'mobx-react';
import { DialogFormContainer } from '../DialogContainer';
import React, { FC } from 'react';
import { Box } from '@wix/design-system';
import { OwnerApp2FAMethod } from './OwnerApp2FAMethod';

export const OwnerApp2FA: FC = observer(() => {
  return (
    <Box direction="vertical" width="100%" align="center">
      <DialogFormContainer
        mainHeaderProps={{ showBackButton: false }}
        direction="vertical"
      >
        <OwnerApp2FAMethod />
      </DialogFormContainer>
    </Box>
  );
});
