import { action, computed, makeObservable, observable } from 'mobx';
import { getEmailSubmit } from '@wix/bi-logger-hls2/v2';
import { RootStore } from './root';
import { ERROR_CODES, extractErrorKeyByErrorCode } from '../utils/errorHandler';
import { EnterEmailStore } from './enterEmail';
import { EXPERIMENTS } from './constants';

export class PostSocialLoginEnterEmailStore extends EnterEmailStore {
  facebookWith2FaNotificationOpen: boolean;
  showErrorMessageForFacebook2FaUsers: boolean = false;
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      submit: action,
      enableAccess: computed,
      facebookWith2FaNotificationOpen: observable,
    });
    this.showErrorMessageForFacebook2FaUsers = rootStore.experiments.enabled(
      EXPERIMENTS.SHOW_ERROR_MESSAGE_FOR_FACEBOOK2_FA_USERS,
    );
  }

  public async goToLoginWithEmail(email: string) {
    this.facebookWith2FaNotificationOpen = false;
    this.rootStore.facebookLoginStore.clearErrors();
    this.rootStore.userDataStore.setEmail(email);
    this.rootStore.loginStore.onNavigateToLogin();
  }

  public async submit() {
    if (this.emailField.errorInfo || this.emailField.serverError) {
      return;
    }
    try {
      const store = this.rootStore.getActiveSocialProviderStore();
      this.rootStore.biLogger.report(
        getEmailSubmit({ socialNetwork: store.provideId }),
      );
      const { errorCode, success } = await store.continueLoginToWix({
        email: this.emailField.value,
      });
      if (!success && errorCode) {
        if (
          this.showErrorMessageForFacebook2FaUsers &&
          errorCode ===
            ERROR_CODES.SOCIAL_MERGE_NOT_ALLOWED_USER_HAS_ENABLED_2FA
        ) {
          this.facebookWith2FaNotificationOpen = true;
          return;
        }
        return this.emailField.addError(
          this.rootStore.i18n.t(extractErrorKeyByErrorCode(errorCode)),
        );
      }
      this.rootStore.userDataStore.setEmail(this.emailField.value);
    } catch (error: any) {
      // TODO handle error
      const errorCode = error?.errorCode ?? error?.response?.data?.errorCode;
      throw errorCode;
    }
  }

  get enableAccess(): boolean {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search)
    const debugCase = urlParams.get('debugCase');
    const isDebug = debugCase === 'socialEnterEmail';
    const isDebugFacebook = debugCase === 'socialFacebookEnterEmail';
    if (isDebugFacebook) {
      this.rootStore.activeSocialProvider = 'facebook';
    }
    return !!this.rootStore.activeSocialProvider || isDebug || isDebugFacebook;
  }
}
