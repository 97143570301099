import { action, computed, makeObservable, observable, autorun } from 'mobx';
import { ERROR_CODES, extractServerErrorCode } from '../utils/errorHandler';
import { RootStore } from './root';
import { AuthenticateByCodeStore } from './authenticateByCode';
import { TWO_FA_METHODS_MAP } from './enterCode';
import {
  loginTwoFactorAuthenticationVerifyClick,
  loginTwoFactorAuthenticationVerifySuccess,
  loginTwoFactorAuthenticationVerifyFail,
  loginTwoFactorAuthenticationResendCodeClick,
  loginTwoFactorAuthenticationLostPhoneAccessClick,
} from '@wix/bi-logger-hls2/v2';
import {
  LOST_ACCESS_TO_EMAIL_ARTICLE_URL,
  LOST_ACCESS_TO_PHONE_ARTICLE_URL,
  TwoFactorAuthMethods,
  TwoFactorAuthPhoneDeliveryMethods,
  twoFAProcessMethod,
  TWOFA_PROCESS,
} from '../utils/constants';
import { ApprovalChallengeStatus } from '@wix/ambassador-identity-v1-verification/types';
import { useContext } from 'react';
import { AppContextProvider } from '../components/AppLoader';

const POLLING_INTERVAL = 2000;

export class TwoFactorAuthStore extends AuthenticateByCodeStore {
  twoFAToken: string;
  verificationId: string | undefined | null;
  uaToken: string | undefined;
  deviceName: string;
  userId: string;
  usingTwoFAFallback: boolean;
  allowRememberMe: boolean = true;
  lfndForce2FA?: boolean;
  dialogTitleKey: string = 'enter_code.title';
  twoFactorAuthStatus: ApprovalChallengeStatus =
  ApprovalChallengeStatus.PENDING_APPROVAL;
  pollingTimer: NodeJS.Timeout | null = null;

  private urlParams: URLSearchParams = new URLSearchParams(
    window.location.search
  );
  private isDebug: boolean = this.urlParams.get('debugCase') === 'mfa';
  private testPayload: Partial<TwoFactorAuthPayload> = {
    currentTwoFAMethod: this.urlParams.get('method'),
    enabledTwoFAMethods: this.urlParams.get('enabled')?.split(','),
    twoFAHint: this.urlParams.get('hint') ?? '',
  };

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.SUBMIT_INTERACTION_NAME = 'verify-code-2fa';
    this.isDebug && this.setAuthParams(this.testPayload);
    makeObservable(this, {
      currentTwoFAMethod: observable,
      enabledTwoFAMethods: observable,
      twoFAHint: observable,
      twoFAToken: observable,
      usingTwoFAFallback: observable,
      twoFactorAuthStatus: observable,
      showMethodsSwitch: computed,
      setAuthParams: action,
      verify: action,
      resendCode: action,
      onLostAccess: action.bound,
    });
  }

  setAuthParams(payload: Partial<TwoFactorAuthPayload>) {
    this.twoFAHint = payload.twoFAHint!;
    this.twoFAToken = payload.twoFAToken!;
    this.usingTwoFAFallback = this.isDebug
      ? false
      : !!payload.usingTwoFAFallback;
    if (this.isDebug) {
      this.currentTwoFAMethod = this.urlParams.get('method') as any;
    } else {
      this.currentTwoFAMethod =
        this.usingTwoFAFallback || !payload.currentTwoFAMethod
          ? TwoFactorAuthMethods.Email
          : payload.currentTwoFAMethod;
    }
    this.rootStore.fedopsLogger.interactionStarted(
      twoFAProcessMethod(this.currentTwoFAMethod)
    );
    this.enabledTwoFAMethods = payload.enabledTwoFAMethods?.reverse() ?? [
      this.currentTwoFAMethod,
    ];
    this.verificationId = payload.verificationId;
    this.uaToken = payload.uaToken;
    this.deviceName = this.getDeviceName(payload.twoFAHint!);
    this.lfndForce2FA = payload.lfndForce2FA;
  }

  getDeviceName(twoFAHint: string) {
    try {
      const { deviceBrandName, deviceModelName } = JSON.parse(twoFAHint);
      return `${deviceBrandName} ${deviceModelName}`;
    } catch (error) {
      return '';
    }
  }

  onResendCodeClicked(
    options: { phoneDeliveryMethod?: string } = {
      phoneDeliveryMethod: TwoFactorAuthPhoneDeliveryMethods.SMS,
    }
  ) {
    this.rootStore.biLogger.report(
      loginTwoFactorAuthenticationResendCodeClick({
        flow_type: this.currentTwoFAMethod,
        tfa_type: 'login',
        reason: this.lfndForce2FA ? 'LFND' : 'login'
      })
    );
    return this.resendCode(options);
  }

  getResendButtonKey() {
    if (this.twoFactorAuthStatus === ApprovalChallengeStatus.PENDING_APPROVAL) {
      return 'twoFactorAuth.resendNotification';
    }

    return 'twoFactorAuth.resendLoginRequest';
  }

  async resendCode(
    options: { phoneDeliveryMethod?: string } = {
      phoneDeliveryMethod: TwoFactorAuthPhoneDeliveryMethods.SMS,
    }
  ) {
    const { phoneDeliveryMethod } = options;

    try {
      const data = await this.rootStore.apiStore.resendSecondFactorCode({
        twoFAToken: this.twoFAToken,
        twoFAMethod: this.currentTwoFAMethod,
        phoneDeliveryMethod,
      });

      if (data?.payload?.currentTwoFAMethod === TwoFactorAuthMethods.Push) {
        this.setAuthParams(data.payload);
        this.setTwoFactorAuthStatus(ApprovalChallengeStatus.PENDING_APPROVAL);
        this.startPolling();
      }

      if (data?.payload?.twoFAHint) {
        this.twoFAHint = data.payload?.twoFAHint;
      }

      if (data?.payload?.twoFAToken) {
        this.twoFAToken = data?.payload?.twoFAToken ?? '';
      } else {
        return extractServerErrorCode(data);
      }
    } catch (error: any) {
      return extractServerErrorCode(error);
    }
  }

  backToLogin() {
    this.rootStore.navigationStore.goToMainLogin({
      email: this.rootStore.userDataStore.email,
    });
  }

  resendOwnerAppNotification = async () => {
    await this.rootStore.apiStore.resendSecondFactorCode({
      twoFAToken: this.twoFAToken,
      twoFAMethod: this.currentTwoFAMethod,
    });
    this.startPolling();
  };

  verify({ code, rememberMe }: { code: string; rememberMe?: boolean }) {
    this.rootStore.biLogger.report(
      loginTwoFactorAuthenticationVerifyClick({
        remember_this_device: rememberMe,
        flow_type: this.currentTwoFAMethod,
        tfa_type: 'login',
      })
    );
    this.rootStore.fedopsLogger.interactionStarted(
      this.SUBMIT_INTERACTION_NAME
    );
    return this.rootStore.apiStore
      .verifyCodeAndLogin({
        twoFAMethod: this.currentTwoFAMethod,
        twoFAToken: this.twoFAToken,
        code,
        rememberMe,
      })
      .then((data) => {
        if (data.success) {
          this.rootStore.fedopsLogger.interactionEnded(
            this.SUBMIT_INTERACTION_NAME
          );
          this.rootStore.fedopsLogger.interactionEnded(TWOFA_PROCESS);
          this.rootStore.fedopsLogger.interactionEnded(
            twoFAProcessMethod(this.currentTwoFAMethod)
          );
          this.rootStore.biLogger.report(
            loginTwoFactorAuthenticationVerifySuccess({
              flow_type: this.currentTwoFAMethod,
              reason: this.lfndForce2FA ? 'LFND' : 'login'
            })
          );
          this.rootStore.navigationStore.postLogin(data?.payload);
          return;
        }
        this.rootStore.biLogger.report(
          loginTwoFactorAuthenticationVerifyFail({
            flow_type: this.currentTwoFAMethod,
            reason: this.lfndForce2FA ? 'LFND' : 'login'
      })
        );
        this.handleError(extractServerErrorCode(data));
      })
      .catch(() => {
        this.handleError(ERROR_CODES.GENERAL_ERROR_CODE);
      });
  }

  showResendButton() {
    return this.currentTwoFAMethod !== TwoFactorAuthMethods.TOTP;
  }

  getTitleKey() {
    if (this.lfndForce2FA) {
      return 'enter_code.lfnd.title';
    }
    return this.usingTwoFAFallback
      ? 'enter_code.fallback.title'
      : 'enter_code.title';
  }

  getDescriptionKey() {
    if (this.lfndForce2FA) {
      return 'enter_code.lfnd.description';
    }
    return this.usingTwoFAFallback
      ? 'enter_code.fallback.description'
      : `enter_code.${TWO_FA_METHODS_MAP[this.currentTwoFAMethod]}.description`;
  }

  enableAccess() {
    return !!(this.twoFAToken && this.currentTwoFAMethod) || this.isDebug;
  }

  onLostAccess() {
    const { language } = this.rootStore.i18n;
    this.rootStore.biLogger.report(
      loginTwoFactorAuthenticationLostPhoneAccessClick({
        flow_type: this.currentTwoFAMethod,
      })
    );
    this.rootStore.navigationStore.redirect(
      this.currentTwoFAMethod === TwoFactorAuthMethods.Email
        ? LOST_ACCESS_TO_EMAIL_ARTICLE_URL(language)
        : LOST_ACCESS_TO_PHONE_ARTICLE_URL(language)
    );
  }

  public async getApprovalChallengeStatus(): Promise<
    ApprovalChallengeStatus | undefined
  > {
    try {
      if (!this.verificationId) {
        return undefined;
      }
      const { challenge } = await this.rootStore.apiStore.getChallenge(
        this.verificationId,
      );
      return challenge?.approvalChallenge?.status;
    } catch (error) {
      return undefined;
    }
  }

  get showMethodsSwitch() {
    return !this.usingTwoFAFallback && this.enabledTwoFAMethods?.length! > 1;
  }

  setTwoFactorAuthStatus(status: ApprovalChallengeStatus) {
    this.twoFactorAuthStatus = status;
  }

  async checkStatus() {
    const verificationStatus = await this.getApprovalChallengeStatus();
    if (verificationStatus === ApprovalChallengeStatus.APPROVED) {
      this.stopPolling();
      const { payload: uaToken } =
        await this.rootStore.apiStore.verifyCodeAndLogin({
          twoFAMethod: this.currentTwoFAMethod,
          twoFAToken: this.twoFAToken,
          rememberMe: false,
        });
      await this.rootStore.navigationStore.postLogin({
        uaToken,
      });
    }
    if (verificationStatus === ApprovalChallengeStatus.EXPIRED) {
      this.setTwoFactorAuthStatus(ApprovalChallengeStatus.EXPIRED);
      this.stopPolling();
    }
    if (verificationStatus === ApprovalChallengeStatus.DECLINED) {
      this.setTwoFactorAuthStatus(ApprovalChallengeStatus.DECLINED);
      this.stopPolling();
    }
  }

  public startPolling() {
    this.stopPolling();
    this.pollingTimer = setInterval(() => {
      this.checkStatus();
    }, POLLING_INTERVAL);
  }

  public stopPolling() {
    if (this.pollingTimer) {
      clearInterval(this.pollingTimer);
      this.pollingTimer = null;
    }
  }
}

export const useTwoFactorAuth = () => {
  const {
    rootStore: { twoFactorAuthStore, biLogger },
  } = useContext(AppContextProvider);

  return { store: twoFactorAuthStore, biLogger };
};
