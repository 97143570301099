import React from 'react';
import {
  FloatingNotification as WSRFloatingNotification,
  FloatingNotificationType,
} from '@wix/design-system';
import { observer } from 'mobx-react';
import { dataHooks } from '../../dataHooks';
import { NotificationStore } from '../../utils/notification';
import s from '../App/App.scss';

interface INotification {
  store?: NotificationStore;
}

export const FloatingNotification: React.FC<INotification> = observer(
  ({ store }) => {
    if (!store?.isOpen) {
      return null;
    }

    return (
      <WSRFloatingNotification
        className={s.floatingNotification}
        dataHook={dataHooks.floatingNotification.main}
        type={store.theme as FloatingNotificationType}
        onClose={store.onClose}
        text={store.content}
        showCloseButton
      />
    );
  },
);
