import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { Input, InputWithLabel, InputWithLabelProps } from '@wix/design-system';
import { ThemedInputLabel } from '../ThemedComponents';
import classNames from 'classnames';
import s from '../InputField/InputField.scss';
import { PasswordInput } from '@wix/identity-fed-common';
import { PasswordInputProps } from '@wix/identity-fed-common/dist/types/password-input/components/password-input/PasswordInput';

export type CustomInputProps = InputWithLabelProps &
  PasswordInputProps & {
    inputWithLabel?: boolean;
    submitOnEnter?: boolean;
  };

const CustomInputCore: React.ForwardRefRenderFunction<any, CustomInputProps> = (
  {
    onBlur,
    onFocus,
    status,
    onEnterPressed,
    border,
    inputWithLabel,
    label,
    submitOnEnter = true,
    ...props
  },
  ref
) => {
  const [inputEl, setInputEl] = useState<HTMLInputElement | null>(null);
  const InputElement = getInputElement(inputWithLabel, props.type);

  const onInputEnterPressed = useCallback(
    (e?: React.KeyboardEvent<HTMLInputElement>) => {
      e?.preventDefault();
      e && onEnterPressed?.(e);
      if (submitOnEnter) {
        const submitButton: any = document.querySelector('[type="submit"]');
        submitButton?.click();
        if (props?.statusMessage) {
          inputEl?.focus();
        }
      }
    },
    [onEnterPressed, submitOnEnter, inputEl, props?.statusMessage]
  );
  const isBottomLine = border === 'bottomLine';

  return (
    <InputElement
      // The only reason I do two class names for the same prediction is to override WSR css priority
      className={classNames({
        [s.bottomLine]: isBottomLine,
        [s.priorityOnly]: isBottomLine,
      })}
      inputRef={(ref) => setInputEl(ref)}
      {...props}
      ref={ref}
      onEnterPressed={(e) => {
        inputEl?.blur();
        onInputEnterPressed(e);
      }}
      status={status}
      onFocus={onFocus}
      onBlur={onBlur}
      border={border}
      inputWithLabel={inputWithLabel}
      {...(inputWithLabel && label
        ? { label: <ThemedInputLabel>{label}</ThemedInputLabel> }
        : ({} as any))}
    />
  );
};

const getInputElement = (inputWithLabel?: boolean, type?: string) => {
  if (type === 'password') {
    return PasswordInput as any;
  }
  return inputWithLabel ? InputWithLabel : Input;
};

export const CustomInput = observer(React.forwardRef(CustomInputCore));
