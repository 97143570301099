import React, { FC, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { DialogHeader } from '../DialogHeader/DialogHeader';
import { Box, Image } from '@wix/design-system';
import ownerApp2FAPending from '../../assets/images/ownerApp2FAPending.svg';
import { OwnerApp2FAResendButton } from '../OwnerApp2FAResendButton/OwnerApp2FAResendButton';
import { ThemedText } from '../ThemedComponents';
import { useTwoFactorAuth } from '../../stores/twoFactorAuth';
import { MoreMethods } from '../OwnerApp2FA/OwnerApp2FAMoreMethods';

export const OwnerApp2FAPending: FC = () => {
  const { store } = useTwoFactorAuth();
  const { t } = useTranslation();

  useEffect(() => {
    store.startPolling();

    return () => {
      store.stopPolling();
    };
  }, [store]);

  const deviceName =
    store.deviceName || t('ownerAppTwoFactorAuth.pending.unknownDevice');

  return (
    <>
      <DialogHeader>
        <DialogHeader.Title as="h3">
          {t('ownerAppTwoFactorAuth.pending.title')}
        </DialogHeader.Title>
        <DialogHeader.SubTitleSmall size="medium" weight="thin">
          <Trans
            i18nKey="ownerAppTwoFactorAuth.pending.description"
            values={{ deviceName }}
          >
            <ThemedText>A login request was sent to:</ThemedText>
            <ThemedText weight="bold">deviceName</ThemedText>
            <ThemedText>Approve to log in.</ThemedText>
          </Trans>
        </DialogHeader.SubTitleSmall>
        <Box
          direction="vertical"
          margin="58px 0 21px 0"
          align="center"
          gap="18px"
        >
          <Image src={ownerApp2FAPending} transparent width="120px" />
          <OwnerApp2FAResendButton />
        </Box>
      </DialogHeader>
      <Box direction="vertical" align="center" gap={0} width="100%">
        <MoreMethods />
      </Box>
    </>
  );
};
