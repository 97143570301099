import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Box, FormField, RadioGroup, Text, Tooltip } from '@wix/design-system';
import { AppContextProvider } from '../../../AppLoader';
import { dataHooks } from '../../../../dataHooks';
import { BackupMethods } from '../../../../stores/premiumUsers2FaEnforcement/ownerApp/ownerApp';
import { InfoCircle } from '@wix/wix-ui-icons-common';

export const BackupMethodStep: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { ownerAppStore: store },
      },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  return (
    <Box padding="0 6px">
      <FormField
        label={
          <Box direction="horizontal">
            <Text size="medium" weight="normal">
              {t('addOwnerApp.wizard.backupMethod.label')}
            </Text>
            <Tooltip
              maxWidth="289px"
              placement="top"
              content={t('addOwnerApp.wizard.backupMethod.tooltip')}
            >
              <InfoCircle style={{ color: '#116dff' }} />
            </Tooltip>
          </Box>
        }
      >
        <RadioGroup
          dataHook={dataHooks.ownerAppModal.options}
          value={store.selectedBackupMethod}
          onChange={(type?: number | string) =>
            store.onSelectBackupMethod(type)
          }
        >
          {Object.values(BackupMethods)
            .filter((key) => {
              return (
                !store.premiumUsers2FaEnforcementStore.multiFactorAuth.Email
                  .isDomainRestricted || key !== BackupMethods.EMAIL
              );
            })
            .map((type, index) => (
              <RadioGroup.Radio key={index} value={index}>
                <Text
                  weight={
                    store.selectedBackupMethod === index ? 'normal' : 'thin'
                  }
                  secondary={store.selectedBackupMethod !== index}
                  color="dark"
                >
                  {t(`addOwnerApp.wizard.backupMethod.${type}`)}
                </Text>
              </RadioGroup.Radio>
            ))}
        </RadioGroup>
      </FormField>
    </Box>
  );
});
