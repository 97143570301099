import { ERROR_CODES } from '../utils/errorHandler';
import { RootStore } from './root';

export const CAPTCHA_ACTIONS = {
  GET_USER_ACCOUNTS: 'GET_USER_ACCOUNTS',
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  FORGOT: 'FORGOT',
};

export class CaptchaStore {
  private readonly rootStore: RootStore;
  readonly RECAPTCH_ENTERPRISE_ELEMENT_ID = 'recaptcha-widget';
  readonly showCaptchaOnGteUserAccounts: boolean;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.showCaptchaOnGteUserAccounts = this.rootStore.experiments.enabled(
      'specs.ident.ExecuteCaptchaOnGetUserAccountsByEmail',
    );
  }

  get invisibleCaptchaKey() {
    return window.__RECAPTCHA_INVISIBLE_KEY__;
  }

  get enterpriseCaptchaKey() {
    return window.__RECAPTCHA_ENTERPRISE_KEY__;
  }

  get captcha() {
    return window.grecaptcha?.enterprise;
  }

  createOrResetCaptchaIfNeeded(
    serverError: string | number,
    captchaAdded: boolean,
  ): boolean {
    if (captchaAdded) {
      this.reload();
      return true;
    } else if (serverError && this.isCaptchaServerError(serverError)) {
      this.renderReCaptchaEnterprise({});
      return true;
    } else {
      return false;
    }
  }

  async handleRecaptchaExecution({
    captchaAdded,
    action,
  }: {
    captchaAdded: boolean;
    action: string;
  }) {
    if (
      !this.showCaptchaOnGteUserAccounts &&
      action === CAPTCHA_ACTIONS.GET_USER_ACCOUNTS
    ) {
      return;
    }
    const invisibleRecaptcha = await this.executeInvisibleCaptcha(action).catch(
      () => undefined,
    );
    const recaptchaParams = captchaAdded ? this.recaptchaParams() : undefined;

    return { invisibleRecaptcha, ...recaptchaParams };
  }

  recaptchaParams(): RecaptchaParams {
    return {
      'recaptchaParams.recaptcha_response_field': this.captcha?.getResponse(),
      'recaptchaParams.recaptchaServerType': 'GOOGLE_ENTERPRISE',
    };
  }

  reload() {
    this.captcha?.reset();
  }

  executeInvisibleCaptcha(action: string) {
    return this.captcha?.execute(this.invisibleCaptchaKey, { action });
  }

  isCaptchaServerError = (serverError: string | number) =>
    serverError.toString() === ERROR_CODES.CAPTCHA_FAILED ||
    serverError.toString() === ERROR_CODES.CAPTCHA_REQUIRED;

  renderReCaptchaEnterprise = ({
    elementId = this.RECAPTCH_ENTERPRISE_ELEMENT_ID,
    theme = 'light',
  }: {
    elementId?: string;
    theme?: string;
  }) =>
    this.captcha?.render(elementId, {
      sitekey: this.enterpriseCaptchaKey,
      theme,
    });
}
