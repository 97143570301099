import { makeAutoObservable } from 'mobx';
import { OwnerAppStore } from '../../../../../stores/premiumUsers2FaEnforcement/ownerApp/ownerApp';
import React from 'react';
import { BackTextButton } from '../BackTextButton';
import { ApprovalChallengeStatus } from '@wix/ambassador-identity-v1-verification/types';
import { ChooseDeviceStep } from './SelectDeviceStep';
import { ConfirmDeviceStep } from '../ConfirmDeviceStep';
import { SuccessfulStep } from './SuccessfulStep';
import {
  StepDefinitions,
  Steps,
} from '../../../../../stores/premiumUsers2FaEnforcement/ownerApp/types';

export class UiStore {
  constructor(private store: OwnerAppStore) {
    makeAutoObservable(this);
  }

  steps: StepDefinitions = {
    [Steps.DeviceSelectionStep]: {
      primaryButtonText: 'ownerAppAsAddOnMethod.primary',
      primaryButtonOnClick: this.store.nextStep,
      secondaryButtonText: 'ownerAppAsAddOnMethod.secondary',
      secondaryButtonOnClick: () =>
        this.store.proceedToPostAuthUrl('opt out of add owner app add-on'),
      sideActions: undefined,
      currentStep: <ChooseDeviceStep />,
    },
    [Steps.ConfirmationStep]: {
      title: 'ownerAppAsAddOnMethod.title',
      subtitle: 'ownerAppAsAddOnMethod.subtitle',
      sideActions: (
        <BackTextButton
          goBack={() => this.store.setActiveStep(Steps.DeviceSelectionStep)}
        />
      ),
      currentStep:
        this.store.authStatus === ApprovalChallengeStatus.PENDING_APPROVAL ||
        this.store.authStatus === ApprovalChallengeStatus.APPROVED ? (
          <ConfirmDeviceStep.Pending />
        ) : (
          <ConfirmDeviceStep.Error />
        ),
    },
    [Steps.BackupMethodStep]: {
      title: 'ownerAppAsAddOnMethod.title',
      subtitle: 'ownerAppAsAddOnMethod.subtitle',
      primaryButtonText: 'ownerAppAsAddOnMethod.primary',
    },
    [Steps.SuccessfulStep]: {
      title: 'ownerAppAsAddOnMethod.title',
      subtitle: 'ownerAppAsAddOnMethod.subtitle',
      currentStep: <SuccessfulStep />,
    },
  };

  translate(s?: string) {
    return s ? this.store.premiumUsers2FaEnforcementStore.t(s) : s;
  }

  get title() {
    return this.translate(this.steps[this.store.activeStep].title);
  }

  get subtitle() {
    return this.translate(this.steps[this.store.activeStep].subtitle);
  }

  get primaryButtonText() {
    return this.translate(this.steps[this.store.activeStep].primaryButtonText);
  }

  get primaryButtonOnClick() {
    return this.steps[this.store.activeStep].primaryButtonOnClick;
  }

  get secondaryButtonText() {
    return this.translate(
      this.steps[this.store.activeStep].secondaryButtonText
    );
  }

  get secondaryButtonOnClick() {
    return this.steps[this.store.activeStep].secondaryButtonOnClick;
  }

  get sideActions() {
    return this.steps[this.store.activeStep].sideActions;
  }

  get currentStep() {
    return this.steps[this.store.activeStep].currentStep;
  }
}
