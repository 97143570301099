import React from 'react';
import { ButtonProps } from '@wix/design-system';
import s from './SubmitButton.scss';
import classNames from 'classnames';
import { ThemedButton } from '../ThemedComponents';

export const SubmitButton: React.FC<ButtonProps> = ({
  className = [],
  ...props
}) => {
  return (
    <ThemedButton
      className={classNames(
        s.commandButton,
        ...(Array.isArray(className) ? className : [className]),
      )}
      type="submit"
      size="large"
      {...props}
    ></ThemedButton>
  );
};
