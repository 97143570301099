import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { AppContextProvider } from '../AppLoader';
import { EnterPassword, EnterPasswordContextProvider } from '../EnterPassword';
import { Trans, useTranslation } from 'react-i18next';
import { dataHooks } from '../../dataHooks';
import { Box, Text } from '@wix/design-system';
import { DialogHeader } from '../DialogHeader/DialogHeader';
import s from './AccountVerification.scss';
import { ThemedText } from '../ThemedComponents';

const AccountVerification: React.FC = observer(() => {
  const {
    rootStore: { 
      accountVerificationStore,
      displayStore: { isMobile, preset }
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  
  return (
    <EnterPasswordContextProvider.Provider
      value={{ store: accountVerificationStore }}
    >
      <Box direction="vertical" align="center" width="100%">
        <EnterPassword dataHook={dataHooks.accountVerification.container}>
          <DialogHeader>
            <DialogHeader.Title as="h3">
              {t('account_verification.title', {
                email: accountVerificationStore.email,
              })}
            </DialogHeader.Title>
            <DialogHeader.SubTitleSmall className={isMobile ? s.mobileDescription : s.desktopDescription }>
              <Trans
                i18nKey='account_verification.content'
                values={{ email: accountVerificationStore.email , brand: t(preset?.nameKey ?? '') }}
              >
                Looks like you already have an account with this email: email
                To log in & connect your social account, please enter your Wix
                password.
              </Trans>
            </DialogHeader.SubTitleSmall>
          </DialogHeader>
        </EnterPassword>
      </Box>
    </EnterPasswordContextProvider.Provider>
  );
});

export default AccountVerification;
