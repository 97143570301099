import { action, makeObservable } from 'mobx';
import { RootStore } from './root';
import { AuthHandlers, CustomizedEmailStepStore } from './emailStep';

export class SignupEmailStepStore implements CustomizedEmailStepStore {
  public titleKey: string;
  public subtitleKey?: string;
  public showLoginEmailStep: boolean;
  public authHandlers: Partial<AuthHandlers> = {};
  constructor(private rootStore: RootStore) {
    const { displayStore } = rootStore;
    this.titleKey =
      displayStore.preset.signup?.titleKey ?? 'emailStep.signup.title';
    this.subtitleKey = displayStore.preset.signup?.subtitleKey;
    makeObservable(this, {
      onShowSignupEmailStep: action,
    });
  }

  public onShowSignupEmailStep() {
    this.rootStore.emailStepStore.loginEmailStepStore.showLoginEmailStep =
      false;
  }
}
