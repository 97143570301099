import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { AppContextProvider } from '../AppLoader';
import { EnterEmail, EnterEmailContextProvider } from '../EnterEmail';
import { dataHooks } from '../../dataHooks';

export const EnterEmailForSocial: React.FC = observer(() => {
  const {
    rootStore: { postSocialLoginEnterEmailStore },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  return (
    <EnterEmailContextProvider.Provider
      value={{ store: postSocialLoginEnterEmailStore }}
    >
      <EnterEmail
        dataHook={dataHooks.enterEmailForSocial.container}
        title={t('enter_email.title')}
        buttonContent={t('enter_email.send')}
      />
    </EnterEmailContextProvider.Provider>
  );
});
