import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { AppContextProvider } from '../AppLoader';

// Try to create a nice displayName for React Dev Tools.
const getDisplayName = <T,>(WrappedComponent: React.ComponentType<T>) =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';

export function withTextTheme<T extends { light?: boolean }>(
  WrappedComponent: React.ComponentType<T>,
) {
  // Creating the inner component. The calculated Props type here is the where the magic happens.
  const ComponentWithTheme: React.FC<T & { light?: boolean } & any> = observer(
    (props: T & any) => {
      const {
        rootStore: { displayStore },
      } = useContext(AppContextProvider);

      // props comes afterwards so the can override the default ones.
      return (
        <WrappedComponent
          light={displayStore.preset.skin === 'light'}
          {...props}
        />
      );
    },
  );

  ComponentWithTheme.displayName = `withTextTheme(${getDisplayName<T>(
    WrappedComponent,
  )})`;

  return ComponentWithTheme;
}

export function withSkinTheme<T extends { skin?: string }>(
  WrappedComponent: React.ComponentType<T>,
) {
  const ComponentWithTheme: React.FC<T> = observer((props: T & any) => {
    const {
      rootStore: { displayStore },
    } = useContext(AppContextProvider);
    const skin =
      displayStore.preset.skin === 'standard'
        ? 'dark'
        : displayStore.preset.skin;
    return <WrappedComponent skin={skin} {...props} />;
  });

  ComponentWithTheme.displayName = `withSkinTheme(${getDisplayName<T>(
    WrappedComponent,
  )})`;

  return ComponentWithTheme;
}
