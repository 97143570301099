import {
  action,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { RESEND_INDICATION_TTL_TIMER } from '../utils/constants';
import { constraints } from '../utils/validators';
import { FormField } from './formField';
import { RootStore } from './root';
import { ERROR_CODES } from '../utils/errorHandler';
import { VerificationState } from '../components/PostLogin/VerifyButton';

export abstract class VerifyCodeStore {
  public confirmCode: FormField;
  public verificationState: VerificationState;
  public resendErrorMessage: string | null;
  public resendSuccessfullyIndication: boolean = false;
  public resendThrottleTimerValue: number = 30;
  protected readonly rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      resendSuccessfullyIndication: observable,
      confirmCode: observable,
      resendErrorMessage: observable,
      verificationState: observable,
      resendThrottleTimerValue: observable,
      reactToResend: action,
      resendCode: action.bound,
      verifyCode: action.bound,
      onVerifyConfirmationCode: action.bound,
      onResendConfirmationCode: action.bound,
    });

    this.confirmCode = new FormField(undefined, [
      [
        constraints.minimum(6),
        this.rootStore.i18n.t(
          'confirmEmailModalSecondStep.confirmationCode.errorInfo'
        ),
      ],
      [
        constraints.maximum(6),
        this.rootStore.i18n.t(
          'confirmEmailModalSecondStep.confirmationCode.errorInfo'
        ),
      ],
    ]);
    this.reactToResend();
  }

  public reactToResend() {
    const resendTimeIndication = RESEND_INDICATION_TTL_TIMER;
    reaction(
      () => this.resendSuccessfullyIndication,
      () => {
        this.setResendThrottleTimer();
        setTimeout(
          () => (this.resendSuccessfullyIndication = false),
          resendTimeIndication
        );
      }
    );
  }

  private setResendThrottleTimer = () => {
    this.resendThrottleTimerValue = 30;
    const timer = setInterval(() => {
      if (this.resendThrottleTimerValue > 0) {
        this.resendThrottleTimerValue--;
      } else {
        clearInterval(timer);
      }
    }, 1000);
  };

  public async resendCode() {
    try {
      await this.onResendConfirmationCode();
      this.resendSuccessfullyIndication = true;
    } catch (errorCode: any) {
      runInAction(() => {
        this.resendErrorMessage = this.rootStore.i18n.t(
          this.rootStore.accountSettingsApi.errorHandling(errorCode)
        );
      });
    }
  }

  public async verifyCode() {
    try {
      if (!this.confirmCode.isValid) {
        return;
      }
      this.verificationState = VerificationState.Verifying;
      await this.onVerifyConfirmationCode();
      this.verificationState = VerificationState.Verified;
    } catch (errorCode: any) {
      if (errorCode === ERROR_CODES.STEP_UP_AUTH_REQUIRED) {
        return this.rootStore.postLoginStore.proceedToPostAuthUrl(
          'code not verified'
        );
      }
      this.verificationState = VerificationState.Unverified;
      this.confirmCode.addError(
        this.rootStore.i18n.t(
          this.rootStore.accountSettingsApi.errorHandling(
            errorCode,
            'validation.phone.badCode'
          )
        )
      );
    }
  }

  public abstract onVerifyConfirmationCode(): Promise<void>;

  public abstract onResendConfirmationCode(): Promise<void>;
}
