import { IPasswordInputRef } from '@wix/identity-fed-common';
import { FormField } from './formField';
import { ValidateMyPasswordResponseResult } from '@wix/ambassador-iam-password-v1-password/build/cjs/types.impl';
import { Constraint } from '../utils/validators';
import { makeObservable, observable } from 'mobx';

export class PasswordFormField extends FormField {
  public passwordInputRef: IPasswordInputRef;
  public loader: boolean;
  public validateNewPassword?: (value: string) => Promise<any>;

  constructor(
    value: string = '',
    constraints?: Constraint[],
    validateNewPassword?: (value: string) => Promise<any>
  ) {
    super(value, constraints, 'password');
    this.validateNewPassword = validateNewPassword;

    makeObservable(this, {
      loader: observable,
    });
  }

  public setPasswordRef = (ref: IPasswordInputRef) => {
    this.passwordInputRef = ref;
  };

  public async onBlur() {
    super.onBlur();
    if (this.passwordInputRef && !this.passwordInputRef?.input?.validity.valid) {
      return this.addError(this.passwordInputRef?.validationMessage?.()!);
    }
    // Server side validation
    if (this.validateNewPassword) {
      this.loader = true;
      try {
        const serverValidationResponse = await this.validateNewPassword(
          this.value
        );
        if (
          serverValidationResponse.result !==
          ValidateMyPasswordResponseResult.SUCCESS
        ) {
          serverValidationResponse?.passwordDoesNotSatisfyPolicyOptions?.violatedRules?.forEach(
            (violation) => {
              throw new Error(violation.name!);
            }
          );
        }
      } finally {
        this.loader = false;
      }
    }
  };

  public async onChange(e) {
    super.onChange(e);
    if (this.passwordInputRef && this.isDirty && this.isValid) {
      if (this.passwordInputRef?.input?.validity?.valid) {
        this.clearErrors();
      } else {
        this.addError(this.passwordInputRef?.validationMessage?.()!);
      }
    }
  }
}
