import { action, computed, makeObservable, observable, when } from 'mobx';
import { ROUTES } from '../routes';
import { AccountData } from '../types';
import { SocialProviders } from '../utils/presets';
import { RootStore } from './root';

export const UNSUPPORTED_AGENTS_FOR_SOCIAL_AUTH = [
  'FBAV',
  'FBAN',
  'Instagram',
  'musical_ly',
  'Twitter',
];
const CODUX_HOST = 'online.codux.com';

export class SocialAuthStore {
  protected readonly rootStore: RootStore;
  socialConnectionsProviders: string[] = [];
  showGoogle: boolean;
  showFacebook: boolean;
  showApple: boolean;
  showAlreadyExistsNote: boolean = false;
  showEditorXSignupDisabledModal: boolean = false;
  isUnsupportedAgentForSocialAuth: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    const { preset } = this.rootStore.displayStore;
    const shouldHideFBButton = this.rootStore.experiments.enabled(
      'specs.ident.HideFacebookButton',
    );
    this.showGoogle = preset.socialProviders?.includes(SocialProviders.GOOGLE)!;
    this.showFacebook =
      !shouldHideFBButton &&
      preset.socialProviders?.includes(SocialProviders.FACEBOOK)!;
    this.showApple =
      preset.socialProviders?.includes(SocialProviders.APPLE)! &&
      location.host != CODUX_HOST;
    this.isUnsupportedAgentForSocialAuth =
      this.rootStore.experiments.enabled(
        'specs.ident.shouldBlockInAppBrowsersLogin',
      ) &&
      UNSUPPORTED_AGENTS_FOR_SOCIAL_AUTH.findIndex((ua) =>
        navigator?.userAgent?.includes(ua),
      ) !== -1;

    makeObservable(this, {
      socialConnectionsProviders: observable,
      showGoogle: observable,
      showFacebook: observable,
      showApple: observable,
      showAlreadyExistsNote: observable,
      showEditorXSignupDisabledModal: observable,
      isUnsupportedAgentForSocialAuth: observable,
      showIconsError: computed,
      enableAccess: computed,
      setSocialConnectionsProviders: action.bound,
      setSocialProvidersAndNavigate: action.bound,
    });
  }

  setSocialConnectionsProviders(providers: string[]) {
    this.socialConnectionsProviders = providers;
  }

  get enableAccess() {
    return !!(
      this.socialConnectionsProviders?.length &&
      this.rootStore.userDataStore.email
    );
  }

  get showIconsError() {
    const { facebookLoginStore, appleLoginStore, googleLoginStore } =
      this.rootStore;
    return (
      facebookLoginStore.hasError ||
      appleLoginStore.hasError ||
      googleLoginStore.hasError
    );
  }

  generateProviderName(key: string) {
    return key.charAt(0).toUpperCase() + key.slice(1);
  }

  setSocialProvidersAndNavigate({
    socialConnectionsProviders,
    ssoUrl,
    showAlreadyExistsNote = false,
  }: {
    socialConnectionsProviders: string[];
    ssoUrl?: string;
    showAlreadyExistsNote?: boolean;
  }) {
    ssoUrl && this.rootStore.ssoStore.setSsoPageUrl(ssoUrl);
    this.showAlreadyExistsNote = showAlreadyExistsNote;
    this.setSocialConnectionsProviders(socialConnectionsProviders);
    when(
      () => this.enableAccess,
      () => this.rootStore.navigationStore.navigate(ROUTES.SOCIAL_ONLY),
    );
  }

  shouldProceedToSocialAuthOnly(account: AccountData) {
    const hasSocialAuth =
      !!account?.userAuthenticatorsData?.socialConnectionsProviders?.length;
    return !account?.userAuthenticatorsData?.hasPassword && hasSocialAuth;
  }
}
