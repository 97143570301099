import React, { useContext } from 'react';
import {
  CustomModalLayout,
  TextButton,
  Box,
  Text,
  Tooltip,
} from '@wix/design-system';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import QRCode from 'react-qr-code';
import s from '../modals.scss';
import classNames from 'classnames';
import { AppContextProvider } from '../../../AppLoader';
import { CopyToClipboard } from './CopyToClipboard';
import { BackTextButton } from '../OwnerApp/BackTextButton';
import { EnforcementModal } from '../EnforcementModal';

export const AuthenticatorAppQrModal: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: {
          shouldActivateNewColorBranding,
          authenticatorAppModals,
          multiFactorAuth,
        },
      },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  return (
    <EnforcementModal>
      <CustomModalLayout
        width="600px"
        showHeaderDivider
        showFooterDivider={false}
        primaryButtonOnClick={() => authenticatorAppModals.onQrModalConfirm()}
        primaryButtonText={t(
          'multiFactorAuth.addAuthApp.qrModal.confirmButton'
        )}
        title={t('multiFactorAuth.addAuthApp.qrModal.title')}
        className={s.enforcementAuthAppModal}
        sideActions={
          <BackTextButton
            goBack={
              authenticatorAppModals.premiumUsers2FaEnforcementStore
                .goBackToEnforcementOrChooseBackupMethod
            }
          />
        }
      >
        <Box marginBottom={4}>
          <Text>
            <Trans i18nKey="multiFactorAuth.addAuthApp.qrModal.content.line1">
              Scan the QR code to add Wix to your authenticator app:
            </Trans>
          </Text>
        </Box>
        <Box direction="vertical" align="center" width="100%" gap="42px">
          <Tooltip
            maxWidth="219px"
            content={
              <Text size="small" light>
                <Trans i18nKey="multiFactorAuth.addAuthApp.qrModal.qrSvg.tooltipContent">
                  Scan this code with an authenticator app such as Google
                  Authenticator, Authy, or others
                  <a
                    className={classNames(
                      s.inTextLink,
                      shouldActivateNewColorBranding && s.newColorsBranding
                    )}
                    target="_blank"
                    onClick={() => multiFactorAuth.onClickLearnMoreLink()}
                    href={multiFactorAuth.authenticatorAppArticleLink}
                    rel="noreferrer"
                  >
                    Learn more about these apps
                  </a>
                </Trans>
              </Text>
            }
          >
            <QRCode value={authenticatorAppModals.qrUrl} size={148} />
          </Tooltip>
          <Box direction="vertical" gap="7px" width="100%">
            <Text>
              {t('multiFactorAuth.addAuthApp.qrModal.secretKey.label')}
            </Text>
            <CopyToClipboard
              inputText={authenticatorAppModals.sharedSecretString}
              copyText={t(
                'multiFactorAuth.addAuthApp.qrModal.secretKey.input.copyText'
              )}
              copiedText={t(
                'multiFactorAuth.addAuthApp.qrModal.secretKey.input.copiedText'
              )}
              onCopy={() => authenticatorAppModals.onCopyQrCodeClicked()}
            />
          </Box>
        </Box>
        <Box marginTop={4}>
          <Text>
            <Trans i18nKey="multiFactorAuth.addAuthApp.qrModal.subtitle">
              Don't have an authenticator app?
              <TextButton
                as="a"
                target="_blank"
                style={{ color: '#116DFF', textDecoration: 'none' }}
                onClick={() => multiFactorAuth.onClickLearnMoreLink()}
                href={multiFactorAuth.authenticatorAppArticleLink}
              >
                Find out how to install
              </TextButton>
            </Trans>
          </Text>
        </Box>
      </CustomModalLayout>
    </EnforcementModal>
  );
});
