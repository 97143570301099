import { reaction, action, observable, makeObservable, computed } from 'mobx';
import { Validation, Constraint, validate } from '../utils/validators';
import { ChangeEvent } from 'react';

export class FormField {
  public value: string = '';
  public originalValue: string;
  public isDirty: boolean = false;
  public isTouched: boolean = false;
  public isFocused: boolean = false;
  public errors: Validation = false;
  public inputType: string;
  public serverError: React.ReactNode = '';
  public isWarning: boolean = false;
  private validator: (str: string) => Validation;

  constructor(
    value: string = '',
    constraints?: Constraint[],
    inputType: string = 'text',
  ) {
    this.value = value;
    this.originalValue = value;
    this.inputType = inputType;
    if (value) {
      this.isDirty = true;
    }

    if (constraints) {
      this.validator = validate(constraints);
    } else {
      // We use dumb validator in case there is no need for validation.
      this.validator = () => false;
    }
    makeObservable(this, {
      value: observable,
      originalValue: observable,
      isDirty: observable,
      isTouched: observable,
      errors: observable,
      serverError: observable,
      inputType: observable,
      isWarning: observable,
      onChange: action.bound,
      addError: action,
      clear: action,
      errorInfo: computed,
      onBlur: action.bound,
      onFocus: action,
      isValid: computed,
    });
    this.reactToChange();
  }

  public reactToChange() {
    reaction(
      () => {
        return this.value;
      },
      (res) => {
        this.triggerValidator();
      },
      { fireImmediately: true },
    );
  }

  public triggerValidator() {
    this.errors = this.validator(this.value);
  }

  public setValue(value: string) {
    this.value = value;
    this.isDirty = true;
    this.isTouched = true;
  }

  public onChange(e: ChangeEvent<HTMLInputElement>) {
    this.value = e.target.value;
    this.serverError = '';
    this.triggerValidator();
  };

  public addError(error: string) {
    if (!Array.isArray(this.errors)) {
      this.errors = [];
    }

    this.errors.unshift(error);
  }

  get errorInfo() {
    if (!Array.isArray(this.errors)) {
      return '';
    }

    return this.errors[0];
  }

  public clear() {
    this.value = '';
    this.isDirty = false;
    this.isTouched = false;
    this.isFocused = false;
    this.isWarning = false;
  }

  public onBlur() {
    this.isTouched = true;
    this.isFocused = false;
    if (this.value) {
      this.isDirty = true;
    }
  };

  public onFocus = () => {
    this.isFocused = true;
  };

  public clearErrors = () => {
    this.errors = false;
  };

  get isValid(): boolean {
    return !this.errors;
  }

  public successfullySaved() {
    this.originalValue = this.value;
  }

  get isChanged(): boolean {
    return this.originalValue !== this.value;
  }

  public markFieldAsDirty() {
    this.isDirty = true;
    this.isTouched = true;
  }

  public setConstraints(constraints: Constraint[]) {
    this.validator = validate(constraints);
  }

  public removeConstraints() {
    this.validator = () => false;
  }
}
