import { action, makeObservable, observable, when } from 'mobx';
import { Constraint, generateEmailFieldConstraints } from '../utils/validators';
import { FormField } from './formField';
import { RootStore } from './root';
import { ROUTES } from '../routes';
import {
  AUTH_METHODS_BY_MAIL,
  IAUTH_METHODS_BY_MAIL,
  LOGIN_PAGE_CONTEXT,
  SIGNUP_PAGE_CONTEXT,
  WIX_PARENT_ACCOUNT_HINT,
} from '../utils/constants';
import { HttpError } from '@wix/http-client';
import { AccountData } from '../types';
import {
  loginPageClickOnLogInWithSso,
  onSwitchContext,
  userClickedOnContinueWithEmail,
} from '@wix/bi-logger-hls2/v2';
import { CAPTCHA_ACTIONS } from './captcha';
import { LoginEmailStepStore } from './loginEmailStep';
import { SignupEmailStepStore } from './signupEmailStep';
import { SIGNUP_FLOWS } from './signup';
import { LOGIN_FLOWS } from './login';
import { areAllUserAccountsBlocked } from '../utils/errorHandler';
import { AUTH_TYPE } from '../components/BlockedAccount/authTypes';

export type AuthHandlers = {
  MULTI: (params: { email: string }) => void;
  SINGLE: (params: { email: string; ssoUrl: string }) => void;
  LOGIN_PASSWORD: (params?: { flow?: string }) => void;
  SOCIAL_ONLY: (params: { socialConnectionsProviders: string[] }) => void;
  SIGNUP_PASSWORD: () => void;
};

export interface CustomizedEmailStepStore {
  titleKey: string;
  subtitleKey?: string;
}

export class EmailStepStore {
  protected readonly rootStore: RootStore;
  private captchaAdded: boolean = false;
  public authHandlers: AuthHandlers;
  public emailField: FormField;
  public shouldRememberMe: boolean = true;
  public isLoading: boolean = false;
  public titleKey: string;
  public subtitleKey: string;
  public isEmailLoginMobileMode: boolean = false;
  public isFetchingByDefaultEmail: boolean = true;
  private EMAIL_CHECK_INTERACTION_NAME = 'email-check';
  public loginEmailStepStore: LoginEmailStepStore;
  public signupEmailStepStore: SignupEmailStepStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    const { i18n, displayStore } = rootStore;
    this.titleKey =
      displayStore.preset.landingPage?.titleKey ?? 'emailStep.title';
    this.subtitleKey =
      displayStore.preset.landingPage?.subtitleKey ?? 'emailStep.subTitle';
    makeObservable(this, {
      getContext: action.bound,
      submit: action.bound,
      onClickContinueWithSso: action.bound,
      onClickForgotEmail: action.bound,
      shouldRememberMe: observable,
      isLoading: observable,
      isFetchingByDefaultEmail: observable,
      isEmailLoginMobileMode: observable,
    });
    const emailRules: Constraint[] = generateEmailFieldConstraints(
      i18n.t.bind(i18n),
    );
    this.emailField = new FormField(
      this.rootStore.userDataStore.email,
      emailRules,
    );

    if (this.rootStore.socialAuthStore.isUnsupportedAgentForSocialAuth) {
      this.isEmailLoginMobileMode = true;
    }
    this.loginEmailStepStore = new LoginEmailStepStore(rootStore);
    this.signupEmailStepStore = new SignupEmailStepStore(rootStore);
    this.handlePostLoaded();
  }

  private async handlePostLoaded() {
    await when(() => this.rootStore.isLoaded);
    this.setAuthHandlers();
    this.handleAutoSubmission();
  }

  protected setAuthHandlers() {
    const { ssoStore, socialAuthStore, signupStore, loginStore } =
      this.rootStore;
    const showLoginEmailStep = () =>
      this.loginEmailStepStore.showLoginEmailStep;
    this.authHandlers = {
      [AUTH_METHODS_BY_MAIL.MULTI]: ssoStore.navigateAccountSelector,
      [AUTH_METHODS_BY_MAIL.SINGLE]: ssoStore.redirectSsoUrl,
      [AUTH_METHODS_BY_MAIL.LOGIN_PASSWORD]: (params?: { flow?: string }) =>
        loginStore.onNavigateToLogin(
          params?.flow ||
            (showLoginEmailStep()
              ? LOGIN_FLOWS.DEFAULT
              : LOGIN_FLOWS.FROM_SIGNUP),
        ),
      [AUTH_METHODS_BY_MAIL.SOCIAL_ONLY]: (params) =>
        socialAuthStore.setSocialProvidersAndNavigate({
          ...params,
          ...(!showLoginEmailStep() ? { showAlreadyExistsNote: true } : {}),
        }),
      [AUTH_METHODS_BY_MAIL.SIGNUP_PASSWORD]: () =>
        signupStore.onNavigateToSignup(
          showLoginEmailStep() ? SIGNUP_FLOWS.FROM_LOGIN : SIGNUP_FLOWS.DEFAULT,
        ),
    };
  }

  private async handleAutoSubmission() {
    const {
      navigationStore: { currentRoute, loginDialogContext },
      userDataStore,
    } = this.rootStore;
    const matchingDialogContext =
      !loginDialogContext ||
      [LOGIN_PAGE_CONTEXT, SIGNUP_PAGE_CONTEXT].some(
        (context) => context === loginDialogContext,
      );
    if (
      userDataStore.email &&
      currentRoute === ROUTES.EMAIL_STEP &&
      matchingDialogContext
    ) {
      await this.submit(true);
    }
    this.isFetchingByDefaultEmail = false;
  }

  public getAuthMethodByAccountData(
    accounts: AccountData[],
    email: string,
  ): { method: IAUTH_METHODS_BY_MAIL; params?: { [key: string]: any } } {
    // Should never happen since the server suppose to response with 404 in such a case
    if (!accounts && !this.rootStore.loginStore.shouldBlockEditorXSignup()) {
      return { method: AUTH_METHODS_BY_MAIL.SIGNUP_PASSWORD };
    }
    const getQueryParam = this.rootStore.navigationStore.getQueryParam;
    const parentHint = getQueryParam('parentHint');
    /** Multiple accounts **/
    if (accounts.length > 1 && !parentHint) {
      return { method: AUTH_METHODS_BY_MAIL.MULTI };
    }
    /** Single account **/
    let account = accounts[0];
    if (accounts.length > 1 && parentHint) {
      const filtered = accounts.filter(
        (a) =>
          (!a.parentAccountId && parentHint === WIX_PARENT_ACCOUNT_HINT) ||
          a.parentAccountId === parentHint,
      );
      if (filtered.length) {
        account = filtered[0];
      }
    }
    const ssoUrl = account?.accountSsoSettings?.ssoUrl;
    const isSsoMandatory =
      ssoUrl &&
      (account?.accountSsoSettings?.isSsoMandatory ||
        !account?.userAuthenticatorsData?.hasPassword);
    if (isSsoMandatory) {
      return {
        method: AUTH_METHODS_BY_MAIL.SINGLE,
        params: { ssoUrl },
      };
    }
    if (this.rootStore.socialAuthStore.shouldProceedToSocialAuthOnly(account)) {
      return {
        method: AUTH_METHODS_BY_MAIL.SOCIAL_ONLY,
        params: {
          socialConnectionsProviders:
            account?.userAuthenticatorsData?.socialConnectionsProviders,
          ssoUrl,
        },
      };
    }
    return { method: AUTH_METHODS_BY_MAIL.LOGIN_PASSWORD };
  }

  public async submit(isDefaultEmail: boolean = false) {
    !isDefaultEmail &&
      this.rootStore.biLogger.report(userClickedOnContinueWithEmail({}));

    if (!this.emailField.isValid || this.isLoading) {
      this.emailField.markFieldAsDirty();
      return;
    }
    this.isLoading = true;
    this.rootStore.fedopsLogger.interactionStarted(
      this.EMAIL_CHECK_INTERACTION_NAME,
    );
    const recaptchaParams =
      await this.rootStore.captchaStore.handleRecaptchaExecution({
        captchaAdded: this.captchaAdded,
        action: CAPTCHA_ACTIONS.GET_USER_ACCOUNTS,
      });

    const email = this.emailField.value;
    this.rootStore.userDataStore.setEmail(email);
    const { apiStore } = this.rootStore;

    try {
      const response = await apiStore.getUserAccounts({
        email,
        ...recaptchaParams,
      });
      
      const authType = this.getAuthMethodByAccountData(
        response?.accountsData!,
        email,
      );
      this.rootStore.fedopsLogger.interactionEnded(
        this.EMAIL_CHECK_INTERACTION_NAME,
      );
      this.isLoading = false;

      const shouldNavigateToBlockedAccount = this.rootStore.experiments.enabled(
        'specs.login.ShouldNavigateToBlockedAccount'
      );
      if (
        shouldNavigateToBlockedAccount &&
        response.accountsData &&
        areAllUserAccountsBlocked(response.accountsData)
      ) {
        return this.rootStore.navigationStore.navigateToBlockedAccount({ refferal_info: AUTH_TYPE.EMAIL_AND_PASSWORD });
      }

      this.authHandlers[authType.method]({
        email,
        ...(authType.params ?? {}),
      } as any);
    } catch (error: any) {
      this.isLoading = false;
      const serverErrorCode = (
        error as HttpError
      )?.response?.data.errorCode?.toString();
      const emailDoesNotExist = this.isUserEmailDoesNotExist(error);
      const isValidInteraction =
        emailDoesNotExist ||
        this.rootStore.captchaStore.isCaptchaServerError(serverErrorCode);
      if (isValidInteraction) {
        this.rootStore.fedopsLogger.interactionEnded(
          this.EMAIL_CHECK_INTERACTION_NAME,
        );
      }
      if (emailDoesNotExist) {
        if (this.rootStore.loginStore.shouldBlockEditorXSignup()) {
          this.emailField.addError(
            this.rootStore.i18n.t('account.not.found.error'),
          );
        } else {
          if (this.rootStore.displayStore.isMobile) {
            this.rootStore.signupStore.isEmailSignupMode = true;
          }
          this.authHandlers[AUTH_METHODS_BY_MAIL.SIGNUP_PASSWORD]();
        }
      }
      this.captchaAdded =
        this.rootStore.captchaStore.createOrResetCaptchaIfNeeded(
          serverErrorCode,
          this.captchaAdded,
        );
    }
  }

  isUserEmailDoesNotExist(error) {
    return (error as HttpError)?.response?.status?.toString() === '404';
  }

  getAccountRecoveryLink(mode: 'email' | 'password') {
    const overrideLocale =
      this.rootStore.navigationStore.getQueryParam('overrideLocale');
    const params = new URLSearchParams({
      sessionId: this.rootStore.sessionId.get(),
      recoveryMode: mode,
      ...(overrideLocale ? { overrideLocale } : {}),
    });
    return `/account-recovery?${params.toString()}`;
  }

  public getSsoWithEmailLink() {
    return ROUTES.SSO_ENTER_EMAIL;
  }

  onClickForgotEmail() {
    this.rootStore.biLogger.report(
      onSwitchContext({
        context: 'forgot-email',
        referrer: this.rootStore.navigationStore.originContext,
      }),
    );
  }

  onClickContinueWithSso() {
    this.rootStore.biLogger.report(loginPageClickOnLogInWithSso({}));
    this.rootStore.navigationStore.navigate(this.getSsoWithEmailLink());
  }

  getContext() {
    return this.loginEmailStepStore.showLoginEmailStep
      ? LOGIN_PAGE_CONTEXT
      : SIGNUP_PAGE_CONTEXT;
  }
}
