import { Box } from '@wix/design-system';
import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import EnterCode from '../EnterCode';
import { AppContextProvider } from '../AppLoader';
import { loginTwoFactorAuthenticationPageLoad } from '@wix/bi-logger-hls2/v2';
import { TwoFactorAuthMethods } from '../../utils/constants';
import { OwnerApp2FA } from '../OwnerApp2FA/OwnerApp2FA';
import { EnterCodeContextProvider } from '../EnterCode/EnterCode';
import { EnterCodeStore } from '../../stores/enterCode';

const TwoFactorAuthDialog: React.FC = observer(() => {
  const {
    rootStore,
    rootStore: { twoFactorAuthStore, biLogger },
  } = useContext(AppContextProvider);
  const [enterCodeStore] = useState(new EnterCodeStore(rootStore));

  useEffect(() => {
    biLogger.report(
      loginTwoFactorAuthenticationPageLoad({
        origin: 'login',
        flow_type: twoFactorAuthStore.currentTwoFAMethod,
        reason: twoFactorAuthStore.lfndForce2FA ? 'LFND' : 'login'
      }),
    );
  });
  const ButtonsSection = twoFactorAuthStore.showResendButton()
    ? EnterCode.ButtonsSectionWithResend
    : EnterCode.ButtonsSection;

  return (
    <EnterCodeContextProvider.Provider
      value={{ store: twoFactorAuthStore, enterCodeStore }}
    >
      {twoFactorAuthStore.currentTwoFAMethod === TwoFactorAuthMethods.Push ? (
        <OwnerApp2FA />
      ) : (
        <Box direction="vertical" width="100%" align="center">
          <EnterCode
            store={twoFactorAuthStore}
            dialogTitleKey={twoFactorAuthStore.getTitleKey()}
            descriptionKey={twoFactorAuthStore.getDescriptionKey()}
          >
            <EnterCode.RememberMe />
            <ButtonsSection>
              <EnterCode.ButtonsSection.VerifyButton />
            </ButtonsSection>
            <EnterCode.LinksSection>
              {twoFactorAuthStore.currentTwoFAMethod ===
                TwoFactorAuthMethods.Phone && <EnterCode.VoiceCallLink />}
              {twoFactorAuthStore.showMethodsSwitch && (
                <EnterCode.MethodsSwitch />
              )}
              <EnterCode.LostAccess onClick={twoFactorAuthStore.onLostAccess} />
            </EnterCode.LinksSection>
          </EnterCode>
        </Box>
      )}
    </EnterCodeContextProvider.Provider>
  );
});

export default TwoFactorAuthDialog;
