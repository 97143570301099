import { makeObservable, action, computed, observable } from 'mobx';
import { ROUTES } from '../routes';
import { RootStore } from './root';
import { AccountData, AccountDataExtended } from '@wix/identity-fed-common';
import {
  loginAccountSelectorAccountSelected,
  loginAccountSelectorComponentLoad,
} from '@wix/bi-logger-hls2/v2';
import {
  SEARCH_PARAMS,
  WIX_HOME_URL,
  WIX_PARENT_ACCOUNT_HINT,
} from '../utils/constants';
import { UserAccountsDataByEmailResponse } from '../types';
import { HttpError } from '@wix/http-client';
import { CAPTCHA_ACTIONS } from './captcha';

export enum AccountsFilter {
  SSO = 'SSO',
}

export class SelectAccountStore {
  private readonly rootStore: RootStore;
  public showError: boolean = false;
  public filter: string = '';
  private captchaAdded: boolean = false;
  public userAccountsDataByEmail?: UserAccountsDataByEmailResponse;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      showError: observable,
      filter: observable,
      userAccountsDataByEmail: observable,
      email: computed,
      showAccountSelector: computed,
      initialFetch: action,
      onErrorOccured: action,
      resetError: action,
      onAccountSelected: action,
      fetchSsoAccounts: action.bound,
      fetchAccounts: action.bound,
      onBackClicked: action.bound,
    });
    this.setAndHandleFilter();
  }

  get email() {
    return this.rootStore.userDataStore.email;
  }

  public onErrorOccured = () => {
    this.showError = true;
  };

  public resetError = () => {
    this.showError = false;
  };

  public onAccountSelectorLoaded = (accountsData: AccountData[]) => {
    this.rootStore.biLogger.report(
      loginAccountSelectorComponentLoad({
        account_ids: accountsData
          .map((account) => account.accountId)
          .toString(),
        parent_account_ids: accountsData
          .map((account) => account.parentAccountId)
          .filter((id) => id)
          .toString(),
        context: 'login',
      }),
    );
  };

  public onAccountSelected = async (account: AccountDataExtended) => {
    // If there is a parentAccountId, try to get an SSO url
    this.rootStore.biLogger.report(
      loginAccountSelectorAccountSelected({
        parent_account_id: account.parentAccountId,
        selected_account_id: account.accountId,
      }),
    );
    const { navigationStore } = this.rootStore;
    // The selected account is currently logged in
    if (account.loggedInUserData) {
      navigationStore.targetUrl
        ? navigationStore.navigateToTargetUrl()
        : navigationStore.redirect(WIX_HOME_URL);
    } else {
      const ssoUrl = account.accountSsoSettings?.ssoUrl;
      if (ssoUrl) {
        this.rootStore.navigationStore.appendSearchParamToCurrentLocation(
          SEARCH_PARAMS.EMAIL,
          this.email,
        );
        const { targetUrl, locationParamsEntries } =
          this.rootStore.navigationStore;
        const searchParams = {
          ...locationParamsEntries,
        };
        targetUrl && (searchParams[SEARCH_PARAMS.TARGET_URL] = targetUrl);
        this.rootStore.navigationStore.redirect(ssoUrl, {
          searchParams,
        });
      } else {
        if (this.rootStore.isNewLoginApp) {
          this.rootStore.setIsLoading(false);
          return this.rootStore.loginStore.onNavigateToLogin();
        }
        navigationStore.goToMainLogin({
          email: this.email,
          parentHint: account.parentAccountId || WIX_PARENT_ACCOUNT_HINT,
        });
      }
    }
  };

  public async fetchSsoAccounts() {
    const res = await this.fetchAccounts();
    const userAccountsDataByEmail = res;
    if (!userAccountsDataByEmail?.accountsData) {
      return;
    }
    userAccountsDataByEmail.accountsData =
      this.rootStore.ssoStore.filterSsoAccounts(
        userAccountsDataByEmail.accountsData,
      );
    return userAccountsDataByEmail;
  }

  public async fetchAccounts() {
    const { userDataStore, apiStore } = this.rootStore;
    try {
      const recaptchaParams =
        await this.rootStore.captchaStore.handleRecaptchaExecution({
          captchaAdded: this.captchaAdded,
          action: CAPTCHA_ACTIONS.GET_USER_ACCOUNTS,
        });
      const res = await apiStore.getUserAccounts({
        email: userDataStore.email,
        ...recaptchaParams,
      });
      return res;
    } catch (error) {
      const serverErrorCode = (
        error as HttpError
      )?.response?.data.errorCode?.toString();
      this.captchaAdded =
        this.rootStore.captchaStore.createOrResetCaptchaIfNeeded(
          serverErrorCode,
          this.captchaAdded,
        );

      console.error(error);
      this.onErrorOccured();
    }
  }

  get showAccountSelector() {
    return (
      this.showError ||
      (this.userAccountsDataByEmail?.accountsData?.length ?? 0) > 1
    );
  }

  public setAndHandleFilter(filter?: AccountsFilter) {
    this.filter =
      filter ??
      this.rootStore.navigationStore.getQueryParam('filterAccounts') ??
      '';
  }

  public async initialFetch() {
    this.resetError();
    let fetchUserAccounts = this.fetchAccounts;
    if (this.filter === AccountsFilter.SSO) {
      fetchUserAccounts = this.fetchSsoAccounts;
    }
    this.userAccountsDataByEmail = await fetchUserAccounts();
    const { accountsData } = this.userAccountsDataByEmail ?? {
      accountsData: [],
    };
    if (!accountsData?.length || accountsData.length > 1) {
      return;
    }
    this.onAccountSelected({
      ...accountsData[0],
      loggedInUserData: this.userAccountsDataByEmail?.loggedInUserData,
    });
  }

  public enableAccess() {
    return !!this.email;
  }
  public onBackClicked() {
    this.userAccountsDataByEmail = undefined;
    this.setAndHandleFilter();
    this.rootStore.navigationStore.goBack();
  }

  get isSelectAccountVisible() {
    return (
      this.rootStore.navigationStore.currentRoute === ROUTES.SELECT_ACCOUNT
    );
  }
}
