import React, { useContext } from 'react';
import { Box, ButtonProps, Loader } from '@wix/design-system';
import { ThemedButton } from '../ThemedComponents';
import { AppContextProvider } from '../AppLoader';

interface ButtonWithLoaderProps {
  onMouseDown?: (e: any) => void;
  showLoader?: boolean;
}

export const ButtonWithLoader: React.FC<
  ButtonWithLoaderProps & ButtonProps
> = ({ showLoader, ...props }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const onClick = React.useCallback(
    (e: any) => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      Promise.all([props?.onClick?.(e)]).finally(() => setIsLoading(false));
    },
    [props.onClick],
  );
  return (
    <Box>
      <ThemedButton size="large" {...props} onClick={onClick}>
        {isLoading || showLoader ? <Loader size="tiny" /> : props.children}
      </ThemedButton>
    </Box>
  );
};
