import { action, computed, makeObservable } from 'mobx';
import { verifyAccountSubmit } from '@wix/bi-logger-hls2/v2';
import { RootStore } from './root';
import { EnterPasswordStore } from './enterPassword';
import { extractErrorKeyByErrorCode } from '../utils/errorHandler';

export class AccountVerificationStore extends EnterPasswordStore {
  private urlParams: URLSearchParams = new URLSearchParams(window.location.search)
  private isDebug: boolean = this.urlParams.get('debugCase') === 'accountVerification';

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      email: computed,
      submit: action.bound,
      enableAccess: action.bound,
    });
  }

  public async submit() {
    if (this.passwordField.errorInfo || this.passwordField.serverError) {
      return;
    }
    try {
      const store = this.rootStore.getActiveSocialProviderStore();
      this.rootStore.biLogger.report(
        verifyAccountSubmit({ socialNetwork: store.provideId }),
      );
      const { errorCode, success } = await store.continueLoginToWix({
        password: this.passwordField.value,
      });
      if (!success && errorCode) {
        this.passwordField.addError(
          this.rootStore.i18n.t(extractErrorKeyByErrorCode(errorCode)),
        );
      }
    } catch (error: any) {
      const errorCode = error?.errorCode ?? error?.response?.data?.errorCode;
      throw errorCode;
    }
  }

  public enableAccess(): boolean {
    return !!(this.rootStore.activeSocialProvider && this.email) || this.isDebug ;
  }

  get email() {
    return this.isDebug ? this.urlParams.get('email') : this.rootStore.userDataStore.email;
  }
}
