import { observer } from 'mobx-react';
import React, { createContext, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Text } from '@wix/design-system';
import { Check } from '@wix/wix-ui-icons-common';
import { VerifyCodeStore } from '../../stores/verifyCode';
import { AppContextProvider } from '../AppLoader';
import { InputField } from '../InputField';
import s from './PostLogin.scss';
import PostLoginModal from './PostLoginModal';
import { ThemedText, ThemedTextButton } from '../ThemedComponents';
import { dataHooks } from '../../dataHooks';
import VerifyButton, { VerificationState } from './VerifyButton';

export const VerifyCodeContextProvider = createContext<{
  store: VerifyCodeStore;
}>(undefined!);

interface VerifyCodeProps {
  title: string;
  infoI18Key: string;
  clean: () => void;
  target?: string;
}

const VerifyCode: React.FC<VerifyCodeProps> = observer(
  ({ title, infoI18Key, target, clean }) => {
    const { store } = useContext(VerifyCodeContextProvider);
    const { t } = useTranslation();
    return (
      <PostLoginModal
        primaryButtonText={
          <VerifyButton verificationState={store.verificationState} />
        }
        primaryButtonDisabled={!store.confirmCode.isValid}
        primaryButtonAction={() =>
          store.verificationState !== VerificationState.Verifying &&
          store.verifyCode()
        }
        title={title}
        backButton={{
          show: store.verificationState === VerificationState.Unverified,
          action: clean,
        }}
      >
        <Box
          paddingBottom={2}
          className={s.verifyCodeInfo}
          dataHook={dataHooks.postLogin.common.verifyCodeContainer}
        >
          <ThemedText>
            <Trans i18nKey={infoI18Key} values={{ value: target }}>
              Verify your new account email by entering the 6-digit code we sent
              to:
              <ThemedText weight="bold">email</ThemedText>
            </Trans>
          </ThemedText>
        </Box>
        <Box
          paddingTop={4}
          paddingBottom={10}
          width="288px"
          direction="vertical"
        >
          <InputField
            formField={store.confirmCode}
            autoFocus
            placeholder={t('post.login.verify.code.placeholder')}
            value={store.confirmCode.value}
            onEnterPressed={store.verifyCode}
          />
          <Box paddingTop={1}>
            {!store.resendSuccessfullyIndication && (
              <ThemedTextButton
                size="small"
                as="a"
                onClick={store.resendCode}
                underline="none"
                skin="standard"
              >
                {t('post.login.resend')}
              </ThemedTextButton>
            )}
            {store.resendSuccessfullyIndication && (
              <Text size="small" skin="disabled">
                {t('code.resend.indication', {
                  time: store.resendThrottleTimerValue.toString(),
                })}
              </Text>
            )}
            {store.resendErrorMessage && (
              <Box inline marginLeft={1}>
                <ThemedText
                  className={s.resendErrorMessage}
                  skin="error"
                  size="small"
                >
                  {store.resendErrorMessage}
                </ThemedText>
              </Box>
            )}
          </Box>
        </Box>
      </PostLoginModal>
    );
  }
);

const ConfirmDetailsPhone: React.FC = observer(() => {
  const {
    rootStore: { phoneStore },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();

  return (
    <VerifyCodeContextProvider.Provider value={{ store: phoneStore }}>
      <VerifyCode
        title={t('post.login.recovery.phone.verify.title')}
        infoI18Key="post.login.confirm.details.phone.verify.info"
        target={phoneStore.getSelectedInternationalPhone()}
        clean={phoneStore.clean}
      />
    </VerifyCodeContextProvider.Provider>
  );
});

const RecoveryPhone: React.FC = observer(() => {
  const {
    rootStore: { phoneStore },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();

  return (
    <VerifyCodeContextProvider.Provider value={{ store: phoneStore }}>
      <VerifyCode
        title={t('post.login.recovery.phone.verify.title')}
        infoI18Key="post.login.recovery.phone.verify.info"
        target={phoneStore.getSelectedInternationalPhone()}
        clean={phoneStore.clean}
      />
    </VerifyCodeContextProvider.Provider>
  );
});

const Phone2FA: React.FC = observer(() => {
  const {
    rootStore: { phoneStore },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();

  return (
    <VerifyCodeContextProvider.Provider value={{ store: phoneStore }}>
      <VerifyCode
        title={t('post.login.mfa.phone.verify.title')}
        infoI18Key="post.login.mfa.phone.verify.info"
        target={phoneStore.getSelectedInternationalPhone()}
        clean={phoneStore.clean}
      />
    </VerifyCodeContextProvider.Provider>
  );
});

const ConfirmEmail: React.FC = observer(() => {
  const {
    rootStore: { confirmEmailStore, postLoginStore },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();

  return (
    <VerifyCodeContextProvider.Provider value={{ store: confirmEmailStore }}>
      <VerifyCode
        title={t('post.login.confirm.email.verify.title')}
        infoI18Key="post.login.confirm.email.verify.info"
        target={postLoginStore.userDetails?.email}
        clean={confirmEmailStore.clean}
      />
    </VerifyCodeContextProvider.Provider>
  );
});

const EmailChange: React.FC = observer(() => {
  const {
    rootStore: { changeAccountEmailStore },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();

  return (
    <VerifyCodeContextProvider.Provider
      value={{ store: changeAccountEmailStore }}
    >
      <VerifyCode
        title={t('post.login.change.account.email.verify.title')}
        infoI18Key="post.login.confirm.email.change.verify.info"
        target={changeAccountEmailStore.emailInput.value}
        clean={changeAccountEmailStore.clean}
      />
    </VerifyCodeContextProvider.Provider>
  );
});

export default Object.assign(VerifyCode, {
  ConfirmDetailsPhone,
  RecoveryPhone,
  Phone2FA,
  ConfirmEmail,
  EmailChange,
});
