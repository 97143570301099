import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Input } from '@wix/design-system';
import { AppContextProvider } from '../AppLoader';
import { InputField } from '../InputField';
import PostLoginModal from './PostLoginModal';
import { ThemedText } from '../ThemedComponents';
import { dataHooks } from '../../dataHooks';
import CustomLoader from '../Common/CustomLoader';

interface ChangeAccountEmailProps {}

const ChangeAccountEmail: React.FC<ChangeAccountEmailProps> = observer(() => {
  const {
    rootStore: { changeAccountEmailStore },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();

  return (
    <PostLoginModal
      title={t('post.login.change.account.email.title')}
      subtitle={t('post.login.change.account.email.subtitle')}
      primaryButtonAction={() => {
        changeAccountEmailStore.sendConfirmationCode();
      }}
      primaryButtonDisabled={
        changeAccountEmailStore.isSubmmited ||
        !changeAccountEmailStore.emailInput.isValid ||
        !changeAccountEmailStore.confirmEmailInput.isValid ||
        changeAccountEmailStore.emailInput.value !==
          changeAccountEmailStore.confirmEmailInput.value
      }
      primaryButtonText={
        changeAccountEmailStore.isSubmmited ? (
          <CustomLoader text={t('post.login.continue')} status="loading" />
        ) : (
          t('post.login.continue')
        )
      }
      backButton={{
        show: changeAccountEmailStore.status !== 'SUCCESS',
        action: changeAccountEmailStore.clean,
      }}
    >
      <>
        <Box direction="vertical" verticalAlign="top">
          <Box direction="vertical" paddingBottom={2} gap={1}>
            <ThemedText>
              {t('post.login.change.account.email.new.email')}
            </ThemedText>
            <InputField
              dataHook={dataHooks.postLogin.changeAccountEmail.emailInput}
              border="standard"
              formField={changeAccountEmailStore.emailInput}
              onChange={changeAccountEmailStore.emailInput.onChange}
              onBlur={() => {
                changeAccountEmailStore.updateFieldMatchError();
              }}
              onFocus={() =>
                changeAccountEmailStore.clearFieldErrors(
                  changeAccountEmailStore.confirmEmailInput,
                )
              }
              status={
                changeAccountEmailStore.emailInput.errorInfo &&
                changeAccountEmailStore.emailInput.isDirty
                  ? Input.StatusError
                  : undefined
              }
              statusMessage={
                changeAccountEmailStore.emailInput.isDirty &&
                changeAccountEmailStore.emailInput.errorInfo
              }
            />
          </Box>
          <Box direction="vertical" gap={1}>
            <ThemedText>
              {t('post.login.change.account.email.reenter.new.email')}
            </ThemedText>
            <InputField
              dataHook={
                dataHooks.postLogin.changeAccountEmail.confirmEmailInput
              }
              border="standard"
              formField={changeAccountEmailStore.confirmEmailInput}
              onChange={changeAccountEmailStore.confirmEmailInput.onChange}
              onBlur={() => {
                changeAccountEmailStore.updateFieldMatchError();
              }}
              status={
                changeAccountEmailStore.confirmEmailInput.errorInfo &&
                changeAccountEmailStore.confirmEmailInput.isDirty
                  ? Input.StatusError
                  : undefined
              }
              statusMessage={
                changeAccountEmailStore.confirmEmailInput.isDirty &&
                changeAccountEmailStore.confirmEmailInput.errorInfo
              }
              disablePaste
            />
          </Box>
        </Box>
      </>
    </PostLoginModal>
  );
});

export default ChangeAccountEmail;
