import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider, initI18n } from '@wix/wix-i18n-config';
import { AppLoader } from './components/AppLoader';
import { PureExperimentsProvider as ExperimentsProvider } from '@wix/wix-experiments-react';
import Experiments from '@wix/wix-experiments';
import { AccountSettingsHttpApi } from './services/accountSettingsApi';
import { DealerApi } from './services/dealerApi';
import { Box, Loader } from '@wix/design-system';

const experiments = new Experiments({ scope: 'users' });
sessionStorage.setItem('loginPageUrl', window.location.href);

const createAccountSettingsApi = () => new AccountSettingsHttpApi();
const createDealerApi = () => new DealerApi();
const i18n = window.__STATICS_URL__
  ? initI18n({
      locale: window.__LOCALE__,
      asyncMessagesLoader: (locale) =>
        import(`./assets/locales/messages_${locale}.json`),
      wait: true,
    })
  : initI18n({
      locale: window.__LOCALE__,
      messages: window.__MESSAGES__,
    });

ReactDOM.render(
  <Suspense
    fallback={
      <Box width="100%" height="100%" align="center" verticalAlign="middle">
        <Loader />
      </Box>
    }
  >
    <I18nextProvider i18n={i18n}>
      <ExperimentsProvider experiments={experiments}>
        <AppLoader
          createAccountSettingsApi={createAccountSettingsApi}
          createDealerApi={createDealerApi}
          i18n={i18n}
        />
      </ExperimentsProvider>
    </I18nextProvider>
  </Suspense>,
  document.getElementById('root')
);
