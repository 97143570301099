import { computed, observable, makeObservable } from 'mobx';

import { Constraint, constraints } from './validate';
import { FormField } from '../formField';
import { PremiumUsers2FaEnforcementStore } from './premiumUsers2FaEnforcement';

export class LoginInfoStore {
  public email: FormField;

  constructor(
    public premiumUsers2FaEnforcementStore: PremiumUsers2FaEnforcementStore
  ) {
    const emailRules: Constraint[] = [
      [
        constraints.email,
        this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
          'loginInfo.email.input.errorInfo'
        ),
      ],
      [
        constraints.required,
        this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
          'loginInfo.email.input.errorInfo'
        ),
      ],
    ];
    this.email = new FormField(
      this.premiumUsers2FaEnforcementStore.postLoginStore.userDetails.email,
      emailRules
    );
    makeObservable(this, {
      email: observable,
      isEmailVerified: computed,
    });
  }

  get isEmailVerified(): boolean {
    return (
      this.premiumUsers2FaEnforcementStore.postLoginStore.userDetails
        .isEmailVerified ||
      this.premiumUsers2FaEnforcementStore.postLoginStore.userDetails?.mailStatus?.startsWith(
        'VERIFIED'
      )
    );
  }
}
