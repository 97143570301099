import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, FormField } from '@wix/design-system';
import { Check } from '@wix/wix-ui-icons-common';
import { dataHooks } from '../../dataHooks';
import { AppContextProvider } from '../AppLoader';
import { ThemedText, ThemedTextButton } from '../ThemedComponents';
import PostLoginModal from './PostLoginModal';

const ConfirmDetails: React.FC<{}> = observer(() => {
  const { rootStore } = useContext(AppContextProvider);
  const { confirmDetailsStore, phoneStore } = rootStore;
  const internationalNumber =
    phoneStore.newInternationalNumber ?? phoneStore.phone?.internationalNumber;
  const { t } = useTranslation();

  return (
    <PostLoginModal
      title={t('post.login.confirm.details.title')}
      subtitle={t('post.login.confirm.details.subtitle')}
      primaryButtonText={
        confirmDetailsStore.isEmailVerified &&
        confirmDetailsStore.isRecoveryPhoneVerified
          ? t('post.login.done')
          : t('post.login.confirm')
      }
      secondaryButtonText={t('post.login.not.now')}
      secondaryButtonAction={confirmDetailsStore.showLater}
      primaryButtonAction={confirmDetailsStore.confirm}
    >
      <Box direction="vertical">
        <Box paddingBottom={3} direction="vertical">
          <FormField label={t('post.login.confirm.details.email')}>
            <Box direction="horizontal" align="space-between" width="100%">
              <ThemedText weight="normal">
                {rootStore.postLoginStore.userDetails?.email}
              </ThemedText>
              {confirmDetailsStore.isEmailVerified ? (
                <Box gap={1}>
                  <Check
                    style={{ color: '#25A55A' }}
                    size="18px"
                    viewBox="0 0 18 18"
                  />
                  <ThemedText skin="success">
                    {t('post.login.verified')}
                  </ThemedText>
                </Box>
              ) : (
                <ThemedTextButton
                  dataHook={dataHooks.postLogin.confirmDetails.editEmailButton}
                  as="a"
                  underline="always"
                  onClick={confirmDetailsStore.editEmail}
                  skin="standard"
                  size="small"
                >
                  {t('post.login.confirm.details.edit')}
                </ThemedTextButton>
              )}
            </Box>
          </FormField>
        </Box>
        <Divider />
        <Box paddingTop={3} direction="vertical">
          <FormField label={t('post.login.confirm.details.recovery.phone')}>
            <Box direction="horizontal" align="space-between" width="100%">
              <ThemedText weight="normal">{internationalNumber}</ThemedText>
              {confirmDetailsStore.isRecoveryPhoneVerified ? (
                <Box gap={1}>
                  <Check
                    style={{ color: '#25A55A' }}
                    size="18px"
                    viewBox="0 0 18 18"
                  />
                  <ThemedText skin="success">
                    {t('post.login.verified')}
                  </ThemedText>
                </Box>
              ) : (
                <ThemedTextButton
                  as="a"
                  underline="always"
                  onClick={confirmDetailsStore.editPhone}
                  skin="standard"
                  size="small"
                >
                  {t('post.login.confirm.details.edit')}
                </ThemedTextButton>
              )}
            </Box>
          </FormField>
        </Box>
      </Box>
    </PostLoginModal>
  );
});

export default ConfirmDetails;
