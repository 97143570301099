import { makeObservable, observable } from 'mobx';
import { RootStore } from './root';

export class PoliciesStore {
  private readonly rootStore: RootStore;
  public readonly termsOfUseUrl: string;
  public readonly captchaTermsOfUseUrl: string;
  public readonly privacyPolicyUrl: string;
  public readonly captchaPrivacyPolicyUrl: string;
  public readonly expandedPolicyKey: string;
  public readonly verticalTermsOfUseUrl: string;
  public readonly verticalPrivacyPolicyUrl: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    const getPublicAbsoluteUrl =
      this.rootStore.navigationStore.getPublicAbsoluteUrl;
    const policiesPrefix = this.rootStore.displayStore.isEditorX
      ? 'info'
      : 'about';
    this.termsOfUseUrl = getPublicAbsoluteUrl(
      `/${policiesPrefix}/terms-of-use`,
    );
    this.privacyPolicyUrl = getPublicAbsoluteUrl(`/${policiesPrefix}/privacy`);
    this.captchaTermsOfUseUrl = 'https://policies.google.com/terms';
    this.captchaPrivacyPolicyUrl = 'https://policies.google.com/privacy';
    const isWixEducation = this.rootStore.displayStore.isWixEducation;
    this.expandedPolicyKey = isWixEducation
      ? 'policies_section.signup.education'
      : 'policies_section.signup_content';
    this.verticalTermsOfUseUrl = isWixEducation
      ? getPublicAbsoluteUrl('/education/terms-of-use')
      : '';
    this.verticalPrivacyPolicyUrl = isWixEducation
      ? getPublicAbsoluteUrl('/education/wix-education-privacy-policy')
      : '';
    makeObservable(this, {
      termsOfUseUrl: observable,
      privacyPolicyUrl: observable,
      captchaTermsOfUseUrl: observable,
      captchaPrivacyPolicyUrl: observable,
      expandedPolicyKey: observable,
      verticalTermsOfUseUrl: observable,
      verticalPrivacyPolicyUrl: observable,
    });
  }
}
