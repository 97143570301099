import { observer } from 'mobx-react';
import React, { ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Heading,
  Input,
  InputWithOptions,
  RadioGroup,
  Text,
} from '@wix/design-system';
import { UserChoice } from '../../stores/phone';
import { AppContextProvider } from '../AppLoader';
import CustomLoader from '../Common/CustomLoader';
import { InputField } from '../InputField';
import PostLoginModal from './PostLoginModal';
import s from './PostLogin.scss';
import { StatusWarningFilled } from '@wix/wix-ui-icons-common';

interface PhoneProps {
  title: React.ReactNode;
  subtitle: string;
  primaryButtonText: string;
  showBackButton: boolean;
  children: ReactNode;
  showNewNumberInput?: boolean;
  secondaryButtonText?: string;
  secondaryButtonAction?: () => void;
  aggressive?: boolean;
}

const Phone: React.FC<PhoneProps> = observer(
  ({
    children,
    title,
    subtitle,
    showNewNumberInput,
    primaryButtonText,
    showBackButton,
    secondaryButtonText,
    secondaryButtonAction,
    aggressive,
  }) => {
    const { t } = useTranslation();
    const {
      rootStore: { phoneStore },
    } = useContext(AppContextProvider);

    return (
      <PostLoginModal
        title={title}
        subtitle={subtitle}
        primaryButtonText={
          phoneStore.isSubmitted ? (
            <CustomLoader text={primaryButtonText} status="loading" />
          ) : (
            primaryButtonText
          )
        }
        primaryButtonAction={() =>
          !phoneStore.isSubmitted && phoneStore.onSendVerificationSms()
        }
        primaryButtonDisabled={!phoneStore.phoneNumber.isValid}
        secondaryButtonText={secondaryButtonText}
        secondaryButtonAction={secondaryButtonAction}
        backButton={{
          show: showBackButton,
          action: phoneStore.clean,
        }}
        aggressive={aggressive}
      >
        <Box direction="vertical">
          {children}
          {(showNewNumberInput ||
            phoneStore.userChoice === UserChoice.NewNumber) && (
            <Box direction="horizontal" gap="22px" marginTop="12px">
              <InputWithOptions
                selectedId={phoneStore.countryCode?.id}
                value={phoneStore.countryCode?.id}
                options={phoneStore.countryCodesOptions}
                onSelect={phoneStore.onSelectCountryCode}
                onChange={(e) => phoneStore.onSearchCountries(e.target.value)}
                prefix={phoneStore.countryCode?.icon}
                className={s.countriesDropdown}
                popoverProps={{ appendTo: 'window', zIndex: '9999' } as any}
              />
              <InputField
                autocomplete="phone-number"
                autoFocus
                formField={phoneStore.phoneNumber}
                placeholder={t('post.login.add_phone.placeholder')}
                value={phoneStore.phoneNumber.value}
                onChange={phoneStore.phoneNumber.onChange}
                onBlur={phoneStore.phoneNumber.onBlur}
                status={
                  !phoneStore.phoneNumber.isValid &&
                  phoneStore.phoneNumber.isDirty
                    ? Input.StatusError
                    : undefined
                }
                onEnterPressed={() => phoneStore.onSendVerificationSms()}
                statusMessage={phoneStore.phoneNumber.errorInfo}
                className={s.phoneInput}
              />
            </Box>
          )}
        </Box>
      </PostLoginModal>
    );
  },
);

const Recovery: React.FC<{}> = observer(() => {
  const { t } = useTranslation();
  const {
    rootStore: { phoneStore },
  } = useContext(AppContextProvider);
  phoneStore.userChoice = UserChoice.NewNumber;
  return (
    <Phone
      title={t('post.login.recovery.phone.title')}
      subtitle={t('post.login.recovery.phone.subtitle')}
      primaryButtonText={t('post.login.next')}
      secondaryButtonText={t('post.login.not.now')}
      secondaryButtonAction={phoneStore.showLater}
      showBackButton={false}
    >
      <Box direction="vertical">
        <Box marginBottom={3}>
          <Text>{t('post.login.recovery.phone.input.label')}</Text>
        </Box>
        <RadioGroup
          value={phoneStore.userChoice}
          onChange={phoneStore.userChoiceChange}
        />
      </Box>
    </Phone>
  );
});

const Mfa: React.FC<{ aggresive?: boolean }> = observer(({ aggresive }) => {
  const { t } = useTranslation();
  const {
    rootStore: { phoneStore, postLoginStore },
  } = useContext(AppContextProvider);
  const isPhoneNumberExist = !!phoneStore.phone?.internationalNumber;
  if (!isPhoneNumberExist) {
    phoneStore.userChoice = UserChoice.NewNumber;
  }

  const header = (() => {
    if (aggresive) {
      return {
        title: (
          <Box direction="horizontal">
            <Box marginRight={1}>
              <StatusWarningFilled color="#FFB700" />
            </Box>
            <Heading size="medium" as="h1">
              {t('post.login.mfa.phone.aggresive.title')}
            </Heading>
          </Box>
        ),
        subtitle: t('post.login.mfa.phone.aggresive.subtitle'),
      };
    } else if (isPhoneNumberExist) {
      return {
        title: t('post.login.mfa.phone.choose.title'),
        subtitle: t('post.login.mfa.phone.subtitle'),
      };
    } else {
      return {
        title: t('post.login.mfa.phone.title'),
        subtitle: t('post.login.mfa.phone.subtitle'),
      };
    }
  })();

  return (
    <Phone
      title={header.title}
      subtitle={header.subtitle}
      primaryButtonText={t('post.login.next')}
      secondaryButtonText={!aggresive ? t('post.login.not.now') : undefined}
      secondaryButtonAction={!aggresive ? postLoginStore.showLater : undefined}
      showBackButton={false}
      aggressive={aggresive}
    >
      <Box direction="vertical">
        <Box direction="vertical">
          {isPhoneNumberExist ? (
            <Box direction="vertical">
              <Box marginBottom="24px">
                <Text>{t('post.login.mfa.phone.which.number')}</Text>
              </Box>
              <RadioGroup
                value={phoneStore.userChoice}
                onChange={phoneStore.userChoiceChange}
              >
                <RadioGroup.Radio value={UserChoice.RecoveryNumber}>
                  {`${phoneStore.phone?.internationalNumber} ${t(
                    'post.login.recovery.phone.number.info',
                  )}`}
                </RadioGroup.Radio>
                <RadioGroup.Radio value={UserChoice.NewNumber}>
                  {t('post.login.recovery.phone.enter.new.number')}
                </RadioGroup.Radio>
              </RadioGroup>
            </Box>
          ) : (
            <Box direction="vertical">
              <Box marginBottom={3}>
                <Text>{t('post.login.mfa.phone.input.label')}</Text>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Phone>
  );
});

const Confirm: React.FC<{}> = observer(() => {
  const { t } = useTranslation();
  const {
    rootStore: { phoneStore },
  } = useContext(AppContextProvider);
  phoneStore.userChoice = UserChoice.NewNumber;
  return (
    <Phone
      title={t('post.login.recovery.change.phone.title')}
      subtitle={t('post.login.recovery.phone.subtitle')}
      primaryButtonText={t('post.login.continue')}
      showBackButton={true}
      showNewNumberInput={true}
    >
      <Box direction="vertical" paddingBottom={4}>
        <Text>{t('post.login.recovery.phone.new.phone.number')}</Text>
      </Box>
    </Phone>
  );
});

export default Object.assign(Phone, {
  Recovery,
  Mfa,
  Confirm,
});
