import { makeObservable, observable } from 'mobx';
import { RootStore } from './root';
import { PasswordFormField } from './passwordFormField';

export abstract class EnterPasswordStore {
  protected readonly rootStore: RootStore;
  public passwordField: PasswordFormField;
  abstract submit(): void;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.passwordField = new PasswordFormField('');
    makeObservable(this, {
      passwordField: observable,
    });
  }

  public hasError(): boolean {
    return !!(this.passwordField.errorInfo || this.passwordField.serverError);
  }
  isSubmittable(): boolean {
    return this.passwordField.isValid;
  }
}
