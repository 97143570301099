export const dataHooks = {
  app: {
    root: 'appRootDataHook',
    header: 'appHeaderDataHook',
    lock: {
      modal: 'lockModal',
      password: {
        formField: 'password',
        input: 'passwordInput',
      },
      retypeModal: {
        main: 'retypeModal',
        password: {
          formField: 'password',
          input: 'passwordInput',
        },
        newLockedField: {
          formField: 'newLockedField',
          input: 'newLockedFieldInput',
        },
        confirmLockedField: {
          formField: 'confirmLockedField',
          input: 'confirmLockedFieldInput',
        },
      },
    },
  },
  confirmationIndication: {
    icon: 'confirmationIndicationIcon',
    text: 'confirmationIndicationText',
  },
  saveIndication: {
    text: 'confirmationIndicationText',
  },
  basicInfo: {
    header: 'basicInfoCardHeaderDataHook',
    firstName: {
      formField: 'basicInfoFirstName',
      input: 'basicInfoFirstNameInput',
    },
    lastName: {
      formField: 'basicInfoLastName',
      input: 'basicInfoLastNameInput',
    },
    username: {
      formField: 'basicInfoUsername',
      input: 'basicInfoUsernameInput',
    },
    profileImage: {
      main: 'basicInfoProfileImage',
      title: 'basicInfoProfileImageTitle',
      input: 'basicInfoProfileImageInput',
      remove: 'basicInfoProfileImageRemove',
      avatar: 'basicInfoProfileImageAvatar',
    },
  },
  loginInfo: {
    header: 'loginInfoCardHeaderDataHook',
    email: {
      formField: 'loginInfoEmail',
      input: 'loginInfoEmailInput',
      confirm: 'loginInfoEmailConfirm',
    },
    addPhone: {
      formField: 'loginInfoPhone',
      addButton: 'loginInfoPhoneButton',
      description: {
        number: 'loginInfoPhoneDescriptionNumber',
      },
      menu: {
        main: 'loginInfoPhoneMenu',
        edit: 'loginInfoPhoneMenuEdit',
        remove: 'loginInfoPhoneMenuRemove',
      },
      modal: {
        main: 'phoneModal',
      },
    },
    add2FAPhone: {
      formField: 'loginInfo2FAPhone',
      addButton: 'loginInfo2FAPhoneButton',
      description: {
        number: 'loginInfo2FAPhoneDescriptionNumber',
      },
      menu: {
        main: 'loginInfo2FAPhoneMenu',
        edit: 'loginInfo2FAPhoneMenuEdit',
        remove: 'loginInfo2FAPhoneMenuRemove',
      },
      modal: {
        main: '2FAPhoneModal',
      },
    },
    password: {
      button: 'loginInfoPasswordButton',
      input: {
        formField: 'loginInfoPassword',
        input: 'loginInfoPasswordInput',
      },
      modal: {
        main: 'passwordModal',
        currentPassword: {
          formField: 'currentPassword',
          input: 'currentPasswordInput',
        },
        newPassword: {
          formField: 'newPassword',
          input: 'newPasswordInput',
        },
        confirmPassword: {
          formField: 'confirmPassword',
          input: 'confirmPasswordInput',
        },
      },
    },
  },
  socialLogins: {
    header: 'socialLoginsCardHeaderDataHook',
    table: {
      main: 'socialLoginsTableDataHook',
      google: 'socialLoginsTableGoogle',
      facebook: 'socialLoginsTableFacebook',
      apple: 'socialLoginsTableApple',
    },
  },
  emailPreferences: {
    header: 'emailPreferencesCardHeaderDataHook',
  },
  partnersDashboard: {
    header: 'partnersDashboardCardHeaderDataHook',
  },
  privacySettings: {
    header: 'privacySettingsCardHeaderDataHook',
  },
  deactivate: {
    header: 'deactivateCardHeaderDataHook',
  },
  confirmationModal: {
    modal: 'confirmationModal',
  },
  confirmEmailModal: {
    firstStep: {
      modal: 'confirmEmailModal',
    },
    secondStep: {
      confirmationEmail: {
        modal: 'confirmEmailConfirmationEmailStep',
      },
      confirmationCode: {
        modal: 'confirmEmailEnterCodeStep',
      },
    },
  },
  notification: {
    main: 'notification',
  },
  floatingNotification: {
    main: 'notification',
  },
  accountSecurity: {
    title: 'accountSecurityTitle',
    email: {
      title: 'accountSecurityEmailTitle',
      completed: {
        content: 'accountSecurityEmailCompletedContent',
        icon: 'accountSecurityEmailCompletedIcon',
        action: 'accountSecurityEmailCompletedAction',
      },
      required: {
        content: 'accountSecurityEmailRequiredContent',
        icon: 'accountSecurityEmailRequiredIcon',
        action: 'accountSecurityEmailRequiredAction',
      },
    },
    sso: {
      icon: 'accountSecuritySSOIcon',
      title: 'accountSecuritySSOTitle',
      content: 'accountSecuritySSOContent',
    },
    twoFA: {
      title: 'accountSecurity2FATitle',
      completed: {
        content: 'accountSecurity2FACompletedContent',
        icon: 'accountSecurity2FACompletedIcon',
        action: 'accountSecurity2FACompletedAction',
      },
      required: {
        content: 'accountSecurity2FARequiredContent',
        icon: 'accountSecurity2FARequiredIcon',
        action: 'accountSecurity2FARequiredAction',
      },
    },
    login: {
      title: 'accountSecurityLoginTitle',
      content: 'accountSecurityLoginContent',
      icon: 'accountSecurityLoginIcon',
      action: 'accountSecurityLoginAction',
    },
  },
  multiFactorAuth: {
    header: 'multiFactorAuthCardHeaderDataHook',
    rows: 'multiFactorAuthCardHeaderRows',
    suggestions: 'multiFactorAuthSuggestions',
    suggestionTitle: 'multiFactorAuthSuggestionTitle',
    addPhone: {
      label: 'multiFactorAuthPhone',
      addButton: 'multiFactorAuthPhoneButton',
      description: {
        number: 'multiFactorAuthPhoneDescriptionNumber',
      },
      menu: {
        main: 'multiFactorAuthPhoneMenu',
        edit: 'multiFactorAuthPhoneMenuEdit',
        remove: 'multiFactorAuthPhoneMenuRemove',
      },
      modal: {
        main: 'multiFactorAuthPhoneModal',
        codeStepModal: {
          codeInput: {
            formField: 'confirmPassword',
            input: 'confirmPasswordInput',
          },
        },
      },
      passwordField: {
        formField: 'add-phone-wizard-password-form-field',
        input: 'add-phone-wizard-password-input',
      },
    },
    addEmail: {
      label: 'multiFactorAuthEmail',
      addButton: 'multiFactorAuthEmailButton',
      description: {
        number: 'multiFactorAuthEmailDescriptionNumber',
      },
      menu: {
        main: 'multiFactorAuthEmailMenu',
        edit: 'multiFactorAuthEmailuEdit',
        remove: 'multiFactorAuthEmailRemove',
      },
      modal: {
        main: 'multiFactorAuthEmailModal',
      },
    },
    addAuthApp: {
      row: 'multiFactorAuthenticatorAppRow',
      label: 'multiFactorAuthenticatorAppLabel',
      addButton: 'multiFactorAuthenticatorAppButton',
      description: {
        text: 'multiFactorAuthenticatorAppInfoText',
        link: 'multiFactorAuthenticatorAppInfoLink',
      },
      menu: {
        main: 'multiFactorAuthenticatorAppMenu',
        edit: 'multiFactorAuthenticatorAppEdit',
        remove: 'multiFactorAuthenticatorAppRemove',
      },
      qrModal: {
        main: 'mfaAppQrModal',
      },
      codeModal: {
        main: 'mfaAppCodeModal',

        codeField: {
          formField: 'mfaAppCodeModal.codeField.formField',
          input: 'mfaAppCodeModal.codeField.input',
        },
      },
    },
    addOwnerApp: {
      row: 'multiFactorOwnerAppRow',
      addButton: 'multiFactorOwnerAppButton',
      menu: {
        main: 'multiFactorOwnerAppMenu',
        edit: 'multiFactorOwnerAppMenuEdit',
        remove: 'multiFactorOwnerAppMenuRemove',
      },
    },
    addBackupMethod: {
      modal: {
        main: 'addBackupMethodModal',
      },
    },
    suggestAuthMethod: {
      modal: {
        main: 'suggestAuthMethodModal',
      },
    },
  },
  changeFieldModal: {
    main: 'changeFieldModal.main',
    field: {
      formField: 'changeFieldModal.field.formField',
      input: 'changeFieldModal.field.input',
    },
  },
  activityLog: {
    header: 'activityLog.header',
  },
  ownerAppModal: {
    main: 'ownerAppModal',
    loader: 'ownerAppModal.loader',
    options: 'ownerAppModal.options',
    deviceName: 'ownerAppModal.deviceName',
    steps: 'ownerAppModal.steps',
    qrCode: 'ownerAppModal.qr',
  },
};
