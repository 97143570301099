import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { Box, RadioGroup, Text } from '@wix/design-system';
import { useTranslation } from 'react-i18next';
import { AppContextProvider } from '../../../AppLoader';
import { dataHooks } from '../../../../dataHooks';

export const ChooseDeviceStep: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { ownerAppStore: store },
      },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  return (
    <Box direction="vertical">
      <Box marginBottom={2}>
        <Text>{t('addOwnerApp.wizard.choose.label')}</Text>
      </Box>
      <Box>
        {store.devices.length === 1 ? (
          <Text weight="bold">{store.devices[0].name}</Text>
        ) : (
          <RadioGroup
            dataHook={dataHooks.ownerAppModal.options}
            value={store.selectedDevice?.id}
            onChange={(id?: string | number) => store.onSelectDevice(id)}
          >
            {store.devices.map(({ id, name }) => (
              <RadioGroup.Radio key={id} value={id}>
                <Text
                  weight={store.selectedDevice?.id === id ? 'bold' : 'thin'}
                  secondary={store.selectedDevice?.id !== id}
                  color="dark"
                >
                  {name}
                </Text>
              </RadioGroup.Radio>
            ))}
          </RadioGroup>
        )}
      </Box>
      <Box marginTop={2}>
        <Text secondary color="dark">
          {t('addOwnerApp.wizard.choose.message')}
        </Text>
      </Box>
    </Box>
  );
});
