import { DialogHeader } from '../DialogHeader/DialogHeader';
import React, { useContext } from 'react';
import s from './ResetPassword.scss';
import { AppContextProvider } from '../AppLoader';
import { Box } from '@wix/design-system';
import { dataHooks } from '../../dataHooks';
import { keys } from '../../translationsKeys';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { PasswordFieldWithLabel } from '../PasswordField/PasswordField';
import { DialogFormContainer } from '../DialogContainer';
import { SubmitButton } from '../SubmitButton';

export const ResetPassword: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    rootStore: {
      resetPasswordStore,
      managePasswordStore: { passwordPolicy },
      displayStore: { isMobile },
    },
  } = useContext(AppContextProvider);

  return (
    <Box direction="vertical" width="100%" align="center">
      <DialogFormContainer
        dataHook={dataHooks.resetPassword.container}
        mainHeaderProps={{ showBackButton: false }}
      >
        <DialogHeader>
          <DialogHeader.Title as="h3">
            {t(keys['reset_password.title'])}
          </DialogHeader.Title>
        </DialogHeader>
        <Box
          direction="vertical"
          align="center"
          className={s.resetPasswordContent}
          gap="10px"
        >
          <PasswordFieldWithLabel
            className={s.resetPasswordInput}
            required
            formField={resetPasswordStore.passwordField}
            label={t(keys['reset_password.password.title'])}
            dataHook={dataHooks.resetPassword.password}
            autocomplete="new-password"
            isMobile={isMobile}
            showStrength
            showVisibility={resetPasswordStore.passwordField.value.length > 0}
            value={resetPasswordStore.passwordField.value}
            onBlur={resetPasswordStore.updatePasswordFieldMatchError}
          />
          <PasswordFieldWithLabel
            className={s.resetPasswordInput}
            required
            disablePaste
            formField={resetPasswordStore.confirmPasswordField}
            label={t(keys['reset_password.repassword.title'])}
            dataHook={dataHooks.resetPassword.rePassword}
            onEnterPressed={resetPasswordStore.submit}
            showVisibility={
              resetPasswordStore.confirmPasswordField.value.length > 0
            }
            value={resetPasswordStore.confirmPasswordField.value}
            onBlur={resetPasswordStore.updatePasswordFieldMatchError}
            autocomplete="new-password"
          />
          <Box align="center">
            <SubmitButton
              disabled={!resetPasswordStore.isSubmittable()}
              onClick={resetPasswordStore.submit}
              skin="standard"
            >
              {t(keys['reset_password.submit'])}
            </SubmitButton>
          </Box>
        </Box>
      </DialogFormContainer>
    </Box>
  );
});
