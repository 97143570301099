import React, { FC, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Box, Text, TextButton } from '@wix/design-system';
import { ApprovalChallengeStatus } from '@wix/ambassador-identity-v1-verification/types';
import { ReactComponent as ConfirmDeviceIllustration } from '../../../../assets/images/confirmDevice.svg';
import { ReactComponent as DeniedIllustration } from '../../../../assets/images/denied.svg';
import { useTranslation } from 'react-i18next';
import { AppContextProvider } from '../../../AppLoader';
import { dataHooks } from '../../../../dataHooks';
import { ResendVerificationCode } from '../../ResendVerificationCode';

const Pending: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { ownerAppStore: store },
      },
    },
  } = useContext(AppContextProvider);
  useEffect(
    () => () => {
      store.stopPolling();
    },
    [store]
  );
  const { t } = useTranslation();
  const name = store.selectedDevice?.name;
  return (
    <Box gap="SP4">
      <Box width={96}>
        <ConfirmDeviceIllustration />
      </Box>
      <Box direction="vertical" gap="SP1">
        <Box direction="vertical">
          <Text size="medium" weight="normal">
            {t('addOwnerApp.wizard.confirm.label.title')}
          </Text>
          <Text size="medium">
            {t('addOwnerApp.wizard.confirm.label', { deviceName: `${name}` })}
          </Text>
        </Box>
        <Text size="medium">{t('addOwnerApp.wizard.confirm.message')}</Text>
        <ResendVerificationCode
          sendConfirmationCode={() => store.sendPushToSelectedDevice(true)}
          resendSuccessfullyIndication={store.resendSuccessfullyIndication}
          resendThrottleTimerValue={store.resendThrottleTimerValue}
          resendErrorMessage={store.resendErrorMessage}
          resendButtonTranslationKey="addOwnerApp.wizard.confirm.resend-link"
          resendIndicationTranslationKey="request.resend.indication"
          resendButtonSize="medium"
        />
      </Box>
    </Box>
  );
});

const Error: FC = () => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { ownerAppStore: store },
      },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  return (
    <Box gap="SP4">
      <Box width={96}>
        <DeniedIllustration />
      </Box>
      <Box direction="vertical" gap="SP1">
        <Box direction="vertical">
          <Text size="medium" weight="normal">
            {t(
              store.authStatus === ApprovalChallengeStatus.EXPIRED
                ? 'addOwnerApp.wizard.timeout.title'
                : 'addOwnerApp.wizard.denied.title'
            )}
          </Text>
          <Text
            size="medium"
            weight="bold"
            dataHook={dataHooks.ownerAppModal.deviceName}
          ></Text>
        </Box>
        <Text size="medium" weight="thin">
          {t(
            store.authStatus === ApprovalChallengeStatus.EXPIRED
              ? 'addOwnerApp.wizard.timeout.message'
              : 'addOwnerApp.wizard.denied.message'
          )}
        </Text>
        <TextButton
          size="medium"
          weight="thin"
          onClick={() => store.sendPushToSelectedDevice(true, store.authStatus)}
        >
          {t('addOwnerApp.wizard.timeout.resend-link')}
        </TextButton>
      </Box>
    </Box>
  );
};

export const ConfirmDeviceStep = { Pending, Error };
