import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CustomModalLayout, Stepper, Text } from '@wix/design-system';
import s from '../modals.scss';
import { dataHooks } from '../../../../stores/premiumUsers2FaEnforcement/dataHooks';
import { AppContextProvider } from '../../../AppLoader';
import { UiStore } from './AddPhoneUiStore';
import { SuperInput } from '../SuperInput';
import VerifyButton from '../../VerifyButton';
import { ResendVerificationCode } from '../../ResendVerificationCode';

export const ConfirmCodeStep: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { add2FAPhoneStore: store },
      },
    },
  } = useContext(AppContextProvider);
  const uiStore = new UiStore(store);
  const ownerAppStore = store.premiumUsers2FaEnforcementStore.ownerAppStore;
  const content = (
    <div className={s.addPhoneContainer}>
      {uiStore.isBackupMethod && (
        <div className={s.stepper}>
          <Stepper
            type="text"
            activeStep={ownerAppStore.activeStep}
            steps={ownerAppStore.steps}
          />
        </div>
      )}
      <Box marginBottom={2} flexWrap="wrap">
        <Text>{t('add_phone_wizard.enter_code.line1')}</Text>
        <Text weight={'bold'}>{store.getSelectedInternationalPhone()}</Text>
      </Box>
      <SuperInput
        field={store.confirmCode}
        dataHooks={
          dataHooks.multiFactorAuth.addPhone.modal.codeStepModal.codeInput
        }
        autocomplete="new-password"
        autoFocus
        placeholder={t('add_phone_wizard.enter_code.placeholder')}
        value={store.confirmCode.value}
        onEnterPressed={store.onVerifyPhoneCode}
        shouldError={!store.confirmCode.isValid && store.confirmCode.isTouched}
      />
      <Box marginTop={1}>
        <ResendVerificationCode
          sendConfirmationCode={store.onResendVerificationSms}
          resendSuccessfullyIndication={store.resendSuccessfullyIndication}
          resendThrottleTimerValue={store.resendThrottleTimerValue}
          resendErrorMessage={store.resendErrorMessage}
          resendButtonSize="small"
          resendIndicationTranslationKey="code.resend.indication"
        />
      </Box>
    </div>
  );

  return (
    <CustomModalLayout
      className={s.enforcementSmsModal}
      removeContentPadding={true}
      primaryButtonText={
        <VerifyButton verificationState={store.verificationState} />
      }
      dataHook="fullscreen-modal"
      primaryButtonOnClick={store.onVerifyPhoneCode}
      sideActions={uiStore.sideActions}
      title={uiStore.title}
      subtitle={uiStore.subtitle}
      primaryButtonProps={{
        disabled: !store.confirmCode.isValid || !store.confirmCode.isDirty,
      }}
      showHeaderDivider
    >
      {content}
    </CustomModalLayout>
  );
});
