import { observable, action, makeObservable } from 'mobx';

interface IOpen {
  content: string;
  theme?: ITheme;
  onClose?(): void;
  autoHide?: boolean;
  actionButtonProps?: ActionButtonProps;
  actionText?: string;
}

interface ActionButtonProps {
  type?: 'button' | 'textLink';
  onClick?(): any;
  link?: string;
}

type IThemeFloatingNotification =
  | 'standard'
  | 'success'
  | 'destructive'
  | 'warning'
  | 'premium'
  | 'preview'
  | 'dark';
type IThemeNotification =
  | 'standard'
  | 'error'
  | 'success'
  | 'warning'
  | 'premium';

type ITheme = IThemeFloatingNotification | IThemeNotification;
type IType = 'global' | 'local' | 'sticky';

export class NotificationStore {
  public isOpen: boolean = false;
  public theme?: ITheme;
  public autoHide: boolean = true;
  public actionButtonProps?: ActionButtonProps;
  public actionText?: string;

  public type: IType = 'sticky';
  public autoHideTimeout: number = 7000;
  public content?: string;
  public onClose?: () => void;

  constructor(type?: IType) {
    makeObservable(this, {
      isOpen: observable,
      theme: observable,
      autoHide: observable,
      actionButtonProps: observable,
      actionText: observable,
      open: action,
      close: action.bound,
    });
    this.type = type ?? this.type;
  }

  public open({
    content,
    theme = 'success',
    onClose = this.close,
    autoHide = true,
    actionButtonProps,
    actionText,
  }: IOpen) {
    this.content = content;
    this.theme = theme;
    this.onClose = onClose;
    this.isOpen = true;
    this.autoHide = autoHide;
    this.actionButtonProps = actionButtonProps;
    this.actionText = actionText;
  }

  public close = () => {
    this.isOpen = false;
    this.content = undefined;
    this.theme = undefined;
    this.onClose = undefined;
    this.actionButtonProps = undefined;
  };
}
