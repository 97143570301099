import { Box } from '@wix/design-system';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContextProvider } from '../AppLoader';
import CustomLoader from '../Common/CustomLoader';
import { FloatingNotification } from '../Common/FloatingNotification';
import { PasswordField } from '../PasswordField/PasswordField';
import { ThemedText, ThemedTextButton } from '../ThemedComponents';
import PostLoginModal from './PostLoginModal';
import { PASSWORD_MIN_LENGTH_OLD } from '../../utils/validators';

export interface PasswordProps {
  title: string;
  subtitle: string;
  type: 'change' | 'create';
  onSave: () => void;
  modalWidth?: number;
}

const ManagePassword: React.FC<PasswordProps> = observer(
  ({ title, subtitle, type, modalWidth, onSave }) => {
    const {
      rootStore: { managePasswordStore },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();
    const {
      currentPassword,
      newPassword,
      confirmPassword,
    } = managePasswordStore;
    return (
      <PostLoginModal
        title={title}
        subtitle={subtitle}
        width={modalWidth}
        secondaryButtonText={t('post.login.not.now')}
        secondaryButtonAction={managePasswordStore.showLater}
        primaryButtonAction={() => !managePasswordStore.saving && onSave()}
        primaryButtonDisabled={!managePasswordStore.isSubmittable(type)}
        primaryButtonText={
          managePasswordStore.saving ? (
            <CustomLoader text={t('post.login.saving')} status="loading" />
          ) : managePasswordStore.saved ? (
            <CustomLoader text={t('post.login.saved')} status="success" />
          ) : (
            t('post.login.save')
          )
        }
      >
        <Box direction="vertical" gap={2}>
          <FloatingNotification
            store={managePasswordStore.forgotPasswordNotification}
          />
          {type === 'change' && (
            <Box>
              <Box direction="vertical" gap={1} width="100%">
                <Box align='space-between'>
                  <ThemedText>{t('post.login.current.password')}</ThemedText>
                  <ThemedTextButton
                    as="a"
                    size="small"
                    onClick={managePasswordStore.onForgotPassword}
                    skin="standard"
                  >
                    {t('post.login.forgot.password')}
                  </ThemedTextButton>
                </Box>
                <PasswordField
                  required
                  autocomplete="current-password"
                  formField={currentPassword}
                  autoFocus
                  value={currentPassword.value}
                  minLength={PASSWORD_MIN_LENGTH_OLD}
                  showVisibility={!!currentPassword.value?.length}
                />
              </Box>
            </Box>
          )}
          <Box direction="vertical" gap={1}>
            <ThemedText>{t('post.login.enter.new.password')}</ThemedText>
            <PasswordField
              required
              autocomplete="new-password"
              formField={newPassword}
              value={newPassword.value}
              onChange={managePasswordStore.onNewPasswordFieldsChange}
              showVisibility={!!newPassword.value?.length}
              passwordPolicy={managePasswordStore.passwordPolicy?.strength}
              showStrength
            />
          </Box>
          <Box direction="vertical" gap={1}>
            <ThemedText>{t('post.login.confirm.new.password')}</ThemedText>
            <PasswordField
              required
              autocomplete="new-password"
              formField={confirmPassword}
              value={confirmPassword.value}
              onEnterPressed={() =>
                managePasswordStore.isSubmittable(type) && onSave()
              }
              onChange={managePasswordStore.onNewPasswordFieldsChange}
              showVisibility={!!confirmPassword.value?.length}
            />
          </Box>
        </Box>
      </PostLoginModal>
    );
  }
);

const Change = observer(() => {
  const { t } = useTranslation();
  const {
    rootStore: { managePasswordStore },
  } = useContext(AppContextProvider);
  return (
    <ManagePassword
      title={t('post.login.change.password.title')}
      subtitle={t('post.login.change.password.subtitle')}
      onSave={managePasswordStore.onChangePassword}
      type="change"
    />
  );
});

const Create = observer(() => {
  const { t } = useTranslation();
  const {
    rootStore: { managePasswordStore },
  } = useContext(AppContextProvider);
  return (
    <ManagePassword
      title={t('post.login.create.password.title')}
      subtitle={t('post.login.create.password.subtitle')}
      onSave={managePasswordStore.onSetPassword}
      type="create"
      modalWidth={550}
    />
  );
});
export default Object.assign(ManagePassword, { Change, Create });
