import { observable, makeObservable, computed } from 'mobx';
import { ACCOUNT_SETTINGS_URL } from '../utils/constants';
import { concatParameterToUrl } from '../utils/general';
import { RootStore } from './root';

export class SsoEmailAlreadyExistStore {
  public transParams: { [key: string]: string };
  private readonly rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.transParams = {
      ssoAccountEmail: this.rootStore.ssoStore.existingWixAccountEmail,
      ssoAccountName: this.rootStore.ssoStore.accountName,
    };
    makeObservable(this, {
      transParams: observable,
      titleKey: computed,
      submitButtonKey: computed,
      contentKey: computed,
      linkKey: computed,
      secondaryLogoUrl: computed,
    });
  }

  get titleKey(): string {
    return 'sso.alreadyHasAccount.title';
  }

  get submitButtonKey(): string {
    return 'sso.alreadyHasAccount.submitButton.text';
  }

  get contentKey(): string {
    return 'sso.alreadyHasAccount.content';
  }

  get linkKey(): string {
    return 'sso.alreadyHasAccount.learnMoreLink.text';
  }

  get ssoArticleLink(): string {
    return `https://support.wix.com/${this.rootStore.i18n.language}/article/wix-partners-about-joining-a-team`;
  }

  get secondaryLogoUrl(): string {
    return this.rootStore.ssoStore.accountImage;
  }

  get enableAccess(): boolean {
    return !!this.rootStore.ssoStore.existingWixAccountEmail;
  }

  public async goToAccountSettings() {
    if (
      this.rootStore.userDataStore.currentLoggedInEmail ===
      this.rootStore.ssoStore.existingWixAccountEmail
    ) {
      const ssoLandingPageUrl = new URL(this.rootStore.ssoStore.initialSsoUrl);
      ssoLandingPageUrl.searchParams.delete('errorCode');
      this.rootStore.navigationStore.redirect(
        concatParameterToUrl(
          `${ACCOUNT_SETTINGS_URL}?focus=change_email_modal&postFocusAction=change_email_sso`,
          'postFocusUrl',
          ssoLandingPageUrl.toString(),
        ),
      );
    } else {
      if (this.rootStore.isNewLoginApp) {
        this.rootStore.userDataStore.setEmail(
          this.rootStore.ssoStore.existingWixAccountEmail,
        );
        return this.rootStore.loginStore.onNavigateToLogin();
      }
      this.rootStore.navigationStore.goToMainLogin({
        email: this.rootStore.ssoStore.existingWixAccountEmail,
      });
    }
    return; // got to account settings
  }
}
