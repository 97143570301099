import React, { FC, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Box, Text, TextButton } from '@wix/design-system';
import { dataHooks } from '../../../../dataHooks';
import { ApprovalChallengeStatus } from '@wix/ambassador-identity-v1-verification/types';
import { ReactComponent as ConfirmDeviceIllustration } from '../../../../assets/images/confirmDevice.svg';
import { ReactComponent as WarningIllustration } from '../../../../assets/images/warning.svg';
import { AppContextProvider } from '../../../AppLoader';
import { useTranslation } from 'react-i18next';
import { ResendVerificationCode } from '../../ResendVerificationCode';

const Pending: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: { ownerAppStore: store },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  useEffect(
    () => () => {
      store.stopPolling();
    },
    []
  );
  const { name } = store.selectedDevice!;
  return (
    <Box padding="18px 24px 0px 24px">
      <Box marginRight="24px">
        <ConfirmDeviceIllustration />
      </Box>
      <Box direction="vertical" gap="SP1" width="378px">
        <Box direction="vertical">
          <Text size="medium" weight="normal">
            {t('post.login.oa.2fa.confirm.label')}
          </Text>
        </Box>
        <Text size="medium" secondary color="dark">
          {t('post.login.oa.2fa.confirm.message', { deviceName: `${name}` })}
        </Text>
        <ResendVerificationCode
          sendConfirmationCode={() => store.sendPushToSelectedDevice(true)}
          resendSuccessfullyIndication={store.resendSuccessfullyIndication}
          resendThrottleTimerValue={store.resendThrottleTimerValue}
          resendErrorMessage={store.resendErrorMessage}
          resendButtonTranslationKey="post.login.oa.2fa.confirm.resend-link"
          resendIndicationTranslationKey="post.login.oa.2fa.code.resend.indication"
          resendButtonSize="medium"
          resendButtonWeight="thin"
        />
      </Box>
    </Box>
  );
});

const Error: FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: { ownerAppStore: store },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  return (
    <Box padding="18px 24px 0px 24px">
      <Box marginRight="24px">
        <WarningIllustration />
      </Box>
      <Box direction="vertical" gap="SP1">
        <Box direction="vertical">
          <Text size="medium" weight="normal">
            {t(
              store.authStatus === ApprovalChallengeStatus.EXPIRED
                ? 'post.login.oa.2fa.timeout.title'
                : 'post.login.oa.2fa.denied.title'
            )}
          </Text>
          <Text
            size="medium"
            weight="bold"
            dataHook={dataHooks.ownerAppModal.deviceName}
          ></Text>
        </Box>
        <Text size="medium" weight="thin">
          {t(
            store.authStatus === ApprovalChallengeStatus.EXPIRED
              ? 'post.login.oa.2fa.timeout.message'
              : 'post.login.oa.2fa.denied.message'
          )}
        </Text>
        <TextButton
          size="medium"
          weight="thin"
          onClick={() => store.sendPushToSelectedDevice(true)}
        >
          {t('post.login.oa.2fa.timeout.resend-link')}
        </TextButton>
      </Box>
    </Box>
  );
});

export const ConfirmDeviceStep = { Pending, Error };
