import React, { useContext, useEffect } from 'react';
import { AppContextProvider } from '../AppLoader';
import { Box, Cell, Layout } from '@wix/design-system';
import { dataHooks } from '../../dataHooks';
import { DialogFormContainer } from '../DialogContainer';
import { keys } from '../../translationsKeys';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  ThemedHeading,
  ThemedText,
  ThemedTextButton,
} from '../ThemedComponents';
import s from './DisconnectSuccessful.scss';

interface DisconnectSuccessfulProps {}

export const DisconnectSuccessful: React.FC<DisconnectSuccessfulProps> =
  observer(() => {
    const {
      rootStore: { 
        deviceLoginStore,
        displayStore: { isMobile },
      },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();

    useEffect(() => {
      const timeout = setTimeout(() => {
        deviceLoginStore.redirectToWix();
      }, 15000);

      return () => clearTimeout(timeout);
    }, []);
    return (
      <Box direction="vertical" align="center" width="100%">
        <DialogFormContainer
          dataHook={dataHooks.deviceLogin.container}
          formClasses={[s.disconnectSuccessfulForm]}
          mainHeaderProps={{showBackButton: false}}
        >
          <Box marginBottom={3}>
            <ThemedHeading
              appearance={isMobile ? "H2" : "H1"}
              dataHook={dataHooks.disconnectSuccessful.title}
            >
              {t('device.disconnect.successful.title')}
            </ThemedHeading>
          </Box>
          <Box
            marginBottom={3}
            className={s.mobileDeviceView}
            direction="vertical"
          >
            <ThemedText size="small">
              {t(keys['device.disconnect.successful.content.first'])}
            </ThemedText>
            <ThemedText size="small">
              {t(keys['device.disconnect.successful.content.second'])}
            </ThemedText>
          </Box>
          <Box
            marginBottom={3}
            className={s.mobileDeviceView}
            direction="vertical"
          >
            <Layout gap={0}>
              <Cell span={12} rows={2}>
                <ThemedText size="small">
                  {t(keys['device.login.text.customer.care'])}{' '}
                  <ThemedTextButton
                    underline="always"
                    dataHook={dataHooks.disconnectSuccessful.customerCareLink}
                    skin="standard"
                    as="a"
                    target="_blank"
                    href={t('device.login.link.customer.care')}
                    size="small"
                  >
                    {t(keys['device.login.text.link.customer.care'])}
                  </ThemedTextButton>{' '}
                </ThemedText>
              </Cell>
            </Layout>
          </Box>
        </DialogFormContainer>
      </Box>
    );
  }
);
