import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CustomModalLayout,
  Input,
  InputWithOptions,
  RadioGroup,
  Stepper,
  Text,
} from '@wix/design-system';

import s from '../modals.scss';
import { UserChoice } from '../../../../stores/premiumUsers2FaEnforcement/sms/addPhone';
import { AppContextProvider } from '../../../AppLoader';
import { UiStore } from './AddPhoneUiStore';

export const AddPhoneStep: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { add2FAPhoneStore: store },
      },
    },
  } = useContext(AppContextProvider);
  const uiStore = new UiStore(store);
  const ownerAppStore = store.premiumUsers2FaEnforcementStore.ownerAppStore;
  const { t } = useTranslation();
  const content = (
    <div className={s.addPhoneContainer}>
      {uiStore.isBackupMethod && (
        <div className={s.stepper}>
          <Stepper
            type="text"
            activeStep={ownerAppStore.activeStep}
            steps={ownerAppStore.steps}
          />
        </div>
      )}
      <Box marginBottom={4}>
        <Text>
          {t(
            store.isPrimaryExist
              ? 'add_phone_wizard.new_style.choose_phone'
              : 'add_phone_wizard.new_style.new_phone'
          )}
        </Text>
      </Box>
      {store.isPrimaryExist ? (
        <ExistingOrAddingNumber />
      ) : (
        <AddingOrEditingFields />
      )}
    </div>
  );
  return (
    <CustomModalLayout
      title={uiStore.title}
      subtitle={uiStore.subtitle}
      primaryButtonText={uiStore.primaryButtonText}
      primaryButtonOnClick={uiStore.primaryButtonOnClick}
      sideActions={uiStore.sideActions}
      className={s.enforcementSmsModal}
      removeContentPadding
      primaryButtonProps={{
        disabled: store.shouldDisableSubmit,
      }}
      showHeaderDivider
    >
      {content}
    </CustomModalLayout>
  );
});

const ExistingOrAddingNumber: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { add2FAPhoneStore: store },
      },
    },
  } = useContext(AppContextProvider);
  const { internationalNumber } = store.getPrimary() || {};
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <RadioGroup
        value={store.userChoice}
        onChange={(value: string | number | undefined) => {
          if (typeof value === 'string' || typeof value === 'number') {
            store.userChoice = value as UserChoice;
          }
        }}
      >
        <RadioGroup.Radio value={UserChoice.PrimaryNumber}>
          <Text weight="normal">{internationalNumber}</Text>
          <Text>{t('add_phone_wizard.add_phone.2fa.radio.existing')}</Text>
        </RadioGroup.Radio>
        <RadioGroup.Radio value={UserChoice.NewNumber}>
          {store.userChoice === UserChoice.NewNumber
            ? t('add_phone_wizard.add_phone.2fa.radio.new.selected')
            : t('add_phone_wizard.add_phone.2fa.radio.new')}
        </RadioGroup.Radio>
      </RadioGroup>
      {store.userChoice === 1 && (
        <Box marginTop={2}>
          <AddingOrEditingFields />
        </Box>
      )}
    </React.Fragment>
  );
});

const AddingOrEditingFields: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { add2FAPhoneStore: store },
      },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  return (
    <Box gap={2}>
      <InputWithOptions
        selectedId={store.countryCode?.id}
        value={store.countryCode?.id}
        options={store.countryCodesOptions}
        onSelect={store.onSelectCountryCode}
        onChange={(e) => store.onSearchCountries(e.target.value)}
        prefix={store.countryCode?.icon}
        className={s.countriesDropdown}
        popoverProps={{ appendTo: 'window', zIndex: '9999' } as any}
      />
      <Input
        className={s.addPhoneStepInput}
        autocomplete="new-password"
        autoFocus
        placeholder={t('add_phone_wizard.add_phone.placeholder')}
        value={store.phoneNumber.value}
        onChange={store.phoneNumber.onChange}
        onBlur={store.phoneNumber.onBlur}
        status={
          !store.phoneNumber.isValid && store.phoneNumber.isDirty
            ? Input.StatusError
            : undefined
        }
        onEnterPressed={() => store.onSendVerificationSms()}
        statusMessage={store.phoneNumber.errorInfo}
      />
    </Box>
  );
});
