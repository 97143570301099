import React, { useContext, useEffect } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { AccountSelector } from '@wix/identity-fed-common';
import { dataHooks } from '../../dataHooks';
import { AppContextProvider } from '../AppLoader';
import { DialogContainer } from '../DialogContainer';
import { theme } from '@wix/design-system/dist/cjs/Themes/brand';
import { Recaptcha } from '../Recaptcha/Recaptcha';
import s from './SelectAccount.scss';
import { Box } from '@wix/design-system';

interface SelectAccountProps extends WithTranslation {}

const SelectAccount: React.FC<SelectAccountProps> = observer(() => {
  const {
    rootStore: { selectAccountStore, i18n, displayStore },
  } = useContext(AppContextProvider);

  useEffect(() => {
    selectAccountStore.initialFetch();
  }, []);
  return (
    <Box direction="vertical" width="100%" align="center">
    <DialogContainer
      dataHook={dataHooks.selectAccountDialog.container}
      contentClasses={[s.selectAccountDialog]}
      mainHeaderProps={{ backButtonCallback: selectAccountStore.onBackClicked }}
    >
      {selectAccountStore.showAccountSelector && (
        <AccountSelector
          email={selectAccountStore.email}
          locale={i18n.language}
          onAccountActionClicked={(account) =>
            selectAccountStore.onAccountSelected(account)
          }
          isMobile={displayStore.isMobile}
          onLoaded={(accountData) =>
            selectAccountStore.onAccountSelectorLoaded(accountData)
          }
          userAccounts={selectAccountStore.userAccountsDataByEmail}
          wsrProviderProps={{ theme: theme() }}
        />
      )}
      <Recaptcha />
    </DialogContainer>
    </Box>
  );
});

export default withTranslation()(SelectAccount);
