type Skin = 'light' | 'dark' | 'standard'; // 'light' should be used on dark backgrounds
export type LogoPosition = 'left' | 'center';
type AuthDialog = {
  titleKey?: string;
  subtitleKey?: string;
};

export const SocialProviders = {
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  APPLE: 'apple',
};

export const PRESETNS = {
  DAYFUL: 'dayful',
  EDITORX: 'editorx',
  HOPP: 'hopp',
  EDUCATION_STUDENT: 'EDUCATION_STUDENT',
  EDUCATION_TEACHER: 'EDUCATION_TEACHER',
  CODUX: 'codux', // New branding for WixComponentsStudio (WCS)
  LOGO_MAKER: 'logo_maker',
  BUSINESS_CARD_MAKER: 'bcm',
};

export type ViewPreset = Partial<{
  nameKey: string; // Default - 'presets.names.wix' = Wix
  skin: Skin; // Default - standard
  backgroundColor: string; // Default - none, natively supported value (Hex, RGB, etc.)
  login: AuthDialog;
  signup: AuthDialog;
  landingPage: AuthDialog; // Main landing page (email step)
  logo: Partial<{
    // Default - wix logo
    src: string;
    position: LogoPosition; // Default - 'left'
    height: string; // Default - 24px
    mobile: {
      height: string; // Default - 20px
    };
    alt: string; // Default - 'Wix'
    url: string; // Default - www.wix.com
  }>;
  policies: {
    termsOfUseUrl?: string; // Default - wix terms of use
    privacyPolicyUrl?: string; // Default - wix privacy policy
  };
  // Social providers separated with comma (for example - 'google,facebook')
  // Default - all ('google,facebook','apple')
  socialProviders: string;
}>;

export const skinToColor = {};
export const DEFAULT_LOGO_HEIGHT = '26px';

export const ViewPresets: {
  [key: string]: ViewPreset;
} = {
  [PRESETNS.DAYFUL]: {
    nameKey: 'presets.names.dayful',
    landingPage: {
      titleKey: 'emailStep.dayful.title',
    },
    signup: {
      titleKey: 'signup.title',
      subtitleKey: 'dayful_subtitle',
    },
    logo: {
      src: 'https://wixmp-7ef3383b5fd80a9f5a5cc686.wixmp.com/logos/dayful-logo.png',
      height: '34px',
      url: 'https://www.dayful.com/',
    },
  },
  [PRESETNS.HOPP]: {
    nameKey: 'presets.names.hopp',
    landingPage: {
      titleKey: 'emailStep.hopp.title',
    },
    signup: {
      titleKey: 'signup.title',
      subtitleKey: 'hopp_subtitle',
    },
    logo: {
      src: 'https://wixmp-7ef3383b5fd80a9f5a5cc686.wixmp.com/Hopp%20by%20wix%20logo%20(1).svg',
      height: '22px',
      url: 'https://www.hopp.co/',
    },
  },
  [PRESETNS.EDUCATION_STUDENT]: {
    nameKey: 'presets.names.education',
    landingPage: {
      titleKey: 'emailStep.education.student.title',
    },
    login: {
      subtitleKey: 'login.education.subtitle',
    },
    signup: {
      titleKey: 'signup.education.student.title',
    },
    logo: {
      src: 'https://images-wixmp-7ef3383b5fd80a9f5a5cc686.wixmp.com/642436ff-70f5-4ebc-8fea-47fab46f7f73/1693247680916',
      url: 'https://www.wix.com/education',
    },
    policies: {
      termsOfUseUrl:
        'https://www.wix.com/tomorrow/classroom/platform/terms-of-use',
      privacyPolicyUrl:
        'https://www.wix.com/tomorrow/classroom/platform/privacy-policy',
    },
    socialProviders: SocialProviders.GOOGLE,
  },
  [PRESETNS.EDUCATION_TEACHER]: {
    nameKey: 'presets.names.education',
    landingPage: {
      titleKey: 'emailStep.education.teacher.title',
    },
    login: {
      subtitleKey: 'login.education.subtitle',
    },
    signup: {
      titleKey: 'signup.education.teacher.title',
    },
    logo: {
      src: 'https://images-wixmp-7ef3383b5fd80a9f5a5cc686.wixmp.com/642436ff-70f5-4ebc-8fea-47fab46f7f73/1693247680916',
      url: 'https://www.wix.com/education',
    },
    policies: {
      termsOfUseUrl:
        'https://www.wix.com/tomorrow/classroom/platform/terms-of-use',
      privacyPolicyUrl:
        'https://www.wix.com/tomorrow/classroom/platform/privacy-policy',
    },
    socialProviders: SocialProviders.GOOGLE,
  },
  [PRESETNS.CODUX]: {
    logo: {
      src: 'https://wixplosives.github.io/codux-assets-storage/login/codux-logo.svg',
      position: 'center',
      height: '100px',
      mobile: {
        height: '80px',
      },
      url: 'https://www.codux.com/',
    },
    signup: {
      titleKey: 'signup.title',
    },
    policies: {
      termsOfUseUrl: 'https://www.wix.com/about/terms-of-use',
      privacyPolicyUrl: 'https://www.wix.com/about/privacy',
    },
  },
  [PRESETNS.EDITORX]: {
    nameKey: 'presets.names.editorx',
    landingPage: {
      titleKey: 'emailStep.editorx.title',
    },
    signup: {
      titleKey: 'signup.title',
    },
    logo: {
      src: 'https://wixmp-7ef3383b5fd80a9f5a5cc686.wixmp.com/logos/EditorX_logo_36x168_black.svg',
      url: 'https://www.editorx.com/',
    },
  },
  [PRESETNS.LOGO_MAKER]: {
    nameKey: 'presets.names.logo_maker',
    logo: {
      url: 'https://www.wix.com/logo/maker/',
    },
    landingPage: {
      titleKey: 'emailStep.logo_maker.title',
    },
    login: {
      titleKey: 'login.logo_maker.title',
    },
    signup: {
      titleKey: 'signup.logo_maker.title',
    },
  },
  [PRESETNS.BUSINESS_CARD_MAKER]: {
    nameKey: 'presets.names.business_card_maker',
    logo: {
      url: 'https://www.wix.com/tools/business-card-maker/',
    },
    login: {
      titleKey: 'login.business_card_maker.title',
    },
    signup: {
      titleKey: 'signup.business_card_maker.title',
    },
  },
};
