import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { dataHooks } from '../../dataHooks';
import { Box } from '@wix/design-system';
import { PasswordFieldWithLabel } from '../PasswordField/PasswordField';
import { EnterPasswordStore } from '../../stores/enterPassword';
import { ThemedTextButton } from '../ThemedComponents';
import { DialogFormContainer } from '../DialogContainer';
import { ButtonWithLoader } from '../ButtonWithLoader/ButtonWithLoader';
import { ACCOUNT_RECOVERY_URL } from '../../utils/constants';
import { AppContextProvider } from '../AppLoader';
import s from './EnterPassword.scss';

interface EnterPasswordProps {
  buttonContent?: string;
  dataHook?: string;
}

export const EnterPasswordContextProvider = createContext<{
  store?: EnterPasswordStore;
}>({});

export const EnterPassword: React.FC<EnterPasswordProps> = observer(
  ({
    children,
    buttonContent,
    dataHook = dataHooks.enterPassword.container,
  }) => {
    const { t } = useTranslation();
    const { store } = useContext(EnterPasswordContextProvider);
    const {
      rootStore: {
        displayStore: { isMobile },
      },
    } = useContext(AppContextProvider);
    return (
      <DialogFormContainer
        dataHook={dataHook}
        formClasses={[s.enterPasswordContainer]}
      >
        {children}
        <Box direction="vertical" width="100%" maxWidth={isMobile ? '300px' : '320px'}>
          <PasswordFieldWithLabel
            required
            formField={store?.passwordField}
            label={t('account_verification.placeholder')}
            dataHook={dataHooks.enterPassword.password}
            autocomplete="current-password"
            showVisibility={!!store?.passwordField.value?.length}
            value={store?.passwordField.value}
            onEnterPressed={() => store?.isSubmittable() && store?.submit()}
          />
          <Box direction="vertical" gap={3} align="center" >
            <ThemedTextButton
              underline="always"
              size="small"
              as="a"
              href={ACCOUNT_RECOVERY_URL}
              target="_blank"
            >
              {t('forgot_password.link')}
            </ThemedTextButton>
            <ButtonWithLoader
              disabled={!store?.isSubmittable()}
              onClick={() => store?.submit()}
              dataHook={dataHooks.enterPassword.submitButton}
              skin='standard'
              type="submit"
            >
              {buttonContent ?? t('account_verification.next')}
            </ButtonWithLoader>
          </Box>
        </Box>
      </DialogFormContainer>
    );
  },
);
