import { RootStore } from './root';

export class TagManagerStore {
  private readonly rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    window.dataLayer = window.dataLayer ?? [];

    this.log('gtm.js', { 'gtm.start': new Date().getTime() });
  }

  log(event: string, data?: Record<string, any>) {
    window.dataLayer?.push({ event, ...data });
  }
}
