import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import EnterCode from '../EnterCode';
import { Box } from '@wix/design-system';
import { AppContextProvider } from '../AppLoader';
import { EnterCodeStore, TWO_FA_METHODS_MAP } from '../../stores/enterCode';
import { accountRecoveryByPhoneVerifyCodePageLoad } from '@wix/bi-logger-hls2/v2';
import { TwoFactorAuthMethods } from '../../utils/constants';
import { EnterCodeContextProvider } from '../EnterCode/EnterCode';

const AccountRecoveryCodeDialog: React.FC = observer(() => {
  const {
    rootStore,
    rootStore: { accountRecoveryCodeStore, biLogger },
  } = useContext(AppContextProvider);
  const [enterCodeStore] = useState(new EnterCodeStore(rootStore));
  useEffect(() => {
    biLogger.report(accountRecoveryByPhoneVerifyCodePageLoad({}));
  });
  return (
    <EnterCodeContextProvider.Provider
      value={{ store: accountRecoveryCodeStore, enterCodeStore }}
    >
    <Box direction="vertical" align="center" width="100%">
      <EnterCode
        store={accountRecoveryCodeStore}
        dialogTitleKey="enter_code.title"
        descriptionKey={`enter_code.${
          TWO_FA_METHODS_MAP[TwoFactorAuthMethods.Phone]
        }.description`}
      >
        <EnterCode.ButtonsSection>
          <EnterCode.ButtonsSection.ResendButton />
          <EnterCode.ButtonsSection.VerifyButton />
        </EnterCode.ButtonsSection>
        <EnterCode.LinksSection>
          <EnterCode.LostAccess
            onClick={accountRecoveryCodeStore.onLostAccess}
          />
        </EnterCode.LinksSection>
      </EnterCode>
    </Box>
    </EnterCodeContextProvider.Provider>
  );
});

export default AccountRecoveryCodeDialog;
