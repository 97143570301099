import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { ApprovalChallengeStatus } from '@wix/ambassador-identity-v1-verification/types';
import { OwnerApp2FAPending } from '../OwnerApp2FAPending/OwnerApp2FAPending';
import { OwnerApp2FAExpired } from '../OwnerApp2FAExpired/OwnerApp2FAExpired';
import { OwnerApp2FADeclined } from '../OwnerApp2FADeclined/OwnerApp2FADeclined';
import { useTwoFactorAuth } from '../../stores/twoFactorAuth';

export const OwnerApp2FAMethod: FC = observer(() => {
  const { store } = useTwoFactorAuth();

  switch (store.twoFactorAuthStatus) {
    case ApprovalChallengeStatus.PENDING_APPROVAL:
      return <OwnerApp2FAPending />;
    case ApprovalChallengeStatus.EXPIRED:
      return <OwnerApp2FAExpired />;
    case ApprovalChallengeStatus.DECLINED:
      return <OwnerApp2FADeclined />;
    default:
      return null;
  }
});
