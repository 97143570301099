import {
  app2FaSetupClickOnBack,
  email2FaSetupCodeErrorMessage,
  email2FaSetupEnterCode,
  email2FaSetupSendCodeFail,
  email2FaSetupSendCodeSuccess,
} from '@wix/bi-logger-dash/v2';
import { action, computed, makeObservable, observable } from 'mobx';
import { PremiumUsers2FaEnforcementStore } from '../premiumUsers2FaEnforcement';
import { VerifyCode } from '../verifyCode';
import { FormField } from '../../formField';
import { extractErrorCode } from '../utils';
import { constraints } from '../../../utils/validators';
import { BI_ORIGIN } from '../../constants';
import { MultiFactorIdentifier } from '../multiFactorAuth';
import { VerificationState } from '../../../components/PostLogin/VerifyButton';

export class ConfirmEmailStore extends VerifyCode {
  public isModalConfirmationCodeOpen: boolean = false;
  public isConfirmationSent: boolean = false;
  public isSubmitted: boolean = false;
  public code: FormField;
  public verificationId: string;
  public verificationState: VerificationState = VerificationState.Unverified;
  public origin: string;

  constructor(
    public premiumUsers2FaEnforcementStore: PremiumUsers2FaEnforcementStore
  ) {
    super(premiumUsers2FaEnforcementStore);
    this.code = new FormField(undefined, [
      [
        constraints.minimum(6),
        this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
          'confirmEmailModalSecondStep.confirmationCode.errorInfo'
        ),
      ],
      [
        constraints.maximum(6),
        this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
          'confirmEmailModalSecondStep.confirmationCode.errorInfo'
        ),
      ],
    ]);
    this.origin = this.premiumUsers2FaEnforcementStore.ownerAppStore
      .waitingForBackupMethod
      ? BI_ORIGIN.TWO_FA_ENFORCEMENT_SECOND_METHOD
      : BI_ORIGIN.TWO_FA_ENFORCEMENT;
    makeObservable(this, {
      isModalConfirmationCodeOpen: observable,
      isConfirmationSent: observable,
      titleFirstStep: computed,
      subtitleFirstStep: computed,
      titleSecondStep: computed,
      firstStepButtonConfirmText: computed,
      isSubmitted: observable,
      code: observable,
      onOpenModal: action,
      onCloseModal: action,
      onCloseSecondStepModal: action,
      onFirstStepConfirm: action.bound,
      verifyConfirmationCode: action,
      sendConfirmationCode: action.bound,
      verificationId: observable,
      onVerifyCode: action,
      verificationState: observable,
      onBackToConfirmEmail: action.bound,
      origin: observable,
    });
  }

  onBackToConfirmEmail() {
    this.premiumUsers2FaEnforcementStore.sendBiEvent(
      app2FaSetupClickOnBack({
        mode: 'enable',
      })
    );
    this.isModalConfirmationCodeOpen = false;
    this.onOpenModal();
  }

  public onOpenModal() {
    this.isSubmitted = false;

    this.premiumUsers2FaEnforcementStore.postLoginStore.rootStore.navigationStore.navigate(
      this.premiumUsers2FaEnforcementStore.postLoginStore.postLoginRoutes
        .PREMIUM_USERS_2FA_EMAIL
    );
  }

  t(key: string) {
    return this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(key);
  }

  get titleFirstStep() {
    return this.t('confirmEmailBeforeRecoveryPhoneModal.title');
  }

  get subtitleFirstStep() {
    return this.t('confirmEmailBeforeRecoveryPhoneModal.subtitle');
  }

  get titleSecondStep() {
    return this.t('confirmEmailModalSecondStep.confirmationCode.title');
  }

  get firstStepButtonConfirmText() {
    return this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
      'confirmEmailModalFirstStep.confirmationCode.confirm'
    );
  }

  public onCloseModal() {
    this.isConfirmationSent = false;
  }

  public onCloseSecondStepModal() {
    this.verificationState = VerificationState.Verified;
    this.isModalConfirmationCodeOpen = false;
    this.premiumUsers2FaEnforcementStore.goToOwnerAppOrDashboard(
      'email 2fa completed'
    );
  }

  public onFirstStepConfirm() {
    if (this.isConfirmationSent) {
      return this.onCloseModal();
    }
    return this.sendConfirmationCode();
  }

  public async sendConfirmationCode() {
    try {
      this.isSubmitted = true;

      const response =
        await this.premiumUsers2FaEnforcementStore.postLoginStore.accountSettingsApi.sendVerificationCode(
          {
            email: this.email,
            incognito: true,
            isActionTagRequired: false,
          }
        );

      this.verificationId = response?.data?.verificationId;
      this.premiumUsers2FaEnforcementStore.sendBiEvent(
        email2FaSetupSendCodeSuccess({
          action: 'send',
          origin: this.origin,
        })
      );
      this.onCloseModal();
      if (this.isModalConfirmationCodeOpen) {
        this.resendSuccessfullyIndication = true;
      } else {
        this.isModalConfirmationCodeOpen = true;
        this.premiumUsers2FaEnforcementStore.postLoginStore.rootStore.navigationStore.navigate(
          this.premiumUsers2FaEnforcementStore.postLoginStore.postLoginRoutes
            .PREMIUM_USERS_ENTER_EMAIL_CODE
        );
      }
    } catch (error) {
      this.isSubmitted = false;
      this.premiumUsers2FaEnforcementStore.sendBiEvent(
        email2FaSetupSendCodeFail({
          action: 'send',
          origin: this.origin,
        })
      );
    }
  }
  public async verifyConfirmationCode(): Promise<{
    success: boolean;
    errorCode?: any;
    errorReason?: any;
  }> {
    const handleError = (response: any) => {
      const errorCode = extractErrorCode(response);
      const errorReason =
        this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
          this.premiumUsers2FaEnforcementStore.postLoginStore.accountSettingsApi.errorHandling(
            errorCode,
            'confirmEmailModalSecondStep.confirmationCode.error.badCode'
          )
        );

      this.code.addError(errorReason);
      return { success: false, errorCode, errorReason };
    };
    this.verificationState = VerificationState.Verifying;
    try {
      this.premiumUsers2FaEnforcementStore.sendBiEvent(
        email2FaSetupEnterCode({
          origin: this.origin,
          is_valid: true,
        })
      );
      const response = await this.onVerifyCode?.(this.email, this.code.value);
      if (!response?.success) {
        this.verificationState = VerificationState.Unverified;
        return handleError(response);
      }

      await this.premiumUsers2FaEnforcementStore.postLoginStore.fetchUserDetails();
    } catch (error) {
      this.verificationState = VerificationState.Unverified;
      return handleError(error);
    }
    this.onCloseSecondStepModal();
    return { success: true };
  }

  public async onVerifyCode(email: string, code: string) {
    try {
      const result =
        await this.premiumUsers2FaEnforcementStore.postLoginStore.accountSettingsApi[
          MultiFactorIdentifier.Email
        ].enable(email, code, this.verificationId!);
      if (result.success) {
        await this.premiumUsers2FaEnforcementStore.multiFactorAuth.handle2faSettingsUpdate();
      } else {
        this.premiumUsers2FaEnforcementStore.sendBiEvent(
          email2FaSetupCodeErrorMessage({
            origin: this.origin,
            error_code: result.errorCode,
            error_reason: result.errorReason,
          })
        );
      }
      return result;
    } catch (error: any) {
      this.premiumUsers2FaEnforcementStore.sendBiEvent(
        email2FaSetupCodeErrorMessage({
          origin: this.origin,
          error_code: error.errorCode,
          error_reason: error.errorReason,
        })
      );
    }
  }
  get email(): string {
    return this.premiumUsers2FaEnforcementStore.loginInfoStore.email.value;
  }
}
