import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import EnterCode from '../EnterCode';
import { Box } from '@wix/design-system';
import { AppContextProvider } from '../AppLoader';
import { EnterCodeStore, TWO_FA_METHODS_MAP } from '../../stores/enterCode';
import { EnterCodeContextProvider } from '../EnterCode/EnterCode';

const StepUpDialog: React.FC = observer(() => {
  const { rootStore } = useContext(AppContextProvider);
  const { stepUpAuthStore } = rootStore;
  const [enterCodeStore] = useState(new EnterCodeStore(rootStore));
  return (
    <EnterCodeContextProvider.Provider
      value={{ store: stepUpAuthStore, enterCodeStore }}
    >
      <Box direction="vertical" align="center" width="100%">
        <EnterCode
          store={stepUpAuthStore}
          dialogTitleKey="enter_code.title"
          descriptionKey={`enter_code.${
            TWO_FA_METHODS_MAP[stepUpAuthStore.currentTwoFAMethod]
          }.description`}
          showBackButton={false}
        >
          <EnterCode.ButtonsSection>
            <EnterCode.ButtonsSection.ResendButton />
            <EnterCode.ButtonsSection.VerifyButton />
          </EnterCode.ButtonsSection>
        </EnterCode>
      </Box>
    </EnterCodeContextProvider.Provider>
  );
});

export default StepUpDialog;
