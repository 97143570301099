import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { Box, CloseButton, TextButton } from '@wix/design-system';
import { ArrowLeft } from '@wix/wix-ui-icons-common';
import classNames from 'classnames';
import { AppContextProvider } from '../AppLoader';
import s from './ModalHeader.scss';
import { dataHooks } from '../../dataHooks';
import { useTranslation } from 'react-i18next';
import { AppViewMode } from '../../stores/display';

export interface ModalHeaderProps {
  xButtonClicked?: () => void;
  showBackButton?: boolean;
  backButtonCallback?: Function;
}

export const ModalHeader: React.FC<ModalHeaderProps> = observer(
  ({ xButtonClicked, showBackButton = true, backButtonCallback }) => {
    const {
      rootStore: {
        modalModeHandlerStore,
        displayStore: { isMobile, viewMode },
        navigationStore,
      },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();
    const showBack = showBackButton && !isMobile;
    const showCloseButton = viewMode === AppViewMode.MODAL;
    const isInIframe = window.self !== window.top;
    return (
      <Box
        className={classNames(s.modalHeader, [s.notIframe] && !isInIframe)}
        dataHook={dataHooks.modalHeader.container}
        align={showBack ? 'space-between' : 'right'}
      >
        {showBack && (
          <TextButton
            dataHook={dataHooks.app.backBtn}
            prefixIcon={<ArrowLeft />}
            size="medium"
            className={classNames(s.backButton, s.a11yFocus)}
            weight="normal"
            skin="dark"
            underline="none"
            onClick={() => {
              backButtonCallback
                ? backButtonCallback()
                : navigationStore.goBack();
            }}
          >
            {t('dialogHeader.backButton.text')}
          </TextButton>
        )}
        {showCloseButton && (
          <CloseButton
            size="large"
            skin="dark"
            onClick={() =>
              xButtonClicked
                ? xButtonClicked()
                : modalModeHandlerStore.onCloseModal()
            }
          />
        )}
      </Box>
    );
  },
);
