import React, { useContext } from 'react';
import classNames from 'classnames';
import { Box, BoxProps } from '@wix/design-system';
import { MainHeader, MainHeaderProps } from '../MainHeader';
import s from './DialogContainer.scss';
import { observer } from 'mobx-react';
import { AppContextProvider } from '../AppLoader';
import { ModalHeader } from '../ModalHeader';

export interface DialogContainerProps extends BoxProps {
  mainHeaderProps?: MainHeaderProps; // default is empty object
  showMainHeader?: boolean; // default is true
  dataHook?: string;
  contentClasses?: any[];
}

export const DialogContainer: React.FC<DialogContainerProps> = observer(
  ({
    children,
    mainHeaderProps,
    showMainHeader = true,
    dataHook,
    className = [],
    contentClasses = [],
    ...props
  }) => {
    const {
      rootStore: { displayStore },
    } = useContext(AppContextProvider);
    const isLargeLogo = parseInt(displayStore.preset.logo?.height ?? '0', 10) > 48;
    return (
      <>
       {showMainHeader && displayStore.showMainHeader && (
        <MainHeader {...mainHeaderProps} />
        )}
      <Box
        direction="vertical"
        align="center"
        {...props}
        className={classNames(
          [
            displayStore.isMobile ? s.mobileContainer : s.desktopContainer,
            { [s.largeLogo]: isLargeLogo },
            s[displayStore.dialogContainerClass],
            className
          ].flat()
        )}
        dataHook={dataHook}
      >
        {displayStore.showModalHeader && (
          <ModalHeader showBackButton={mainHeaderProps?.showBackButton} />
        )}
        <div className={classNames(...contentClasses, s.dialogEnter)}>
          {children}
        </div>
      </Box>
      </>
    );
  },
);

export const DialogFormContainer: React.FC<
  DialogContainerProps & { formClasses?: string[] }
> = ({ children, formClasses = [], ...props }) => {
  return (
    <DialogContainer
      {...props}
      contentClasses={[classNames(s.form, ...formClasses)]}
    >
      {children}
    </DialogContainer>
  );
};
