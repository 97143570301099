import { AccountsResponse } from '@wix/ambassador-identity-account-v2-account/build/cjs/types.impl';
import { AccountData } from '@wix/identity-fed-common';
import { action, computed, makeObservable, observable } from 'mobx';
import { ROUTES } from '../routes';
import { RootStore } from './root';
import { workspaceSelectorUserClickedBack } from '@wix/bi-logger-login-react-app/v2';

export class SelectWorkspaceStore {
  private readonly rootStore: RootStore;
  public showError: boolean = false;
  public filter: string = '';
  public userAccounts?: AccountsResponse;
  private urlParams: URLSearchParams = new URLSearchParams(window.location.search)
  private isDebug: boolean = this.urlParams.get('debugCase') === 'workspace';
  
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      showError: observable,
      userAccounts: observable,
      email: computed,
      onErrorOccured: action,
      resetError: action,
      onBackClicked: action.bound,
    });
  }

  get email() {
    return this.rootStore.userDataStore.currentLoggedInEmail || (this.isDebug && this.urlParams.get('email'));
  }

  public onErrorOccured = () => {
    this.showError = true;
  };

  public resetError = () => {
    this.showError = false;
  };

  public onAccountSelectorLoaded = (accountsData: AccountData[]) => {
    // log bi event
  };

  public enableAccess() {
    return !!this.email || this.isDebug;
  }

  public onBackClicked() {
    this.reportWorkspaceSelectorUserClickedBack();
    this.rootStore.navigationStore.goBack();
  }

  get isSelectWorkspaceVisible() {
    return (
      this.rootStore.navigationStore.currentRoute === ROUTES.SELECT_WORKSPACE
    );
  }

  private reportWorkspaceSelectorUserClickedBack = () => {
    this.rootStore.biLogger.report(workspaceSelectorUserClickedBack({}));
  };
}
