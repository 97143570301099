import { getRegistrations } from '@wix/ambassador-ping-push-v1-notification/http';
import {
  Registration,
  RegistrationStatus,
} from '@wix/ambassador-ping-push-v1-notification/types';
import type { IHttpClient } from '@wix/http-client';

export interface Device {
  id: string;
  name: string;
}

export class DeviceRegistry {
  constructor(private httpClient: IHttpClient) {}

  async fetchUserDevices(userId: string): Promise<Device[]> {
    const response: any = await this.httpClient.request(
      getRegistrations({
        userId,
        apps: ['WixAdmin'],
      }),
    );
    const { registrations } = response.data;

    return registrations ? registrationsToDevices(registrations) : [];
  }
}

// Approximately 30 days given leaps and daylight savings.
const TOO_OLD = 30 * 24 * 60 * 60 * 1000;

export function registrationsToDevices(registrations: Registration[]) {
  return registrations
    ?.filter(isDeviceValid)
    .map((registration) => registrationToDevice(registration.metadata));
}

const isDeviceValid = ({
  status,
  lastActive,
  fullToken,
  metadata,
}: Registration) => {
  const now = Date.now();
  const isSubscribed = status === RegistrationStatus.Subscribed;
  const isRecentActivity = TOO_OLD > now - (lastActive?.getTime() ?? 0);
  const hasValidToken = !!fullToken;
  const hasDeviceInfo = metadata?.deviceModelName && metadata?.deviceBrandName;
  const hasInstallationId = !!metadata?.installationId;

  return (
    isSubscribed &&
    isRecentActivity &&
    hasValidToken &&
    hasDeviceInfo &&
    hasInstallationId
  );
};

function registrationToDevice(metadata: any): Device {
  const name = [metadata?.deviceBrandName, metadata?.deviceModelName]
    .filter(Boolean)
    .join(' ');
  return {
    id: metadata?.installationId,
    name: name || 'Unknown',
  };
}
