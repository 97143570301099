import { action, makeObservable, observable, reaction } from 'mobx';
import { RootStore } from './root';
import { SocialProviderLoginStore } from './socialProviderLogin';
import logo from '../../images/facebook-logo.svg';

interface FacebookService {
  init: Function;
  XFBML: { parse: () => void };
  getLoginStatus: (callback: Function, status?: boolean) => void;
  login: (callback: Function, options: { scope: string }) => void;
  Event: any;
}

export const FACEBOOK_CONTAINER_CLASS = 'fb-login-button';
const FB_LOGIN_INTERACTION = 'facebook-login'

export class FacebookLoginStore extends SocialProviderLoginStore<LoginToWixFacebookParams> {
  private facebookService: FacebookService;
  providerId: string;
  isRendered: boolean = false;

  constructor(rootStore: RootStore) {
    super(rootStore);
    this.useNewSdk = this.rootStore.experiments?.enabled(
      'specs.ident.UseNewFacebookSdkReactLoginApp',
    );
    this.providerId = 'facebook';
    this.hasError = this.rootStore.experiments?.enabled(
      'specs.ident.ShowFacebookErrorMsg',
    );
    // this.newCustomButtonLogoUrl =
    //   'https://wixmp-7ef3383b5fd80a9f5a5cc686.wixmp.com/logos/facebook-logo2.svg';
    this.customButtonLogoUrl = logo;
    this.init();
    this.reactToSocialError();
    window.onFacebookLogin = this.onLogin.bind(this);
    makeObservable(this, {
      isRendered: observable,
      initializeSdk: action.bound,
      onLogin: action.bound,
      onClickCustomLoginButton: action.bound,
    });
  }
  protected reactToSocialError() {
    reaction(
      () =>
        this.rootStore.googleLoginStore.hasError ||
        this.rootStore.appleLoginStore.hasError,
      (newError) => {
        if (newError) {
          this.hasError = false;
        }
      },
    );
  }

  onClickCustomLoginButton(): void {
    this.socialButtonClicked();
    this.rootStore.fedopsLogger.interactionStarted(FB_LOGIN_INTERACTION);
    this.facebookService.login(this.onLogin, { scope: 'email' });
  }

  async init() {
    window.facebookSdkLoaded.then(() => {
      this.facebookService = window.FB;
      this.facebookService.Event.subscribe('xfbml.render', () =>
        setTimeout(() => (this.isRendered = true), 500),
      );
      this.facebookService.init({
        appId: '733768086637828',
        version: 'v15.0',
        channelUrl: '//www.wix.com/channel.html',
        xfbml: true,
        localStorage: false,
      });
      window.addEventListener(
        'message',
        (event) => {
          if (
            event.origin.includes('facebook') &&
            event.data.includes('login_button_click')
          ) {
            this.socialButtonClicked();
          }
        },
        false,
      );
      this.isInitialized = true;
    });
  }

  initializeSdk() {
    if (this.useNewSdk) {
      setTimeout(() => {
        this.isRendered = false;
        this.facebookService?.XFBML?.parse();
      }, 100);
    }
  }
  private isLoggedOutFromFacebook(): Promise<unknown> {
    const promise = new Promise((resolve) => {
      this.facebookService.getLoginStatus((response) => {
        resolve(
          !response.authResponse ||
            response.status === 'unknown' ||
            !response.authResponse.userID,
        );
      }, true);
    });
    return promise;
  }

  async onLogin({ authResponse }: { authResponse: FacebookAuthResult }) {
    this.rootStore.fedopsLogger.interactionEnded(FB_LOGIN_INTERACTION);
    const hasError = !authResponse || (await this.isLoggedOutFromFacebook());
    if (hasError) {
      this.handleError({ showErrorStatus: false });
      return;
    }
    const { userID, accessToken } = authResponse;

    if (!userID) {
      this.handleError({ showErrorStatus: false });
      return;
    }

    await this.loginToWix({
      access_token: accessToken,
      user_id: userID,
    });
  }
}
