import { makeObservable, observable, reaction } from 'mobx';
import { RESEND_INDICATION_TTL_TIMER } from '../constants';
import throttle from 'lodash/throttle';

import { PremiumUsers2FaEnforcementStore } from './premiumUsers2FaEnforcement';
import { waitsFor } from '../utils';

export class VerifyCode {
  public resendSuccessfullyIndication: boolean = false;
  public resendThrottleTimerValue: number = RESEND_INDICATION_TTL_TIMER / 1000;

  constructor(
    protected premiumUsers2FaEnforcementStore: PremiumUsers2FaEnforcementStore
  ) {
    makeObservable(this, {
      resendSuccessfullyIndication: observable,
      resendThrottleTimerValue: observable,
    });
    this.reactToResend();
  }

  proceedToPostAuthUrl(reason: string) {
    this.premiumUsers2FaEnforcementStore.postLoginStore.proceedToPostAuthUrl(
      reason
    );
  }

  private setResendThrottleTimer = throttle(async () => {
    let ticks = new Array(RESEND_INDICATION_TTL_TIMER / 1000).fill('');
    Promise.all(
      ticks.map(async (_, i) => {
        await waitsFor(i * 1000);
        this.resendThrottleTimerValue = ticks.length - i;
        if (ticks.length === i + 1) {
          await waitsFor(1000);
          this.resendSuccessfullyIndication = false;
        }
      })
    );
  }, RESEND_INDICATION_TTL_TIMER);

  private reactToResend() {
    reaction(
      () => this.resendSuccessfullyIndication,
      () => {
        if (!this.resendSuccessfullyIndication) {
          return;
        }
        return this.setResendThrottleTimer();
      }
    );
  }
}
