import React, { useContext } from 'react';
import { CustomModalLayout, Box } from '@wix/design-system';
import { observer } from 'mobx-react';
import { AppContextProvider } from '../../../../AppLoader';
import { dataHooks } from '../../../../../stores/premiumUsers2FaEnforcement/dataHooks';
import s from '../../modals.scss';
import { UiStore } from './AddOwnerAppAsAdditionalUiStore';
import { EnforcementModal } from '../../EnforcementModal';

export const AddOwnerAppAsAdditionalModal: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { ownerAppStore: store },
      },
    },
  } = useContext(AppContextProvider);
  const uiStore = new UiStore(store);

  return (
    <EnforcementModal>
      <CustomModalLayout
        title={uiStore.title}
        subtitle={uiStore.subtitle}
        primaryButtonText={uiStore.primaryButtonText}
        primaryButtonOnClick={uiStore.primaryButtonOnClick}
        secondaryButtonText={uiStore.secondaryButtonText}
        secondaryButtonOnClick={uiStore.secondaryButtonOnClick}
        sideActions={uiStore.sideActions}
        removeContentPadding
        showHeaderDivider={false}
        showFooterDivider={true}
        className={s.addOwnerAppAsAdditionalModal}
        dataHook={dataHooks.ownerAppModal.main}
        overflowY="hidden"
      >
        <Box padding="SP4">{uiStore.currentStep}</Box>
      </CustomModalLayout>
    </EnforcementModal>
  );
});
