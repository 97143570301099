import React, { ReactNode, useRef, KeyboardEvent } from 'react';
import {
  Box,
  FormField as FormFieldWSR,
  Input,
  InputSize,
} from '@wix/design-system';
import { observer } from 'mobx-react';
import { FormField } from '../../../stores/premiumUsers2FaEnforcement/utils/formField';

export interface ISuperInput {
  dataHooks: {
    formField: string;
    input: string;
  };
  label?: string | ReactNode;
  lockSuffix?: string;
  placeholder?: string;
  type?: string;
  shouldLock?: boolean;
  infoContent?: string;
  onClick?: Function;
  disabled?: boolean;
  onFocus?(e?: React.FocusEvent<HTMLInputElement>): void;
  field: FormField;
  shouldError?: boolean;
  onBlur?(event: React.FocusEvent<HTMLInputElement>): void;
  // Field override props
  value?: string;
  errorInfo?: string;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  onEnterPressed?: React.KeyboardEventHandler<HTMLInputElement>;
  onLock?(): void;
  disablePaste?: boolean;
  disableEditing?: boolean;
  autocomplete?: string;
  suffix?: React.ReactNode;
  inputRef?: React.MutableRefObject<any>;
  autoSelect?: boolean;
  autoFocus?: boolean;
  size?: InputSize;
  onlyDigits?: boolean;
  maxLength?: number;
  minLength?: number;
  readOnly?: boolean;
  lockTooltip?: string;
  showPasswordStrength?: boolean;
}

const SPECIAL_CHARACTERS = [
  'Backspace',
  'Delete',
  'ArrowLeft',
  'ArrowRight',
  'Meta',
  'Control',
];
const PRE_PASTE_COPY_KEYS = ['Meta', 'Control'];

export const SuperInput: React.FC<ISuperInput> = observer(
  ({
    dataHooks,
    label,
    placeholder,
    onFocus,
    shouldLock,
    disabled = false,
    infoContent = '',
    onClick = () => {
      return;
    },
    field,
    type = 'text',
    value,
    shouldError,
    errorInfo,
    onChange,
    onBlur,
    onEnterPressed,
    disablePaste,
    disableEditing,
    autocomplete,
    suffix,
    inputRef,
    autoSelect,
    size = 'medium' as InputSize,
    autoFocus,
    onlyDigits = false,
    maxLength,
    readOnly,
  }) => {
    const ref = inputRef ?? useRef(null);
    const unfocusIfLocked = () => {
      if (disabled) {
        return;
      }
      onClick();
      ref.current.blur();
    };
    suffix = suffix ?? '';

    value = value ?? field.value;
    shouldError = shouldError ?? (!field.isValid && field.isTouched);
    errorInfo = errorInfo ?? field.errorInfo;
    const onChangeCallback = (e: any) => {
      if (
        onlyDigits &&
        e.target.value.split('').find((c: any) => c < '0' || c > '9')
      ) {
        return e.preventDefault();
      }
      field.onChange(e);
      onChange && onChange(e);
    };
    const onBlurCallback = onBlur
      ? (e: any) => {
          field.onBlur();
          onBlur(e);
        }
      : field.onBlur;
    autocomplete = autocomplete
      ? autocomplete
      : type === 'password'
      ? 'current-password'
      : '';

    const onKeyPressOnlyDigits = (e: KeyboardEvent<HTMLInputElement>) => {
      if (
        !field.pasteCopyKeyPressed &&
        !SPECIAL_CHARACTERS.includes(e.key) &&
        (e.key < '0' || e.key > '9')
      ) {
        e.preventDefault();
      }
      field.setPasteCopyKeyPressed(PRE_PASTE_COPY_KEYS.includes(e.key));
    };

    return (
      <FormFieldWSR
        dataHook={dataHooks.formField}
        label={label}
        infoContent={infoContent}
        statusMessage={shouldError && errorInfo}
        status={shouldError ? Input.StatusError : undefined}
      >
        <Box>
          <Input
            autocomplete={autocomplete}
            autoFocus={autoFocus}
            dataHook={dataHooks.input}
            placeholder={placeholder}
            value={value}
            onChange={onChangeCallback}
            onEnterPressed={onEnterPressed}
            onBlur={shouldLock ? undefined : onBlurCallback}
            onFocus={shouldLock ? unfocusIfLocked : onFocus}
            suffix={suffix}
            ref={ref}
            type={field.inputType ?? type}
            disabled={disabled}
            onPaste={(e) => disablePaste && e.preventDefault()}
            disableEditing={disableEditing}
            autoSelect={autoSelect}
            size={size}
            onKeyDown={onlyDigits ? onKeyPressOnlyDigits : undefined}
            maxLength={maxLength}
            readOnly={readOnly}
          />
        </Box>
      </FormFieldWSR>
    );
  }
);
