import { observer } from 'mobx-react';
import React from 'react';
import { Box } from '@wix/design-system';
import { MainHeader } from '../../MainHeader';

export const EnforcementModal: React.FC = observer(({ children }) => {
  return (
    <Box direction="vertical" width="100%" align="center">
      <MainHeader showBackButton={false} disableLogoClick={true} />
      {children}
    </Box>
  );
});
