import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { DEFAULT_ROUTE } from '../../routes';

interface ProtectedRouteProps {
  condition?: boolean;
  defaultRoute?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  condition = false,
  defaultRoute = DEFAULT_ROUTE,
}) => {
  const { search } = useLocation();
  if (!condition) {
    return <Redirect to={`${defaultRoute}${search}`} />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
