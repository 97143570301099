import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@wix/design-system';
import { AppContextProvider } from '../../AppLoader';
import PostLoginModal from '../PostLoginModal';
import { ApprovalChallengeStatus } from '@wix/ambassador-identity-v1-verification/types';
import { OwnerAppStore } from '../../../stores/ownerApp';
import { ChooseDeviceStep } from './steps/SelectDeviceStep';
import { ConfirmDeviceStep } from './steps/ConfirmDeviceStep';
import { SuccessfulStep } from './steps/SuccessfulStep';
import { Step } from '../../../types';

const PostLoginOwnerApp2Fa: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    rootStore: {
      postLoginStore: { ownerAppStore },
    },
  } = useContext(AppContextProvider);

  const header = (() => {
    return {
      title: t('post.login.oa.2fa.title'),
      subtitle: t('post.login.oa.2fa.subtitle'),
    };
  })();
  const currentStep = ownerAppStore.steps[ownerAppStore.activeStep];
  return (
    <PostLoginModal
      minHeight="100px"
      removeContentPadding={true}
      width={546}
      height={currentStep.modalHeight}
      title={header.title}
      subtitle={header.subtitle}
      primaryButtonText={currentStep.primaryButtonText}
      primaryButtonAction={currentStep.primaryButtonAction}
      primaryButtonPriority={
        ownerAppStore.activeStep === Step.Confirmation ? 'secondary' : undefined
      }
      primaryButtonDisabled={
        currentStep.primaryButtonAction && !ownerAppStore.selectedDevice
      }
      secondaryButtonText={currentStep.secondaryButtonText}
      secondaryButtonAction={currentStep.secondaryButtonAction}
      footerText="post.login.oa.2fa.footer"
      footerWeight="normal"
      stepName={ownerAppStore.getActiveStepName()}
    >
      <Box>
        <CurrentStep store={ownerAppStore} />
      </Box>
    </PostLoginModal>
  );
});

const CurrentStep: React.FC<{ store: OwnerAppStore }> = observer(
  ({ store }) => {
    const { activeStep, authStatus } = store;
    switch (activeStep) {
      case Step.DeviceSelection:
        return <ChooseDeviceStep />;
      case Step.Confirmation:
        if (
          authStatus === ApprovalChallengeStatus.PENDING_APPROVAL ||
          authStatus === ApprovalChallengeStatus.APPROVED
        ) {
          return <ConfirmDeviceStep.Pending />;
        }
        return <ConfirmDeviceStep.Error />;
      case Step.Successful:
        return <SuccessfulStep />;
      default:
        return null;
    }
  }
);

export default PostLoginOwnerApp2Fa;
