import React from 'react';
import { Box } from '@wix/design-system';
import EnterCode from '../EnterCode';
import { useTwoFactorAuth } from '../../stores/twoFactorAuth';

export const MoreMethods = () => {
  const { store } = useTwoFactorAuth();

  return (
    <>
      {store.showMethodsSwitch && <EnterCode.MethodsSwitch />}
      <Box marginTop="5px">
        <EnterCode.LostAccess onClick={store.onLostAccess} />
      </Box>
    </>
  );
};
