import React from 'react';
import { Trans } from '@wix/wix-i18n-config';
import { ThemedText, ThemedTextButton } from '../components/ThemedComponents';

export const createTextWithLink = (i18nKey: string, linkUrl: string) => (
  <ThemedText>
    <Trans i18nKey={i18nKey}>
      text
      <ThemedTextButton as="a" href={linkUrl} underline="always">
        link
      </ThemedTextButton>
    </Trans>
  </ThemedText>
);
