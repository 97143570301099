import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { Box, FormField, RadioGroup, Text, Tooltip } from '@wix/design-system';
import { AppContextProvider } from '../../AppLoader';
import { useTranslation } from 'react-i18next';
import { dataHooks } from '../../../dataHooks';
import { Chat, Email, Mobile, QR, InfoCircle } from '@wix/wix-ui-icons-common';
import { MultiFactorIdentifier } from '../../../stores/premiumUsers2FaEnforcement/multiFactorAuth';

const methodToIconMap: { [key in MultiFactorIdentifier]: JSX.Element } = {
  [MultiFactorIdentifier.OwnerApp]: <Mobile />,
  [MultiFactorIdentifier.AuthApp]: <QR />,
  [MultiFactorIdentifier.Phone]: <Chat />,
  [MultiFactorIdentifier.Email]: <Email />,
};

const VerificationMethodIcon = ({
  method,
}: {
  method: MultiFactorIdentifier;
}) => {
  return methodToIconMap[method] || null;
};

export const ChooseVerificationMethodContent: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: { premiumUsers2FaEnforcementStore: store },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  return (
    <Box padding="18px 24px 0px 24px">
      <FormField
        label={
          <Box paddingBottom="6px">
            <Text weight="normal">
              {t('premium.users.2fa.enforcement.choose.label')}
            </Text>
          </Box>
        }
      >
        <Box>
          <RadioGroup
            dataHook={dataHooks.ownerAppModal.options}
            value={store.selectedVerificationMethod}
            onChange={(id?: string | number) => {
              if (typeof id === 'string') {
                store.onSelectVerificationMethod(id as MultiFactorIdentifier);
              }
            }}
          >
            {store.filteredVerificationMethods.map(([key, method]) => (
              <RadioGroup.Radio key={key} value={key}>
                <Box direction="horizontal" gap="6px">
                  <VerificationMethodIcon
                    method={key as MultiFactorIdentifier}
                  />
                  <Text weight="thin" secondary>
                    {method.name}
                  </Text>
                  {method.tooltipTextKey && (
                    <Tooltip
                      maxWidth="289px"
                      placement="top"
                      content={t(
                        'premium.users.2fa.enforcement.authenticator.app.tooltip'
                      )}
                    >
                      <InfoCircle style={{ color: '#116dff' }} />
                    </Tooltip>
                  )}
                </Box>
              </RadioGroup.Radio>
            ))}
          </RadioGroup>
        </Box>
      </FormField>
    </Box>
  );
});
