import { action, makeObservable, observable } from 'mobx';
import { FormField } from './formField';
import { RootStore } from './root';
import { ROUTES } from '../routes';
import { DEVICE_LOGIN_METHODS, WIX_HOME_URL } from '../utils/constants';
import { HttpError } from '@wix/http-client';

export class DeviceLoginStore {
  private readonly rootStore: RootStore;
  private readonly authHandlers: {
    SIGNUP: () => void;
    CONNECTION_SUCCESSFUL: () => void;
    DISCONNECT_SUCCESSFUL: () => void;
  };
  public code: FormField;
  private VERIFY_CODE_INTERACTION_NAME = 'verify-code';
  private INVALIDATE_USER_CODE_INTERACTION_NAME = 'invalidate-user-code';
  public codeLength = 8;
  private urlParams: URLSearchParams = new URLSearchParams(window.location.search)
  private isDebug: boolean = this.urlParams.get('debugCase') === 'deviceLogin';

  get contentKey(): string {
    return 'device.login.connect.currently.logged.in';
  }

  get currentLoggedInEmail(): string {
    const userDataLoggedInEmail = this.rootStore.userDataStore.currentLoggedInEmail
    if (userDataLoggedInEmail) {
      return userDataLoggedInEmail;
    } else {
      return this.isDebug ? (this.urlParams.get('email') ?? '') : '';
    }
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    const {
      navigationStore: { curryNavigate: navigate },
    } = rootStore;
    makeObservable(this, {
      submit: action.bound,
      code: observable,
    });

    this.code = new FormField('');

    this.authHandlers = {
      [DEVICE_LOGIN_METHODS.SIGNUP]: navigate(ROUTES.SIGNUP_PASSWORD),
      [DEVICE_LOGIN_METHODS.CONNECTION_SUCCESSFUL]: navigate(
        ROUTES.CONNECTION_SUCCESSFUL,
      ),
      [DEVICE_LOGIN_METHODS.DISCONNECT_SUCCESSFUL]: navigate(
        ROUTES.DISCONNECT_SUCCESSFUL,
      ),
    };
  }

  public enableAccess(): boolean {
    return !!this.rootStore.userDataStore.currentLoggedInEmail || this.isDebug;
  }

  public redirectToWix() {
    return this.rootStore.navigationStore.redirect(WIX_HOME_URL);
  }

  public async submit() {
    if (!this.code.isValid) {
      return;
    }
    if (this.code.value.length < 8 || this.code.value.length > 8) {
      this.code.addError(
        this.rootStore.i18n.t('device.login.code.input.code.length.errorInfo'),
      );
      return;
    }

    this.rootStore.fedopsLogger.interactionStarted(
      this.VERIFY_CODE_INTERACTION_NAME,
    );
    const code = this.code.value;
    const { apiStore } = this.rootStore;
    try {
      const verifyCode = await apiStore.deviceVerifyCode(code);

      if (verifyCode === 200) {
        this.authHandlers[DEVICE_LOGIN_METHODS.CONNECTION_SUCCESSFUL]();
      }
      this.rootStore.fedopsLogger.interactionEnded(
        this.VERIFY_CODE_INTERACTION_NAME,
      );
    } catch (error: any) {
      this.rootStore.fedopsLogger.interactionEnded(
        this.VERIFY_CODE_INTERACTION_NAME,
      );
      this.code.addError(
        this.rootStore.i18n.t('device.login.code.input.incorrect.errorInfo'),
      );
    }
  }

  public async disconnect() {
    this.authHandlers[DEVICE_LOGIN_METHODS.DISCONNECT_SUCCESSFUL]();
    if (!this.code.isValid) {
      return;
    }
    this.rootStore.fedopsLogger.interactionStarted(
      this.INVALIDATE_USER_CODE_INTERACTION_NAME,
    );
    const code = this.code.value;
    const { apiStore } = this.rootStore;
    try {
      const verifyCode = await apiStore.deviceInvalidateUserCode(code);

      if (verifyCode === 200) {
        this.authHandlers[DEVICE_LOGIN_METHODS.DISCONNECT_SUCCESSFUL]();
      }
      this.rootStore.fedopsLogger.interactionEnded(
        this.INVALIDATE_USER_CODE_INTERACTION_NAME,
      );
    } catch (error: any) {
      this.rootStore.fedopsLogger.interactionEnded(
        this.INVALIDATE_USER_CODE_INTERACTION_NAME,
      );
    }
  }
}
