import { observer } from 'mobx-react';
import React from 'react';
import {
  Box,
  Text,
  TextButton,
  TextButtonSize,
  TextButtonWeight,
} from '@wix/design-system';
import { useTranslation } from 'react-i18next';

export interface ResendVerificationCodeProps {
  resendSuccessfullyIndication: boolean;
  resendThrottleTimerValue: number;
  sendConfirmationCode: () => void;
  resendErrorMessage?: string | null;
  resendButtonTranslationKey?: string;
  resendIndicationTranslationKey: string;
  resendButtonSize: TextButtonSize;
  resendButtonWeight?: TextButtonWeight;
}

export const ResendVerificationCode: React.FC<ResendVerificationCodeProps> =
  observer(
    ({
      resendSuccessfullyIndication,
      resendThrottleTimerValue,
      resendErrorMessage,
      sendConfirmationCode,
      resendButtonTranslationKey,
      resendIndicationTranslationKey,
      resendButtonSize,
      resendButtonWeight,
    }) => {
      const { t } = useTranslation();
      return (
        <Box direction="horizontal" gap={1}>
          {!resendSuccessfullyIndication && (
            <TextButton
              size={resendButtonSize}
              weight={resendButtonWeight}
              onClick={sendConfirmationCode}
            >
              {t(
                resendButtonTranslationKey ??
                  'confirmEmailModalSecondStep.confirmationCode.resendLink.text'
              )}
            </TextButton>
          )}
          {resendSuccessfullyIndication && (
            <Text size="medium" weight="thin" light secondary>
              {t(resendIndicationTranslationKey, {
                time: resendThrottleTimerValue.toString(),
              })}
            </Text>
          )}
          {resendErrorMessage && (
            <Box inline marginLeft={1}>
              <Text skin="error" size="small">
                {resendErrorMessage}
              </Text>
            </Box>
          )}
        </Box>
      );
    }
  );
