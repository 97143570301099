import React from 'react';
import {
  Notification as WSRNotification,
  NotificationTheme,
} from '@wix/design-system';
import { observer } from 'mobx-react';
import { NotificationStore } from '../../utils/notification';

interface INotification {
  store?: NotificationStore;
  dataHook?: string;
}

export const Notification: React.FC<INotification> = observer(
  ({ store, dataHook }) => {
    if (!store?.isOpen) {
      return null;
    }

    return (
      <WSRNotification
        dataHook={dataHook}
        theme={store.theme as NotificationTheme}
        show={store.isOpen}
        onClose={store.onClose}
        autoHideTimeout={store.autoHideTimeout}
        type={store.type}
      >
        <WSRNotification.TextLabel>{store.content}</WSRNotification.TextLabel>
        <WSRNotification.CloseButton />
      </WSRNotification>
    );
  },
);
