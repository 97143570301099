import { observer } from 'mobx-react';
import React from 'react';
import { Box, Loader, TextProps } from '@wix/design-system';
import { ThemedText } from '../ThemedComponents';

interface CustomLoaderProps {
  text: string;
  status: 'loading' | 'success' | 'error';
  textProps?: TextProps;
}

const CustomLoader: React.FC<CustomLoaderProps> = observer(
  ({ text, status, textProps = { light: true } }) => {
    return (
      <Box inline verticalAlign="middle" gap={1} minWidth={65}>
        <Loader
          size="tiny"
          status={status}
          color={status === 'success' ? 'white' : 'blue'}
        />
        <ThemedText {...textProps}>{text}</ThemedText>
      </Box>
    );
  },
);

export default CustomLoader;
