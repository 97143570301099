import React, { useContext, useEffect } from 'react';
import { AppContextProvider } from '../AppLoader';
import { Box, Text } from '@wix/design-system';
import { dataHooks } from '../../dataHooks';
import { DialogFormContainer } from '../DialogContainer';
import { keys } from '../../translationsKeys';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  ThemedHeading,
  ThemedText,
  ThemedTextButton,
} from '../ThemedComponents';
import s from './ConnectionSuccessful.scss';

interface ConnectionSuccessfulProps {}

export const ConnectionSuccessful: React.FC<ConnectionSuccessfulProps> =
  observer(() => {
    const {
      rootStore: { 
        deviceLoginStore,
        displayStore: { isMobile },
      },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();
    useEffect(() => {
      const timeout = setTimeout(() => {
        deviceLoginStore.redirectToWix();
      }, 10000);

      return () => clearTimeout(timeout);
    }, []);
    return (
      <Box direction="vertical" align="center" width="100%">
        <DialogFormContainer
          dataHook={dataHooks.deviceLogin.container}
          formClasses={[s.connectionSuccessfulForm]}
          mainHeaderProps={{showBackButton: false}}
        >
          <Box marginBottom={3} verticalAlign="middle">
            <ThemedHeading
              appearance={isMobile ? "H2" : "H1"}
              dataHook={dataHooks.connectionSuccessful.title}
            >
              {t(keys['device.connection.successful.title'])}
            </ThemedHeading>
          </Box>
          <Box marginBottom={3} verticalAlign="middle">
            <ThemedText weight="bold" size="small">
              {t(keys['device.connection.successful.content.first'])}
            </ThemedText>
          </Box>
          <Box marginBottom={3} verticalAlign="middle">
            <ThemedText size="small">
              {t(
                keys['device.connection.successful.content.second.text.third']
              )}
            </ThemedText>
          </Box>
          <Box marginBottom={3} verticalAlign="middle">
            <ThemedText size="small">
              {t(keys['device.connection.successful.content.second'])}
              <ThemedTextButton
                underline="always"
                dataHook={dataHooks.connectionSuccessful.disconnect}
                skin="dark"
                onClick={() => {
                  deviceLoginStore.disconnect();
                }}
                size="small"
              >
                {t(
                  keys['device.connection.successful.content.second.text.link']
                )}
              </ThemedTextButton>
            </ThemedText>
          </Box>
        </DialogFormContainer>
      </Box>
    );
  }
);
