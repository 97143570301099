const COOKIE_MAP = {};

/**
 * Parse a cookie string into an object
 * @param {string} cookies - valid cookie string (; separated key=value pairs)
 * @return {Object.<string, string>}
 */
const parseCookieString = (cookies: string): Record<string, string> => {
  let parsed = COOKIE_MAP[cookies];
  if (!parsed) {
    parsed = (cookies || '').split(/;\s*/).reduce((acc, cookie) => {
      const c = cookie.match(/([^=]*)\=(.*)/);
      if (c) {
        acc[c[1]] = c[2];
      }
      return acc;
    }, {});
    COOKIE_MAP[cookies] = parsed;
  }
  return parsed;
};

/**
 * Get a cookie by name
 * @param {string} name
 * @returns {string|undefined}
 */
export const getCookie = (name: string): string | undefined => {
  if (typeof window !== 'undefined') {
    const parsed = parseCookieString(window.document.cookie);
    return parsed[name];
  }
};
