import { RootStore } from './root';

export class SessionId {
  private sessionId: string = '';

  constructor(private rootStore: RootStore) {}

  get(): string {
    if (!this.sessionId) {
      const ldSessionID =
        this.rootStore.navigationStore.getQueryParam('sessionId');
      if (ldSessionID) {
        this.sessionId = ldSessionID;
      } else {
        this.sessionId = this.getNewGuid();
      }
    }
    return this.sessionId;
  }

  s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  private getNewGuid() {
    const s4 = this.s4;
    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    );
  }
}
