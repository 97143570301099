import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Box, Loader, Text } from '@wix/design-system';
import { AppContextProvider } from '../../../../AppLoader';
import { EnableState } from '../../../../../stores/premiumUsers2FaEnforcement/ownerApp/ownerApp';
import { useTranslation } from 'react-i18next';
import { StatusComplete } from '@wix/wix-ui-icons-common';

export const SuccessfulStep: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { ownerAppStore: store },
      },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  useEffect(() => {
    store.enable();
  }, [store]);
  useEffect(() => {
    if (store.enableState === EnableState.Enabled) {
      const timer = setTimeout(() => {
        store.premiumUsers2FaEnforcementStore.postLoginStore.proceedToPostAuthUrl(
          'Add 2FA over owner app successful'
        );
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [store.enableState, store.premiumUsers2FaEnforcementStore.postLoginStore]);
  return (
    <Box width="100%" height="100%" align="center">
      <Box direction="vertical" verticalAlign="middle">
        {store.enableState === EnableState.Enabling ? (
          <Loader text={t('ownerAppAsAddOnMethod.successful.connecting')} />
        ) : (
          <Box
            direction="vertical"
            alignContent="center"
            rowGap="12px"
            align="center"
          >
            <StatusComplete height="54px" width="54px" color="#084EBD" />
            <Text>{t('ownerAppAsAddOnMethod.successful.loggingIn')}</Text>
          </Box>
        )}
      </Box>
    </Box>
  );
});
