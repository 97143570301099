import {
  socialAuthError,
  socialAuthSuccess,
  socialLoginClicked,
} from '@wix/bi-logger-hls2/v2';
import { action, makeObservable, observable } from 'mobx';
import { ROUTES } from '../routes';
import { ERROR_CODES, extractServerErrorCode } from '../utils/errorHandler';
import { RootStore } from './root';
import webBiLogger from '@wix/web-bi-logger';
import { AUTH_TYPE } from '../components/BlockedAccount/authTypes';

export abstract class SocialProviderLoginStore<T> {
  protected readonly rootStore: RootStore;
  abstract readonly providerId: string;
  customButtonLogoUrl: string;
  lastLoginAuthParams: T;
  hasError: boolean = false;
  isLoading: boolean = false;
  isInitialized: boolean = false;
  useNewSdk: boolean = false;
  abstract init(): void;
  abstract initializeSdk(options?: { [key: string]: string }): void;
  abstract onLogin(authResult: any): void;
  abstract onClickCustomLoginButton(): void;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      hasError: observable,
      isLoading: observable,
      isInitialized: observable,
      lastLoginAuthParams: observable,
      handleError: action,
    });
  }

  handleError(
    options: {
      error?: string;
      state?: string;
      showErrorStatus?: boolean;
    } = {},
  ) {
    const { error, state, showErrorStatus = true } = options;
    this.hasError = showErrorStatus;
    this.rootStore.biLogger.report(
      socialAuthError({
        socialNetwork: this.providerId,
        reason: error || 'Unknown error',
        state,
      }),
    );
  }

  clearErrors() {
    this.hasError = false;
  }

  private handleLoginToWixSuccess(
    response: any,
    extendedData: { [key: string]: string } = {},
  ) {
    this.rootStore.biLogger = webBiLogger.factory().logger();
    this.rootStore.postLoginStore.biLogger = webBiLogger
      .factory()
      .logger();
    this.rootStore.biLogger.report(
      socialAuthSuccess({
        socialNetwork: this.providerId,
        context: this.rootStore.emailStepStore.getContext(),
        ...(extendedData.authMethod === 'GOOGLE_ONE_TAP' ? {state: 'googleOT'} : {})
      }),
    );
    const headerRedirectUrl = response.headers['x-wix-redirect-url'];
    if (headerRedirectUrl) {
      this.rootStore.navigationStore.redirect(headerRedirectUrl);
      return;
    }
    const redirectParams = { ...response.data?.payload, ...extendedData };
    if (response.data?.action === 'register') {
      // TODO - Show post signup modal if requested
      return this.rootStore.navigationStore.postSignup(redirectParams);
    }
    this.rootStore.navigationStore.postLogin(redirectParams);
  }

  isValidInteraction(errorCode: string = '') {
    return [
      ERROR_CODES.SOCIAL_LOGIN_VERIFY_ACCOUNT,
      ERROR_CODES.SOCIAL_LOGIN_GET_EMAIL,
      ERROR_CODES.SSO_LOGIN_MANDATORY_ERROR,
      ERROR_CODES.USER_DELETED_OR_BLOCKED,
      ERROR_CODES.USER_IS_DELETED,
      ERROR_CODES.USER_IS_BLOCKED,
      ERROR_CODES.OFAC_COUNTRIES_LOCATION_ERROR,
      ERROR_CODES.INVALID_EMAIL_OR_PASSWROD,
    ].includes(errorCode);
  }

  socialButtonClicked() {
    this.rootStore.biLogger.report(
      socialLoginClicked({
        socialNetwork: this.providerId,
        context: this.rootStore.emailStepStore.getContext(),
      }),
    );
  }

  private handleLoginToWixFailure(
    interactionName: string,
    data: any = {},
  ): { errorCode?: string } {
    this.rootStore.biLogger.report(
      socialAuthError({
        socialNetwork: this.providerId,
        reason: data.errorCode,
      }),
    );
    const navigate = this.rootStore.navigationStore.navigate;
    const errorCode = extractServerErrorCode(data);
    if (this.isValidInteraction(errorCode)) {
      this.rootStore.fedopsLogger.interactionEnded(interactionName);
    }
    if (errorCode === ERROR_CODES.SOCIAL_LOGIN_VERIFY_ACCOUNT) {
      this.rootStore.setActiveSocialProvider(this.providerId);
      this.rootStore.userDataStore.setEmail(data.errorDescription);
      navigate(ROUTES.ACCOUNT_VERIFICATION);
    } else if (errorCode === ERROR_CODES.USER_IS_BLOCKED) {
      this.rootStore.navigationStore.navigateToBlockedAccount( {refferal_info: AUTH_TYPE.SOCIAL} );
    } else if (errorCode === ERROR_CODES.SOCIAL_LOGIN_GET_EMAIL) {
      this.rootStore.setActiveSocialProvider(this.providerId);
      navigate(ROUTES.SOCIAL_ENTER_EMAIL);
    } else if (errorCode === ERROR_CODES.SSO_LOGIN_MANDATORY_ERROR) {
      const { accountSsoLoginUrl } = data.payload;
      navigate(accountSsoLoginUrl);
    } else if (errorCode === ERROR_CODES.EDITOR_X_SIGNUP_DISABLED_ERROR) {
      // Show Editor X signup disabled modal
      this.rootStore.socialAuthStore.showEditorXSignupDisabledModal = true;
    } else {
      this.hasError = true;
    }

    // The expected errors here are -
    // USER_DELETED_OR_BLOCKED, OFAC_COUNTRY, INVALID_EMAIL_OR_PASSWROD

    return { errorCode };
  }

  async loginToWix(
    authParams: T,
    extendedData: { [key: string]: string } = {},
  ): Promise<{ errorCode?: string; success: boolean }> {
    this.lastLoginAuthParams = authParams;
    this.isLoading = true;
    this.hasError = false;
    try {
      const interactionName = `${this.providerId}-server-response`;
      this.rootStore.fedopsLogger.interactionStarted(interactionName);
      const response = await this.rootStore.apiStore.socialLogin(
        this.rootStore.signupStore.applyAdditionalSignupParams(authParams),
        this.providerId,
      );
      if (response.data?.success) {
        this.rootStore.fedopsLogger.interactionEnded(interactionName);
        this.handleLoginToWixSuccess(response, extendedData);
        return { success: true };
      } else {
        this.isLoading = false;
        return {
          ...this.handleLoginToWixFailure(interactionName, response.data),
          success: false,
        };
      }
    } catch (error) {
      this.isLoading = false;
      return {
        errorCode: extractServerErrorCode(error as any),
        success: false,
      };
    }
  }

  continueLoginToWix(options: { password?: string } = {}) {
    return this.loginToWix({ ...this.lastLoginAuthParams, ...options });
  }
}
