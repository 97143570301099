import { action, computed, makeObservable, observable } from 'mobx';
import { RootStore } from './root';
import { NotificationStore } from '../utils/notification';
import { ERROR_CODES } from '../utils/errorHandler';
import { FORGOT_PASSWORD_LINK } from '../utils/constants';

export class ResetPasswordInvalidStore {
  public isTokenExpired: boolean = false;
  public isTokenAlreadyUsed: boolean = false;
  public isBadToken: boolean = false;
  public titleKey: string = '';
  public descriptionKey: string = '';
  public alreadyUsedLink: string;
  public resendNotificationStore: NotificationStore;
  private readonly rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.resendNotificationStore = new NotificationStore();
    this.isTokenExpired =
      window.__RESET_PASSWORD_TOKEN_ERROR_CODE__ ===
      ERROR_CODES.RESET_PASSWORD_TOKEN_EXPIRED;
    this.isTokenAlreadyUsed =
      window.__RESET_PASSWORD_TOKEN_ERROR_CODE__ ===
      ERROR_CODES.RESET_PASSWORD_TOKEN_ALREADY_USED;
    this.isBadToken =
      window.__RESET_PASSWORD_TOKEN_ERROR_CODE__ ===
      ERROR_CODES.RESET_PASSWORD_BAD_TOKEN;
    this.setParams();
    makeObservable(this, {
      isTokenExpired: observable,
      isTokenAlreadyUsed: observable,
      isBadToken: observable,
      titleKey: observable,
      descriptionKey: observable,
      enableAccess: computed,
      isInvalid: computed,
      onResendExpired: action.bound,
    });
  }

  private setResetPasswordAlreadyUsedLink() {
    const sessionId = this.rootStore.sessionId.get();
    this.alreadyUsedLink = FORGOT_PASSWORD_LINK(
      sessionId,
      this.rootStore.navigationStore.overrideLocale,
    );
  }

  private setParams() {
    this.setResetPasswordAlreadyUsedLink();
    const prefix = this.isTokenAlreadyUsed
      ? 'reset_password.already_used'
      : 'reset_password.expired';
    this.titleKey = `${prefix}.title`;
    this.descriptionKey = `${prefix}.description`;
  }

  public async onResendExpired() {
    try {
      await this.rootStore.apiStore.getCurrentPage();
      this.resendNotificationStore.open({
        content: this.rootStore.i18n.t('reset_password.expired.resend.success'),
      });
    } catch (_) {}
  }

  get enableAccess(): boolean {
    return this.rootStore.resetPasswordStore.enableAccess && this.isInvalid;
  }

  get isInvalid(): boolean {
    return this.isTokenAlreadyUsed || this.isTokenExpired || this.isBadToken;
  }
}
