import { WorkspaceSelector } from '@wix/identity-fed-common';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { dataHooks } from '../../dataHooks';
import { AppContextProvider } from '../AppLoader';
import { DialogContainer } from '../DialogContainer';
import s from './SelectWorkspace.scss';
import { Box } from '@wix/design-system';

interface SelectWorkspaceProps extends WithTranslation {}

const SelectWorkspace: React.FC<SelectWorkspaceProps> = observer(() => {
  const { rootStore } = useContext(AppContextProvider);
  const { selectWorkspaceStore, displayStore } = rootStore;
  const locale = rootStore.locale?.split('_')[0] || 'en';
  return (
    <Box direction="vertical" width="100%" align="center">
    <DialogContainer
      dataHook={dataHooks.selectWorkspaceDialog.container}
      contentClasses={[s.selectWorkspaceDialog]}
      mainHeaderProps={{
        backButtonCallback: selectWorkspaceStore.onBackClicked,
      }}
    >
      <WorkspaceSelector
        email={selectWorkspaceStore.email}
        isMobile={displayStore.isMobile}
        locale={locale}
      />
    </DialogContainer>
    </Box>
  );
});

export default withTranslation()(SelectWorkspace);
