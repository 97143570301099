import { HttpClient } from '@wix/http-client';
import { DealerOfferEventsService } from '@wix/ambassador-dealer-offer-events-service/http';
import { EventType } from '@wix/ambassador-dealer-offer-events-service/types';
import { FETCH_OFFERS_URL } from '../utils/constants';

export const DEALER_EVENTS_API_BASE_URL = '/dealer-offer-events-service';
export class DealerApi {
  private readonly REALESTATE_ID = '06451704-d5cb-4ae8-bf1c-3dc663c3bc8e';
  private readonly httpClient = new HttpClient();
  private dealerEventsService;

  constructor() {
    this.dealerEventsService = DealerOfferEventsService(
      DEALER_EVENTS_API_BASE_URL,
    ).DealerOfferEventsService();
  }

  public async getOffers(): Promise<Offers> {
    const data = {
      realEstateId: this.REALESTATE_ID,
      limit: 1,
    };

    try {
      const response = await this.httpClient.post(
        FETCH_OFFERS_URL,
        JSON.stringify(data),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public sendDealerMainCtaEvent(offerGuid: string, targetUrl: string) {
    this.dealerEventsService().reportEvent({
      event: {
        offerId: offerGuid,
        realEstateId: this.REALESTATE_ID,
        type: EventType.MAIN_CTA_CLICK,
        mainCtaClick: {
          targetUrl,
        },
      },
    });
  }

  public sendDealerViewEvent(offerGuid: string) {
    this.dealerEventsService().reportEvent({
      event: {
        offerId: offerGuid,
        realEstateId: this.REALESTATE_ID,
        type: EventType.VIEW,
      },
    });
  }

  public sendDealerShowLaterEvent(offerGuid: string) {
    this.dealerEventsService().reportEvent({
      event: {
        offerId: offerGuid,
        realEstateId: this.REALESTATE_ID,
        type: EventType.SHOW_LATER,
      },
    });
  }
}
