import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import {
  Box,
  FormField,
  RadioGroup,
  SectionHelper,
  Text,
} from '@wix/design-system';
import { useTranslation } from 'react-i18next';
import { AppContextProvider } from '../../../../AppLoader';
import { dataHooks } from '../../../../../dataHooks';
import { ReactComponent as WixLogo } from '../../../../../assets/images/wixLogo.svg';

export const ChooseDeviceStep: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { ownerAppStore: store },
      },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  return (
    <Box direction="vertical" width="100%" gap="24px">
      <SectionHelper appearance="success" size="small" fullWidth={true}>
        {t('ownerAppAsAddOnMethod.sectionHelper')}
      </SectionHelper>
      <Box>
        <Box
          marginRight="24px"
          border="1px solid black"
          borderRadius="12px"
          height="48px"
          width="48px"
          align="center"
          verticalAlign="middle"
        >
          <Box>
            <WixLogo width="36px"></WixLogo>
          </Box>
        </Box>
        <Box direction="vertical" gap="6px">
          <Text weight="bold" size="medium">
            {t('ownerAppAsAddOnMethod.selectDeviceStep.title')}
          </Text>
          <Text>{t('ownerAppAsAddOnMethod.selectDeviceStep.subtitle')}</Text>
          <Text></Text>
          <FormField
            label={
              <Box>
                <Text>
                  {t('ownerAppAsAddOnMethod.selectDeviceStep.choose.label')}
                </Text>
              </Box>
            }
          >
            <Box>
              {store.devices.length === 1 ? (
                <Text weight="bold">{store.devices[0].name}</Text>
              ) : (
                <RadioGroup
                  dataHook={dataHooks.ownerAppModal.options}
                  value={store.selectedDevice?.id}
                  onChange={(id?: string | number) => store.onSelectDevice(id)}
                >
                  {store.devices.map(({ id, name }) => (
                    <RadioGroup.Radio key={id} value={id}>
                      <Text
                        weight={
                          store.selectedDevice?.id === id ? 'bold' : 'thin'
                        }
                        secondary={store.selectedDevice?.id !== id}
                        color="dark"
                      >
                        {name}
                      </Text>
                    </RadioGroup.Radio>
                  ))}
                </RadioGroup>
              )}
            </Box>
          </FormField>
        </Box>
      </Box>
    </Box>
  );
});
