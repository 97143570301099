import * as React from 'react';

export enum Steps {
  DeviceSelectionStep,
  ConfirmationStep,
  BackupMethodStep,
  SuccessfulStep,
}

interface StepDefinition {
  title?: string;
  subtitle?: string;
  primaryButtonText?: string;
  primaryButtonOnClick?: () => Promise<void>;
  secondaryButtonText?: string;
  secondaryButtonOnClick?: () => void;
  sideActions?: React.ReactNode;
  currentStep?: React.ReactNode;
}
export type StepDefinitions = {
  [key in Steps]: StepDefinition;
};
