export const PRE_RENDER_LOGIN_FT =
  'specs.ident.RedirectToNewLoginBeforeForceRender';
export const SHOULD_BLOCK_EDITOR_X_SIGNUP_FT =
  'specs.ident.ShouldBlockEditorXSignup';
export const ALLOWED_AUTO_REDIRECT_DIALOGS = ['login', 'signup'];
export const RENDER_APP_PATH = 'loginApp';
export const ALLOWED_URL_PATH_ENDINGS = [
  `/${RENDER_APP_PATH}`,
  `/${RENDER_APP_PATH}/`,
  `/${RENDER_APP_PATH}/auth`,
];
