import React from 'react';
import { makeAutoObservable } from 'mobx';
import { AddPhoneStore } from '../../../../stores/premiumUsers2FaEnforcement/sms/addPhone';
import {
  StepDefinitions,
  Steps,
} from '../../../../stores/premiumUsers2FaEnforcement/sms/types';
import { BackTextButton } from '../OwnerApp/BackTextButton';

export class UiStore {
  public isBackupMethod: boolean = false;
  constructor(private store: AddPhoneStore) {
    makeAutoObservable(this);
    this.isBackupMethod =
      store.premiumUsers2FaEnforcementStore.ownerAppStore.waitingForBackupMethod;
  }

  translate(s?: string) {
    return s ? this.store.premiumUsers2FaEnforcementStore.t(s) : s;
  }

  get steps() {
    return this.isBackupMethod
      ? this.backupMethodSteps
      : this.initialMethodSteps;
  }

  initialMethodSteps: StepDefinitions = {
    [Steps.AddPhoneStep]: {
      title: 'post.login.mfa.phone.choose.title',
      subtitle: 'post.login.mfa.phone.choose.subtitle',
      primaryButtonText: 'add_phone_wizard.new_style.add_phone.confirm_button',
      primaryButtonOnClick: this.store.onSendVerificationSms,
      sideActions: (
        <BackTextButton
          goBack={
            this.store.premiumUsers2FaEnforcementStore
              .goBackToEnforcementOrChooseBackupMethod
          }
        />
      ),
      currentStep: undefined,
    },
    [Steps.ConfirmCodeStep]: {
      title: 'post.login.mfa.phone.verify.title',
      sideActions: <BackTextButton goBack={this.store.onBackToPreviousStep} />,
      currentStep: undefined,
    },
  };

  backupMethodSteps: StepDefinitions = {
    [Steps.AddPhoneStep]: {
      title: 'add_phone_wizard.title',
      subtitle: 'add_phone_wizard.subtitle',
      primaryButtonText: 'add_phone_wizard.new_style.add_phone.confirm_button',
      primaryButtonOnClick: this.store.onSendVerificationSms,
      sideActions: (
        <BackTextButton
          goBack={
            this.store.premiumUsers2FaEnforcementStore
              .goBackToEnforcementOrChooseBackupMethod
          }
        />
      ),
      currentStep: undefined,
    },
    [Steps.ConfirmCodeStep]: {
      title: 'add_phone_wizard.title',
      subtitle: 'add_phone_wizard.subtitle',
      sideActions: <BackTextButton goBack={this.store.onBackToPreviousStep} />,
      currentStep: undefined,
    },
  };

  get title() {
    return this.translate(this.steps[this.store.activeStep].title);
  }

  get subtitle() {
    return this.translate(this.steps[this.store.activeStep].subtitle);
  }

  get primaryButtonText() {
    return this.translate(this.steps[this.store.activeStep].primaryButtonText);
  }

  get primaryButtonOnClick() {
    return this.steps[this.store.activeStep].primaryButtonOnClick;
  }

  get sideActions() {
    return this.steps[this.store.activeStep].sideActions;
  }
}
