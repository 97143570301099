import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { Box, TextProps, HeadingProps } from '@wix/design-system';
import classNames from 'classnames';
import { ThemedHeading, ThemedText } from '../ThemedComponents';
import s from './DialogHeader.scss';
import { AppContextProvider } from '../AppLoader';
import { dataHooks } from '../../dataHooks';

interface DialogHeaderProps {
  dataHook?: string;
  className?: string;
}

const _DialogHeader: React.FC<DialogHeaderProps> = observer(
  ({ dataHook, children, className }) => {
  
    return (
      <Box
        aria-live="assertive"
        aria-atomic="true"
        direction="vertical"
        gap={1}
        align="center"
        className={classNames(s.dialogHeaderContainer, className)}
        dataHook={dataHook ?? dataHooks.dialogHeader.container}
      >
        {children}
      </Box>
    );
  },
);

const Title: React.FC<HeadingProps> = observer(
  ({ children, dataHook, className, ...props }) => {
    const {
      rootStore: { displayStore },
    } = useContext(AppContextProvider);
    return (
      <ThemedHeading
        aria-live="assertive"
        aria-atomic="true"
        appearance={displayStore.isMobile ? 'H2' : 'H1'}
        dataHook={dataHook}
        className={classNames(s.mainTitle, className)}
        {...props}
      >
        {children}
      </ThemedHeading>
    );
  },
);

const SubTitleLarge: React.FC<{ dataHook?: string }> = observer(
  ({ children, dataHook }) => {
    const {
      rootStore: { displayStore },
    } = useContext(AppContextProvider);
    return displayStore.isMobile ? (
      <ThemedText weight="normal">{children}</ThemedText>
    ) : (
      <ThemedHeading appearance="H4" dataHook={dataHook}>
        {children}
      </ThemedHeading>
    );
  },
);

const SubTitleSmall: React.FC<TextProps & { maxWidth?: string }> = ({
  children,
  dataHook,
  maxWidth,
  ...props
}) => (
  <Box maxWidth={maxWidth}>
    <ThemedText dataHook={dataHook} {...props}>
      {children}
    </ThemedText>
  </Box>
);

export const DialogHeader = Object.assign(_DialogHeader, {
  SubTitleLarge,
  SubTitleSmall,
  Title,
});
