import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx';
import { StepperStep, StepperStepType } from '@wix/design-system';
import { VerifyCode } from '../verifyCode';
import {
  ownerApp2FaSetupChoosingABackup,
  ownerApp2FaSetupClickOnNextAfterChoosingDevice,
  ownerApp2FaSetupClickOnResendRequest,
} from '@wix/bi-logger-identity-data/v2';

import { PremiumUsers2FaEnforcementStore } from '../premiumUsers2FaEnforcement';
import { Device } from '../../../services/DeviceRegistry';
import { ApprovalChallengeStatus } from '@wix/ambassador-identity-v1-verification/types';
import { MultiFactorIdentifier } from '../multiFactorAuth';
import { Steps } from './types';
import { BI_ORIGIN, BI_SCREEN_NAMES } from '../../constants';

export enum EnableState {
  Idle = 0,
  Enabling = 1,
  Enabled = 2,
}

export enum BackupMethods {
  SMS = 'sms',
  TOTP = 'totp',
  EMAIL = 'email',
}

export const BackupMethodIndexToMfaType = {
  0: 'sms',
  1: 'auth app',
  2: 'email',
};

export const BackupMethodsToIndex = {
  [BackupMethods.SMS]: 0,
  [BackupMethods.TOTP]: 1,
  [BackupMethods.EMAIL]: 2,
};

export class OwnerAppStore extends VerifyCode {
  public waitingForBackupMethod: boolean = false;
  public devices: Device[] = [];
  public selectedDevice?: Device;
  public selectedBackupMethod: number = 0;
  public authStatus: ApprovalChallengeStatus =
    ApprovalChallengeStatus.PENDING_APPROVAL;
  public activeStep: Steps;
  public resendErrorMessage: string;
  private verificationId: string;
  private timer: NodeJS.Timer;
  private origin: string;
  public enableState: EnableState = EnableState.Idle;

  constructor(
    public premiumUsers2FaEnforcementStore: PremiumUsers2FaEnforcementStore
  ) {
    super(premiumUsers2FaEnforcementStore);
    makeObservable(this, {
      devices: observable,
      selectedDevice: observable,
      authStatus: observable,
      activeStep: observable,
      noBackupMethodsEnabled: computed,
      enableState: observable,
      selectedBackupMethod: observable,
      resendErrorMessage: observable,
      openModal: action,
      onSelectDevice: action,
      onSelectBackupMethod: action,
      enable: action.bound,
      sendPushToSelectedDevice: action.bound,
      nextStep: action.bound,
      setActiveStep: action.bound,
      init: action.bound,
      updateAuthStatus: action.bound,
      hasConnectedDevices: computed,
      startPostAuthRedirect: action.bound,
      onBackToChooseVerificationMethod: action.bound,
    });
    this.reactToAuthStatus();
    this.init().then(() => {});
  }

  public async init() {
    this.devices = await this.fetchUserDevices();
    this.selectedDevice = this.devices[0];
    this.updateAuthStatus(ApprovalChallengeStatus.PENDING_APPROVAL);
    this.activeStep = Steps.DeviceSelectionStep;
  }

  private reactToAuthStatus() {
    reaction(
      () => this.authStatus,
      async () => {
        if (this.authStatus !== ApprovalChallengeStatus.APPROVED) {
          return;
        }
        try {
          if (this.noBackupMethodsEnabled) {
            this.activeStep = Steps.BackupMethodStep;
            this.waitingForBackupMethod = true;
            return;
          }
          this.activeStep = Steps.SuccessfulStep;
        } catch (e) {}
      }
    );
  }

  public onStepClick = (stepIndex: number) => {
    if (stepIndex === 0) {
      this.activeStep = Steps.DeviceSelectionStep;
    }
  };

  onBackToChooseVerificationMethod() {
    switch (this.activeStep) {
      case Steps.DeviceSelectionStep:
        this.waitingForBackupMethod = false;
        this.premiumUsers2FaEnforcementStore.postLoginStore.rootStore.navigationStore.navigate(
          this.premiumUsers2FaEnforcementStore.postLoginStore.postLoginRoutes
            .PREMIUM_USERS_2FA_ENFORCEMENT
        );
        break;
      case Steps.ConfirmationStep:
      case Steps.BackupMethodStep:
        this.activeStep = Steps.DeviceSelectionStep;
        break;
    }
  }

  public setActiveStep(step: Steps) {
    this.activeStep = step;
  }

  public async enable() {
    this.enableState = EnableState.Enabling;
    await this.premiumUsers2FaEnforcementStore.postLoginStore.accountSettingsApi.OwnerApp.enable(
      this.verificationId,
      this.selectedDevice?.id
    );
    this.enableState = EnableState.Enabled;
    this.setAsEnabled();
  }

  public startPostAuthRedirect() {
    if (this.enableState === EnableState.Enabled) {
      const timer = setTimeout(() => {
        this.premiumUsers2FaEnforcementStore.postLoginStore.proceedToPostAuthUrl(
          'Add 2FA over owner app successful'
        );
      }, 1000);

      return () => clearTimeout(timer);
    }
  }

  public fetchUserDevices = async () => {
    return this.premiumUsers2FaEnforcementStore.postLoginStore.deviceRegistry.fetchUserDevices(
      this.premiumUsers2FaEnforcementStore.postLoginStore.userDetails.guid
    );
  };

  async openModal(asAddOn: boolean) {
    const path = asAddOn
      ? this.premiumUsers2FaEnforcementStore.postLoginStore.postLoginRoutes
          .PREMIUM_USERS_2FA_WIX_OWNER_APP_SECOND_METHOD
      : this.premiumUsers2FaEnforcementStore.postLoginStore.postLoginRoutes
          .PREMIUM_USERS_2FA_WIX_OWNER_APP;
    this.origin = asAddOn
      ? BI_ORIGIN.TWO_FA_ENFORCEMENT_SECOND_METHOD
      : BI_ORIGIN.TWO_FA_ENFORCEMENT;
    runInAction(async () => {
      await this.init();
      this.premiumUsers2FaEnforcementStore.navigationStore.navigate(path);
    });
  }
  onSelectDevice(id?: string | number) {
    this.selectedDevice = this.devices.find((device) => device.id === id);
  }

  onSelectBackupMethod(method: number | string | undefined) {
    if (typeof method === 'number') {
      this.selectedBackupMethod = method;
    }
  }

  requestAddBackupMethod() {
    this.premiumUsers2FaEnforcementStore.sendBiEvent(
      ownerApp2FaSetupChoosingABackup({
        mfaType: BackupMethodIndexToMfaType[this.selectedBackupMethod],
      })
    );
    switch (this.selectedBackupMethod) {
      case BackupMethodsToIndex[BackupMethods.SMS]:
        this.premiumUsers2FaEnforcementStore.multiFactorAuth.onRequestAddFactor(
          MultiFactorIdentifier.Phone
        );
        break;
      case BackupMethodsToIndex[BackupMethods.TOTP]:
        this.premiumUsers2FaEnforcementStore.multiFactorAuth.onRequestAddFactor(
          MultiFactorIdentifier.AuthApp
        );
        break;
      case BackupMethodsToIndex[BackupMethods.EMAIL]:
        this.premiumUsers2FaEnforcementStore.multiFactorAuth.onRequestAddFactor(
          MultiFactorIdentifier.Email
        );
        break;
    }
  }

  setAsEnabled() {
    this.premiumUsers2FaEnforcementStore.twoFASettings.ownerAppMethod = {};
  }

  async sendPushToSelectedDevice(
    resend?: boolean,
    authStatus?: ApprovalChallengeStatus
  ) {
    try {
      if (this.timer) {
        clearInterval(this.timer);
      }
      if (resend) {
        this.resendSuccessfullyIndication = true;

        this.premiumUsers2FaEnforcementStore.sendBiEvent(
          ownerApp2FaSetupClickOnResendRequest({
            screenName: this.getScreenName(authStatus!!),
            origin: this.origin,
          })
        );
      }
      const res =
        await this.premiumUsers2FaEnforcementStore.postLoginStore.accountSettingsApi.sendVerificationCode(
          {
            deviceId: this.selectedDevice?.id,
          }
        );
      this.verificationId = res.data.verificationId;
      await this.checkOwnerAuthStatus(this.verificationId);
    } catch (e) {
      if (resend) {
        return (this.resendErrorMessage =
          this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
            'addOwnerApp.wizard.error.resend'
          ));
      }
    }
  }

  getScreenName(authStatus: ApprovalChallengeStatus) {
    switch (authStatus) {
      case ApprovalChallengeStatus.EXPIRED:
        return BI_SCREEN_NAMES.ENFORCEMENT_FAILED_TO_CONFIRM_DEVICE;
      case ApprovalChallengeStatus.DECLINED:
        return BI_SCREEN_NAMES.ENFORCEMENT_LOGIN_REQUEST_WAS_DENIED;
      case ApprovalChallengeStatus.PENDING_APPROVAL:
        return BI_SCREEN_NAMES.APPROVE_DEVICE;
    }
  }

  async checkOwnerAuthStatus(verificationId: string) {
    const TIME_OUT_EXPIRED = 60000;
    const interval = 2000;
    this.updateAuthStatus(ApprovalChallengeStatus.PENDING_APPROVAL);

    this.timer = setInterval(async () => {
      const result =
        await this.premiumUsers2FaEnforcementStore.postLoginStore.accountSettingsApi.OwnerApp.getStatus(
          verificationId
        );
      const status = result.challenge?.approvalChallenge?.status;
      this.updateAuthStatus(status ?? ApprovalChallengeStatus.PENDING_APPROVAL);
      if (status !== ApprovalChallengeStatus.PENDING_APPROVAL) {
        clearInterval(this.timer);
      }
    }, interval);

    setTimeout(() => {
      clearInterval(this.timer);
    }, TIME_OUT_EXPIRED);
  }

  public updateAuthStatus(status: ApprovalChallengeStatus) {
    this.authStatus = status;
  }

  async nextStep() {
    switch (this.activeStep) {
      case Steps.DeviceSelectionStep:
        await this.sendPushToSelectedDevice();
        this.premiumUsers2FaEnforcementStore.sendBiEvent(
          ownerApp2FaSetupClickOnNextAfterChoosingDevice({
            chosenDevice: this.selectedDevice?.name,
            origin: this.origin,
          })
        );
        this.activeStep = Steps.ConfirmationStep;
        break;
      case Steps.BackupMethodStep:
        this.requestAddBackupMethod();
        break;
      default:
        break;
    }
  }

  stopPolling() {
    clearInterval(this.timer);
  }

  getStepType(step: Steps) {
    if (this.activeStep === step) {
      return 'normal' as StepperStepType;
    }
    if (this.activeStep > step) {
      return 'completed' as StepperStepType;
    }
    return 'disabled' as StepperStepType;
  }

  get steps(): StepperStep[] {
    const steps = [
      {
        text: this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
          'addOwnerApp.wizard.choose.title'
        ),
        type: this.getStepType(Steps.DeviceSelectionStep),
      },
      {
        text: this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
          'addOwnerApp.wizard.confirm.title'
        ),
        type: this.getStepType(Steps.ConfirmationStep),
      },
    ];

    if (this.noBackupMethodsEnabled) {
      steps.push({
        text: this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
          'addOwnerApp.wizard.backup.title'
        ),
        type: this.getStepType(Steps.BackupMethodStep),
      });
    }

    return steps;
  }

  get primaryButtonText() {
    switch (this.activeStep) {
      case Steps.DeviceSelectionStep:
      case Steps.BackupMethodStep:
        return this.premiumUsers2FaEnforcementStore.postLoginStore.i18n.t(
          'addOwnerApp.wizard.primary'
        );
      default:
        return undefined;
    }
  }

  get hasConnectedDevices() {
    return this.devices.length > 0;
  }

  get noBackupMethodsEnabled() {
    return this.premiumUsers2FaEnforcementStore.multiFactorAuth
      .hasNoBackupMethodsEnabled;
  }
}
