import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { AppContextProvider } from '../AppLoader';
import { SocialLoginButton, SocialLoginIcon } from '../SocialLoginButton';
import { dataHooks } from '../../dataHooks';

export const GoogleLoginButton: React.FC = observer(() => {
  const {
    rootStore: { googleLoginStore, displayStore },
  } = useContext(AppContextProvider);
  return (
    <SocialLoginButton
      socialStore={googleLoginStore}
      dataHook={dataHooks.socials.googleBtn}
      loaderWidth={displayStore.socialButtonsWidth}
    >
      {googleLoginStore.useNewSdk ? (
        // 'aria-disabled' and 'aria-label' wrappers are essentials for google button to work with voice over in mobile
        <div
          aria-label="GOOGLE_SIGNIN_BUTTON"
          aria-disabled="false"
          role="button"
        >
          <div
            className={googleLoginStore.googleBtnClassName}
            style={{ width: displayStore.socialButtonsWidth }}
          ></div>
        </div>
      ) : (
        <SocialLoginButton.OldCustomLoginButton />
      )}
    </SocialLoginButton>
  );
});

export const GoogleLoginIcon: React.FC<{ className?: string }> = observer(
  ({ className }) => {
    const {
      rootStore: { googleLoginStore },
    } = useContext(AppContextProvider);

    return (
      <SocialLoginIcon
        socialStore={googleLoginStore}
        additionalProps={{ type: 'icon', shape: 'circle' }}
        dataHook={dataHooks.socials.googleIcon}
        buttonClassName={className}
      >
        {googleLoginStore.useNewSdk ? (
          <div className={googleLoginStore.googleBtnClassName}></div>
        ) : (
          <SocialLoginButton.CustomLoginIcon />
        )}
      </SocialLoginIcon>
    );
  },
);
