import { action, computed, makeObservable, observable } from 'mobx';
import { email2FaSetupSendCode } from '@wix/bi-logger-identity-data/v2';

import { PremiumUsers2FaEnforcementStore } from './premiumUsers2FaEnforcement';

import { confirmationCodeActions } from './types';
import { BI_ORIGIN } from '../constants';
import { AuthenticatorAppData } from './authApp/types';

export enum MultiFactorIdentifier {
  Phone = 'Phone',
  Email = 'Email',
  AuthApp = 'AuthApp',
  OwnerApp = 'OwnerApp',
}
export class MultiFactorAuthStore {
  public password: string;

  constructor(
    private premiumUsers2FaEnforcementStore: PremiumUsers2FaEnforcementStore
  ) {
    makeObservable(this, {
      password: observable,
      onRequestAddFactor: action,
      onRequestAddAuthApp: action,
      handle2faSettingsUpdate: action,
      authenticatorAppArticleLink: computed,
      Phone: computed,
      Email: computed,
      AuthApp: computed,
      OwnerApp: computed,
      hasNoMethodsEnabled: computed,
    });
  }

  public onClickLearnMoreLink() {
    // TODO: Do we need to send a BI event here?;
  }

  public onRequestAddFactor(identifier: MultiFactorIdentifier) {
    const addFactorCallbacks = {
      [MultiFactorIdentifier.Phone]: () => this.onRequestAddSms(),
      [MultiFactorIdentifier.Email]: () => this.onRequestAddEmail(),
      [MultiFactorIdentifier.AuthApp]: () => this.onRequestAddAuthApp(),
      [MultiFactorIdentifier.OwnerApp]: () => this.onRequestAddOwnerApp(),
    };
    return addFactorCallbacks[identifier]();
  }

  public onRequestAddSms() {
    return this.premiumUsers2FaEnforcementStore.add2FAPhoneStore.onRequestAddPhone();
  }

  public onRequestAddEmail() {
    this.premiumUsers2FaEnforcementStore.sendBiEvent(
      email2FaSetupSendCode({
        action: confirmationCodeActions.SEND,
        origin: BI_ORIGIN.TWO_FA_ENFORCEMENT,
      })
    );
    this.premiumUsers2FaEnforcementStore.confirmEmail.onOpenModal();
  }

  public onRequestAddOwnerApp() {
    this.premiumUsers2FaEnforcementStore.ownerAppStore
      .openModal(false)
      .then(() => {});
  }

  public async onRequestAddAuthApp() {
    try {
      const authenticatorAppData: AuthenticatorAppData =
        await this.premiumUsers2FaEnforcementStore.postLoginStore.accountSettingsApi.startAuthenticatorAppMethod(
          this.premiumUsers2FaEnforcementStore.postLoginStore.userDetails.email
        );
      this.premiumUsers2FaEnforcementStore.authenticatorAppModals.openQrModal({
        ...authenticatorAppData,
      });
    } catch (error) {
      console.error(error);
    }
  }

  public async handle2faSettingsUpdate() {
    if (
      this.premiumUsers2FaEnforcementStore.ownerAppStore.waitingForBackupMethod
    ) {
      try {
        await this.premiumUsers2FaEnforcementStore.ownerAppStore.enable();
      } catch (error) {}
    }
    await this.premiumUsers2FaEnforcementStore.fetchTwoFASettings();
  }

  get authenticatorAppArticleLink(): string {
    return `https://support.wix.com/${this.premiumUsers2FaEnforcementStore.postLoginStore.rootStore.language.locale}/article/using-an-authenticator-app-for-2-step-verification-for-your-wix-account`;
  }

  get Phone(): {
    international?: string;
    phone?: string;
    countryCode?: string;
    isEnabled: boolean;
  } {
    const phoneNumber =
      this.premiumUsers2FaEnforcementStore.twoFASettings?.phoneMethod
        ?.phoneNumber;
    return {
      international: phoneNumber?.internationalNumber,
      phone: phoneNumber?.nationalNumber,
      countryCode: phoneNumber?.countryCode,
      isEnabled: !!phoneNumber,
    };
  }

  get Email(): {
    email?: string;
    isEnabled: boolean;
    isDomainRestricted: boolean;
  } {
    const twoFASettings = this.premiumUsers2FaEnforcementStore.twoFASettings;
    return {
      email: twoFASettings?.emailMethod
        ? twoFASettings.emailMethod.email ||
          this.premiumUsers2FaEnforcementStore.postLoginStore.userDetails.email
        : undefined,
      isEnabled: !!twoFASettings?.emailMethod,
      isDomainRestricted: !!twoFASettings?.restrictions?.find(
        (r) => r === 'NotAllowedToSet2FAViaEmail'
      ),
    };
  }

  get AuthApp(): {
    isEnabled: boolean;
  } {
    return {
      isEnabled:
        !!this.premiumUsers2FaEnforcementStore.twoFASettings?.totpMethod,
    };
  }

  get OwnerApp(): {
    isEnabled: boolean;
  } {
    return {
      isEnabled:
        !!this.premiumUsers2FaEnforcementStore.twoFASettings?.ownerAppMethod,
    };
  }

  get hasNoMethodsEnabled(): boolean {
    return (
      !this.Email.isEnabled &&
      !this.Phone.isEnabled &&
      !this.AuthApp.isEnabled &&
      !this.OwnerApp.isEnabled
    );
  }

  get hasNoBackupMethodsEnabled(): boolean {
    return (
      !this.Email.isEnabled && !this.Phone.isEnabled && !this.AuthApp.isEnabled
    );
  }
}
