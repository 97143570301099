import { action, makeObservable, observable } from 'mobx';
import { RootStore } from './root';
import { extractErrorKeyByErrorCode } from '../utils/errorHandler';

export class ActionElementStore {
  private readonly rootStore: RootStore;

  status: 'error' | 'success';
  showMessage: boolean;
  messageContent: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      status: observable,
      showMessage: observable,
      messageContent: observable,
      onActionCompleted: action.bound,
      onHideMessage: action.bound,
    });
  }

  onActionCompleted(errorCode) {
    this.status = errorCode ? 'error' : 'success';
    this.showMessage = true;
    this.messageContent = this.rootStore.i18n.t(
      errorCode
        ? extractErrorKeyByErrorCode(errorCode)
        : 'enter_code.resend_code_tooltip_notification',
    );
  }

  onHideMessage() {
    this.showMessage = false;
    this.messageContent = '';
  }
}
