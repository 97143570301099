import React, { useContext } from 'react';
import { Box, Text, CustomModalLayout, Divider } from '@wix/design-system';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { AppContextProvider } from '../../../AppLoader';
import { dataHooks } from '../../../../stores/premiumUsers2FaEnforcement/dataHooks';
import { BackTextButton } from '../OwnerApp/BackTextButton';
import s from '../modals.scss';
import { EnforcementModal } from '../EnforcementModal';

export const ConfirmEmailModal: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { confirmEmail },
      },
    },
  } = useContext(AppContextProvider);
  return (
    <EnforcementModal>
      <CustomModalLayout
        primaryButtonText={confirmEmail.firstStepButtonConfirmText}
        dataHook={dataHooks.confirmEmailModal.firstStep.modal}
        primaryButtonOnClick={confirmEmail.onFirstStepConfirm}
        primaryButtonProps={{
          disabled: confirmEmail.isSubmitted,
        }}
        title={confirmEmail.titleFirstStep}
        subtitle={confirmEmail.subtitleFirstStep}
        sideActions={
          <BackTextButton
            goBack={
              confirmEmail.premiumUsers2FaEnforcementStore
                .goBackToEnforcementOrChooseBackupMethod
            }
          />
        }
        className={s.enforcementEmailModal}
        showHeaderDivider
        removeContentPadding
      >
        <Box direction="vertical" padding="24px 36px 141px 30px">
          <Box direction="vertical">
            <Text>{t('confirmEmailBeforeTwoFactorEmailModal.content')}</Text>
            <Box height="42px" verticalAlign="middle">
              <Text weight="bold">{confirmEmail.email}</Text>
            </Box>
            <Divider skin="light"></Divider>
          </Box>
        </Box>
      </CustomModalLayout>
    </EnforcementModal>
  );
});
