import { RootStore } from './root';
import {
  postLoginConfirmAccountDetails,
  postLoginConfirmDetailsDone,
} from '@wix/bi-logger-post-login/v2';

export class ConfirmDetailsStore {
  public isEmailVerified: boolean = false;
  public isRecoveryPhoneVerified: boolean = false;
  private rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  public editEmail = () => {
    this.sendBiEvent('Edit email');
    this.rootStore.navigationStore.navigate(
      this.rootStore.postLoginStore.postLoginRoutes.CHANGE_ACCOUNT_EMAIL
    );
  };

  public editPhone = () => {
    this.rootStore.confirmDetailsStore.sendBiEvent('Edit phone');
    this.rootStore.navigationStore.navigate(
      this.rootStore.postLoginStore.postLoginRoutes.CONFIRM_DETAILS_PHONE
    );
  };

  public confirm = () => {
    let changes;
    if (this.isEmailVerified && this.isRecoveryPhoneVerified) {
      changes = 'Email and Phone number';
    } else if (this.isEmailVerified) {
      changes = 'Email';
    } else if (this.isRecoveryPhoneVerified) {
      changes = 'Phone number';
    } else {
      changes = null;
    }
    this.sendBiEvent('Confirm');
    this.rootStore.biLogger.report(
      postLoginConfirmDetailsDone({
        changes,
      })
    );
    this.rootStore.postLoginStore.offerSucceed();
  };

  public showLater = () => {
    this.sendBiEvent('Not Now');
    this.rootStore.postLoginStore.showLater();
  };

  public sendBiEvent = (button: string) => {
    this.rootStore.biLogger.report(
      postLoginConfirmAccountDetails({
        button,
        flowType: 'Confirm account details',
      })
    );
  };
}
