import { action, makeObservable, observable, override } from 'mobx';
import { ConfirmaitonCodeActions, Values } from '../utils/constants';
import { RootStore } from './root';
import { VerifyCodeStore } from './verifyCode';
import {
  postLoginConfirmAccountDetails,
  postLoginVerifyDetails,
} from '@wix/bi-logger-post-login/v2';

export class ConfirmEmailStore extends VerifyCodeStore {
  public isLoading: boolean = false;
  public emailSentNotificationText: string;
  public isSubmitted: boolean = false;
  public email: string;
  public verificationId: string;
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this, {
      isLoading: observable,
      emailSentNotificationText: observable,
      isSubmitted: observable,
      email: observable,
      onResendConfirmationCode: override,
      onVerifyConfirmationCode: override,
      clean: action,
    });
  }

  public async confirmEmail() {
    try {
      this.rootStore.biLogger.report(
        postLoginConfirmAccountDetails({
          button: 'Confirm',
          flowType: 'Confirm Email',
        })
      );
      const response = await this.sendConfirmationCode(
        ConfirmaitonCodeActions.SEND
      );
      if (response?.data?.result?.success) {
        this.rootStore.navigationStore.navigate(
          this.rootStore.postLoginStore.postLoginRoutes.CONFIRM_EMAIL_CODE
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  public showLater = () => {
    this.rootStore.biLogger.report(
      postLoginConfirmAccountDetails({
        button: 'Not now',
        flowType: 'Confirm Email',
      })
    );
    this.rootStore.postLoginStore.showLater();
  };

  public changeEmail = () => {
    this.rootStore.biLogger.report(
      postLoginConfirmAccountDetails({
        button: 'Edit email',
        flowType: 'Confirm Email',
      })
    );
    this.rootStore.navigationStore.navigate(
      this.rootStore.postLoginStore.postLoginRoutes.CHANGE_ACCOUNT_EMAIL
    );
  };

  public async onResendConfirmationCode() {
    this.sendConfirmationCode(ConfirmaitonCodeActions.RESEND);
  }

  public clean = () => {
    this.isSubmitted = false;
    this.confirmCode.clear();
  };

  public async sendConfirmationCode(
    clickAction: Values<
      typeof ConfirmaitonCodeActions
    > = ConfirmaitonCodeActions.SEND,
    isActionTagRequired: boolean = true,
    email?: string
  ): Promise<any> {
    try {
      this.isSubmitted = true;

      const response =
        await this.rootStore.accountSettingsApi.sendVerificationCode({
          email: email ?? this.rootStore.postLoginStore.userDetails.email,
          incognito: true,
          isActionTagRequired,
        });

      this.verificationId = response?.data?.verificationId;

      return response;
    } catch (error) {
      this.isSubmitted = false;
      if (clickAction === ConfirmaitonCodeActions.RESEND) {
        throw error;
      }
    }
  }

  public async onVerifyConfirmationCode() {
    try {
      const success = await this.rootStore.accountSettingsApi.verifyEmailCode({
        verificationCode: this.confirmCode.value,
        verificationId: this.verificationId,
      });

      if (success) {
        this.rootStore.postLoginStore.offerSucceed();
        this.rootStore.biLogger.report(
          postLoginVerifyDetails({
            flow_type: 'Confirm Email',
            methodType: 'Email',
          })
        );
        return;
      }

      const response = {
        success,
        data: {
          errorCode: 8005,
        },
      };
      throw this.rootStore.accountSettingsApi.handleError(response);
    } catch (error: any) {
      this.rootStore.biLogger.report(
        postLoginVerifyDetails({
          error_info: error,
          flow_type: 'Confirm Email',
          methodType: 'Email',
        })
      );
      throw this.rootStore.accountSettingsApi.handleError(error);
    }
  }
}
