import { TextButton } from '@wix/design-system';
import { ChevronLeft } from '@wix/wix-ui-icons-common';
import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface IBackTextButton {
  goBack: () => void;
}

export const BackTextButton: React.FC<IBackTextButton> = observer(
  ({ goBack }) => {
    const { t } = useTranslation();
    return (
      <TextButton skin="dark" prefixIcon={<ChevronLeft />} onClick={goBack}>
        {t('multiFactorAuth.addAuthApp.codeModal.backButton')}
      </TextButton>
    );
  }
);
