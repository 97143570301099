import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { AppContextProvider } from '../AppLoader';
import { EnterEmail, EnterEmailContextProvider } from '../EnterEmail';
import { dataHooks } from '../../dataHooks';

export const SsoEnterEmail: React.FC = observer(() => {
  const {
    rootStore: { ssoEnterEmailStore },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  return (
    <EnterEmailContextProvider.Provider value={{ store: ssoEnterEmailStore }}>
      <EnterEmail
        data-hook={dataHooks.sso.enterEmailDialog.container}
        title={t('sso.enterEmailPage.title')}
        subTitle={t('sso.enterEmailPage.subtitle')}
        backButtonCb={() => ssoEnterEmailStore.onBackButtonClicked()}
      />
    </EnterEmailContextProvider.Provider>
  );
});
