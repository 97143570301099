import { observable, makeObservable, action } from 'mobx';
import { SEARCH_PARAMS } from '../utils/constants';
import { RootStore } from './root';

export const SSO_PROVIDERS = {
  YELL: 'YELL',
};

export class UserDataStore {
  private readonly rootStore: RootStore;
  public email: string;
  public currentLoggedInEmail: string;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.currentLoggedInEmail = window.__CURRENT_LOGGED_IN_USER_EMAIL__;
    this.email = this.getInitialEmailValue();
    makeObservable(this, {
      email: observable,
      setEmail: action.bound,
    });
  }

  setEmail(email: string) {
    this.email = email;
  }

  private getInitialEmailValue() {
    const emailFromUrl =
      this.rootStore.navigationStore.getQueryParam(SEARCH_PARAMS.EMAIL) ||
      this.rootStore.navigationStore.getQueryParam(
        SEARCH_PARAMS.DEFAULT_EMAIL,
      ) ||
      '';
    return emailFromUrl.replace(/ /g, '+');
  }
}
