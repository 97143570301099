import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { ConfirmCodeStep } from './ConfirmCodeStep';
import { AddPhoneStep } from './AddPhoneStep';
import { AppContextProvider } from '../../../AppLoader';
import { Steps } from '../../../../stores/premiumUsers2FaEnforcement/sms/types';
import { EnforcementModal } from '../EnforcementModal';

export const AddPhoneModal: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: {
        premiumUsers2FaEnforcementStore: { add2FAPhoneStore: store },
      },
    },
  } = useContext(AppContextProvider);
  return (
    <EnforcementModal>
      {store.activeStep === Steps.AddPhoneStep ? (
        <AddPhoneStep />
      ) : (
        <ConfirmCodeStep />
      )}
    </EnforcementModal>
  );
});
