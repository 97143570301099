import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Box } from '@wix/design-system';
import { ThemedText } from '../ThemedComponents';
import { ActionElementStore } from '../../stores/actionElement';
import {
  StatusAlertSmall,
  StatusCompleteSmall,
} from '@wix/wix-ui-icons-common';
import s from './ActionElement.scss';

const MESSAGE_TIMEOUT = 3000;

type ActionElementProps = {
  store: ActionElementStore;
  statusMessageDatahook?: string;
  hideDelay?: number;
  children?: React.ReactElement;
};

const ActionElement: React.FC<ActionElementProps> = observer(
  ({ children, store, statusMessageDatahook, hideDelay = MESSAGE_TIMEOUT }) => {
    useEffect(() => {
      if (store.showMessage) {
        setTimeout(() => {
          store.onHideMessage();
        }, hideDelay);
      }
    }, [store, store.showMessage, hideDelay]);
    const isError = store.status === 'error';
    return (
      <Box direction="vertical" gap={2} width="100%" align="center">
        {children}
        {store.showMessage && (
          <ThemedText
            dataHook={statusMessageDatahook}
            skin={store.status}
            className={s.statusText}
          >
            {isError ? <StatusAlertSmall /> : <StatusCompleteSmall />}
            {store.messageContent}
          </ThemedText>
        )}
      </Box>
    );
  },
);

export default ActionElement;
