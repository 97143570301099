import React from 'react';
import { Box, CopyClipboard, Input, TextButton } from '@wix/design-system';
import { observer } from 'mobx-react';
import { DuplicateSmall, ConfirmSmall } from '@wix/wix-ui-icons-common';
import { useTranslation } from 'react-i18next';

interface CopyToClipboardProps {
  inputText?: string;
  copyText?: string;
  copiedText?: string;
  onCopy?(): void;
}

export const CopyToClipboard: React.FC<CopyToClipboardProps> = observer(
  ({ inputText, copiedText, copyText, onCopy }) => {
    const { t } = useTranslation();
    copiedText = copiedText ?? t('copyToClipboard.copiedText');
    copyText = copyText ?? t('copyToClipboard.copyText');
    const onCopyClicked = (copyToClipboard: any) => {
      copyToClipboard();
      onCopy && onCopy();
    };
    return (
      <CopyClipboard value={inputText!} resetTimeout={1500}>
        {({ isCopied, copyToClipboard }) => (
          <Input
            disableEditing
            value={inputText}
            suffix={
              <Box verticalAlign="middle" marginRight="SP1">
                <TextButton
                  skin={isCopied ? 'dark' : 'standard'}
                  onClick={() => onCopyClicked(copyToClipboard)}
                  size="small"
                  prefixIcon={
                    isCopied ? (
                      <Box color="G10">
                        <ConfirmSmall />
                      </Box>
                    ) : (
                      <DuplicateSmall />
                    )
                  }
                >
                  {!isCopied ? copyText : copiedText}
                </TextButton>
              </Box>
            }
          />
        )}
      </CopyClipboard>
    );
  }
);
