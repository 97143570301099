import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { AppContextProvider } from '../AppLoader';

export const Recaptcha: React.FC<{ id?: string }> = observer(({ id }) => {
  const {
    rootStore: {
      captchaStore: { RECAPTCH_ENTERPRISE_ELEMENT_ID },
    },
  } = useContext(AppContextProvider);
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center' }}
      id={id ?? RECAPTCH_ENTERPRISE_ELEMENT_ID}
    ></div>
  );
});
