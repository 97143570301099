import { action, makeObservable, observable } from 'mobx';
import { NotificationStore } from '../utils/notification';
import { Constraint } from '../utils/validators';
import { RootStore } from './root';
import { postLoginChangeYourPasswordDetails } from '@wix/bi-logger-post-login/v2';
import { ERROR_CODES } from '../utils/errorHandler';
import { PasswordPolicy } from '@wix/ambassador-iam-password-v1-password/build/cjs/types.impl';
import { PasswordFormField } from './passwordFormField';

export class ManagePasswordStore {
  public isPasswordStrengthShown: boolean = false;
  public isPasswordVisible: boolean = false;
  public isLogoutMode: boolean = false;
  public passwordStrengthScore: PasswordStrength;
  public currentPassword: PasswordFormField;
  public newPassword: PasswordFormField;
  public confirmPassword: PasswordFormField;
  public passwordPolicy: PasswordPolicy | undefined;
  public forgotPasswordNotification: NotificationStore = new NotificationStore();
  public saving = false;
  public saved = false;
  private readonly rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      isPasswordStrengthShown: observable,
      isPasswordVisible: observable,
      isLogoutMode: observable,
      passwordStrengthScore: observable,
      currentPassword: observable,
      newPassword: observable,
      confirmPassword: observable,
      forgotPasswordNotification: observable,
      saving: observable,
      saved: observable,
      passwordPolicy: observable,
      onCloseChangePasswordModal: action,
      onTogglePasswordStrength: action,
      onTogglePasswordVisibility: action,
      updatePasswordStrengthScore: action,
      onChangePassword: action.bound,
      onSetPassword: action.bound,
      onForgotPassword: action.bound,
      clearInputs: action,
      onNewPasswordFieldsChange: action.bound,
    });

    const passwordMatchRule: Constraint = [
      (str: string) => str === this.newPassword.value,
      this.rootStore.i18n.t('validation.password.match'),
    ];
    this.currentPassword = new PasswordFormField('');
    this.newPassword = new PasswordFormField(
      '',
      undefined,
      this.validateNewPassword,
    );
    this.confirmPassword = new PasswordFormField('', [passwordMatchRule]);
    this.updatePasswordStrengthScore('');
  }

  public validateNewPassword = async (newPassword: string) => {
    if (this.passwordPolicy) {
      const res = await this.rootStore.passwordService.validateNewPassword(
        this.passwordPolicy,
        newPassword,
      );
      return res;
    }
  }

  public fetchPasswordPolicy = async () => {
    const shouldForcePasswordPolicy = this.rootStore.experiments.enabled(
      'specs.ident.ShouldForcePasswordPolicy'
    );
    if (shouldForcePasswordPolicy) {
      this.passwordPolicy = await this.rootStore.passwordService.getPasswordPolicy();
    }
  };

  onNewPasswordFieldsChange() {
    if (!this.passwordPolicy) {
      this.newPassword.triggerValidator();
    }
    this.confirmPassword.triggerValidator();
  }

  public onChangePasswordAndLogoutRequest() {
    this.isLogoutMode = true;
  }

  public onCloseChangePasswordModal() {
    this.isPasswordStrengthShown = false;
    this.updatePasswordStrengthScore('');
  }

  public onTogglePasswordStrength(state: boolean) {
    this.isPasswordStrengthShown = state;
  }

  public onTogglePasswordVisibility(state: boolean) {
    this.isPasswordVisible = state;
  }

  public updatePasswordStrengthScore(newPassword?: string) {
    this.passwordStrengthScore =
      this.rootStore.passwordStrengthExtractor.getPasswordStrength(
        newPassword ?? this.newPassword.value,
      );
  }

  public showLater = () => {
    this.rootStore.biLogger.report(
      postLoginChangeYourPasswordDetails({
        button: 'Not Now',
      }),
    );
    this.rootStore.postLoginStore.showLater();
  };

  public onChangePassword = async () => {
    if (!this.isSubmittable('change')) {
      return;
    }
    const interactionName = 'change-password';
    this.rootStore.fedopsLogger.interactionStarted(interactionName);

    try {
      this.saving = true;
      await this.rootStore.accountSettingsApi.updatePassword(
        this.currentPassword.value,
        this.newPassword.value,
      );
      this.rootStore.fedopsLogger.interactionEnded(interactionName);

      this.saving = false;
      this.saved = true;

      this.rootStore.biLogger.report(
        postLoginChangeYourPasswordDetails({
          button: 'Next',
          flow_type: 'Change',
        }),
      );

      this.rootStore.postLoginStore.offerSucceed();
    } catch (error: any) {
      this.saving = false;
      const errorCode =
        error?.data?.errorCode ||
        error?.response?.data?.errorCode ||
        error?.response?.data?.details?.applicationError?.code;
      // Incorrect password
      if (errorCode === ERROR_CODES.PASSWORD_INCORRECT || errorCode === ERROR_CODES.IAM_PASSWORD_INCORRECT) {
        this.rootStore.fedopsLogger.interactionEnded(interactionName);
      }
      const errorMessage = this.rootStore.accountSettingsApi.errorHandling(
        errorCode,
      );
      const errorInfo = this.rootStore.i18n.t(errorMessage);

      this.rootStore.biLogger.report(
        postLoginChangeYourPasswordDetails({
          button: 'Next',
          flow_type: 'Change',
          error_info: errorMessage,
        }),
      );

      this.currentPassword.addError(errorInfo);
    }
  };

  public async onSetPassword() {
    if (!this.isSubmittable('create')) {
      return;
    }
    this.rootStore.fedopsLogger.interactionStarted('set-password');

    try {
      this.saving = true;
      await this.rootStore.accountSettingsApi.updatePassword(
        this.newPassword.value,
        this.confirmPassword.value,
      );
      this.saving = false;
      this.saved = true;

      this.rootStore.fedopsLogger.interactionEnded('set-password');
      this.rootStore.biLogger.report(
        postLoginChangeYourPasswordDetails({
          button: 'Next',
          flow_type: 'Create',
        }),
      );
      this.rootStore.postLoginStore.offerSucceed();
    } catch (error: any) {
      const errorCode =
        error?.response?.data?.errorCode || error?.data?.errorCode;
      const errorMsg =
        this.rootStore.accountSettingsApi.errorHandling(errorCode);
      this.rootStore.biLogger.report(
        postLoginChangeYourPasswordDetails({
          button: 'Next',
          flow_type: 'Create',
          error_info: errorMsg,
        }),
      );
      const errorInfo = this.rootStore.i18n.t(errorMsg);
      this.newPassword.addError(errorInfo);
    }
  }

  public async onForgotPassword() {
    this.rootStore.fedopsLogger.interactionStarted('forgot-password');

    try {
      await this.rootStore.accountSettingsApi.forgotPass(
        this.rootStore.postLoginStore.userDetails.email,
      );
      this.forgotPasswordNotification.open({
        content: this.rootStore.i18n.t(
          'changePassword.forgotPassword.notification.successful',
        ),
        theme: 'success',
        onClose: () => {
          this.forgotPasswordNotification.isOpen = false;
        },
      });
      this.rootStore.fedopsLogger.interactionEnded('forgot-password');
      this.rootStore.biLogger.report(
        postLoginChangeYourPasswordDetails({
          button: 'Forgot password',
        }),
      );
    } catch (error: any) {
      const errorCode =
        error?.response?.data?.errorCode || error?.data?.errorCode;
      const errorMessage =
        this.rootStore.accountSettingsApi.errorHandling(errorCode);
      const errorInfo = this.rootStore.i18n.t(errorMessage);
      this.rootStore.biLogger.report(
        postLoginChangeYourPasswordDetails({
          button: 'Forgot password',
          error_info: errorMessage,
        }),
      );
      this.forgotPasswordNotification.open({
        content: errorInfo,
        theme: 'destructive',
      });
    }
  }

  public clearInputs() {
    this.currentPassword.clear();
    this.newPassword.clear();
    this.confirmPassword.clear();
    this.isPasswordVisible = false;
    this.isLogoutMode = false;
  }

  public isSubmittable(type: string): boolean {
    if (type === 'change') {
      return !!(
        this.currentPassword.isValid &&
        this.newPassword.isValid &&
        this.newPassword.value === this.confirmPassword.value
      );
    }
    return !!(
      this.newPassword.isValid &&
      this.newPassword.value === this.confirmPassword.value
    );
  }
}
