import { getMyUserProfile } from '@wix/ambassador-data-services-profile-client-v4-profile-field-data/http';
import {
  GetMyUserProfileRequest,
  GetMyUserProfileResponse,
  ProfileValue,
} from '@wix/ambassador-data-services-profile-client-v4-profile-field-data/types';
import { HttpClient, HttpResponse } from '@wix/http-client';
import { isNumber } from 'lodash';

const USER_GROUPS_FIELD_NAME = 'user_groups';

export class BiProfileServiceApi {
  constructor() {
    this.handleError = this.handleError.bind(this);
  }

  public getProfileData = async ({
    fields,
  }: {
    fields: string[];
  }): Promise<GetMyUserProfileResponse> => {
    const request: GetMyUserProfileRequest = {
      fields,
    };

    const httpClient = new HttpClient();
    let result: HttpResponse<GetMyUserProfileResponse> | null = null;
    try {
      result = await httpClient.request(getMyUserProfile(request));
    } catch (e) {
      console.error('Failed to get profile data', e);
    }
    return result!.data;
  };

  public async getUserGroups(): Promise<ProfileValue[]> {
    const result = await this.getProfileData({
      fields: [USER_GROUPS_FIELD_NAME],
    });
    const userGroups = result.fields?.[USER_GROUPS_FIELD_NAME] as {
      manyValues?: { values: ProfileValue[] };
    };
    return userGroups?.manyValues?.values || [];
  }

  public async getUserProfile(
    profileName: string
  ): Promise<ProfileValue | undefined> {
    const result = await this.getProfileData({
      fields: [profileName],
    });
    const profile = result.fields?.[profileName] as {
      aSingleValue?: ProfileValue;
    };
    return profile?.aSingleValue;
  }

  public handleError(response: any) {
    const errorCode =
      response?.response?.data?.errorCode ||
      response?.data?.errorCode ||
      response?.data?.reason ||
      response?.reason;
    const errorReason = this.errorHandling(errorCode);

    return { success: false, errorCode, errorReason };
  }

  public errorHandling(
    errorCode: number | string,
    defaultMsg = 'ops_something_went_wrong'
  ) {
    const errorCodeStr =
      errorCode && isNumber(errorCode) ? errorCode.toString() : errorCode;
    switch (errorCodeStr) {
      default:
        return defaultMsg;
    }
  }
}
