import { Box, Loader, Text } from '@wix/design-system';
import { StatusCompleteSmall } from '@wix/wix-ui-icons-common';
import React from 'react';
import { useTranslation } from 'react-i18next';

export enum VerificationState {
  Unverified = 0,
  Verifying = 1,
  Verified = 2,
}

const VerifyButton = ({ verificationState }) => {
  const { t } = useTranslation();
  switch (verificationState) {
    case VerificationState.Unverified:
      return (
        <Text size="small" weight="normal" light>
          {t('verify_button')}
        </Text>
      );
    case VerificationState.Verifying:
      return (
        <Box gap={1}>
          <Loader size="tiny"></Loader>
          <Text size="small" weight="normal" light>
            {t('verifying_button')}
          </Text>
        </Box>
      );
    case VerificationState.Verified:
      return (
        <Box gap={1}>
          <StatusCompleteSmall />
          <Text size="small" weight="normal" light>
            {t('verified_button')}
          </Text>
        </Box>
      );
    default:
      return (
        <Text size="small" weight="normal" light>
          {t('add_phone_wizard.enter_code.verify_button')}
        </Text>
      );
  }
};

export default VerifyButton;
