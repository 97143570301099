import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { Box, FormField, RadioGroup, Text } from '@wix/design-system';
import { AppContextProvider } from '../../../AppLoader';
import { useTranslation } from 'react-i18next';
import { dataHooks } from '../../../../dataHooks';

export const ChooseDeviceStep: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: { ownerAppStore: store },
    },
  } = useContext(AppContextProvider);
  const { t } = useTranslation();
  return (
    <Box padding="18px 24px 0px 24px">
      <FormField
        label={
          <Box marginBottom="6px">
            <Text weight="normal">{t('post.login.oa.2fa.choose.label')}</Text>
          </Box>
        }
        statusMessage={
          <Box marginTop="18px">
            <Text>{t('post.login.oa.2fa.choose.message')}</Text>
          </Box>
        }
      >
        <Box>
          <RadioGroup
            dataHook={dataHooks.ownerAppModal.options}
            value={store.selectedDevice?.id}
            onChange={(id?: string | number) => store.onSelectDevice(id!)}
          >
            {store.devices.map(({ id, name }) => (
              <RadioGroup.Radio key={id} value={id}>
                <Text>{name}</Text>
              </RadioGroup.Radio>
            ))}
          </RadioGroup>
        </Box>
      </FormField>
    </Box>
  );
});
