import { Constraint, generateEmailFieldConstraints } from '../utils/validators';
import { FormField } from './formField';
import { action, makeObservable, observable, reaction } from 'mobx';
import { RootStore } from './root';
import { getErrorTemplate } from '../components/EnterEmail/EnterEmail';

export abstract class EnterEmailStore {
  public emailField: FormField;
  protected readonly rootStore: RootStore;
  abstract submit(): void;
  titleKey: string;
  abstract facebookWith2FaNotificationOpen: boolean;
  abstract goToLoginWithEmail(email: string): void;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    const { i18n } = this.rootStore;
    const emailRules: Constraint[] = generateEmailFieldConstraints(
      i18n.t.bind(i18n),
    );
    this.emailField = new FormField('', emailRules);
    makeObservable(this, {
      emailField: observable,
      titleKey: observable,
      onBackButtonClicked: action.bound,
    });
    this.reactToAppLoaded();
  }

  private reactToAppLoaded() {
    reaction(
      () => {
        return this.rootStore.isLoaded;
      },
      () => this.rootStore.isLoaded && this.appLoadedCallback(),
      { fireImmediately: true },
    );
  }

  private appLoadedCallback() {
    this.emailField.value = this.rootStore.userDataStore.email;
  }

  public hasError(): boolean {
    return !!(this.emailField.errorInfo || this.emailField.serverError);
  }

  public onBackButtonClicked() {
    const { isNewLoginApp, navigationStore } = this.rootStore;
    if (isNewLoginApp) {
      return navigationStore.goBack();
    }
    navigationStore.goToMainLogin({
      email: this.emailField.value,
    });
  }

  public handleError(errorKey: string, errorCode?: string) {
    this.emailField.serverError = getErrorTemplate(
      this.rootStore.i18n,
      errorKey,
      errorCode,
      this.onBackButtonClicked,
    );
  }
}
