import React, { useContext } from 'react';
import { Modal, Text, MessageModalLayout } from '@wix/design-system';
import { observer } from 'mobx-react';
import { AppContextProvider } from '../AppLoader';

export const ConfirmationModal: React.FC = observer(() => {
  const {
    rootStore: {
      postLoginStore: { confirmationModalStore: store },
    },
  } = useContext(AppContextProvider);
  return (
    <Modal
      shouldCloseOnOverlayClick
      isOpen={store.isOpen}
      onRequestClose={() => store.onClose()}
      contentLabel={store.title}
    >
      <MessageModalLayout
        theme={store.newStyleTheme}
        onCloseButtonClick={() => store.onX()}
        primaryButtonText={store.okTitle}
        secondaryButtonText={store.cancelTitle}
        primaryButtonOnClick={() => store.onOk!(store.close)}
        secondaryButtonOnClick={
          store.cancelTitle ? () => store.onClose() : undefined
        }
        title={store.title}
        sideActions={store.sideActions}
        className={store.className}
      >
        <Text>{store.content}</Text>
      </MessageModalLayout>
    </Modal>
  );
});
