import { makeObservable, observable, action, reaction } from 'mobx';
import { Device } from '../services/DeviceRegistry';
import { ApprovalChallengeStatus } from '@wix/ambassador-identity-v1-verification/types';
import { PostLoginStore } from './postLogin';
import { postLoginOwnerApp2FaOfferUserHasBackupMethodSrc5Evid9510 } from '@wix/bi-logger-identity-data/v2';
import {
  BI_BUTTON_NAMES,
  BI_SCREEN_NAMES,
  RESEND_INDICATION_TTL_TIMER,
} from './constants';
import { throttle } from 'lodash';
import { waitsFor } from './utils';
import { Step, StepDefinitions } from '../types';
import { DealerApi } from '../services/dealerApi';

export class OwnerAppStore {
  public devices: Device[] = [];
  public selectedDevice: Device;
  public authStatus: ApprovalChallengeStatus =
    ApprovalChallengeStatus.PENDING_APPROVAL;
  public activeStep: Step = Step.DeviceSelection;
  public resendErrorMessage: string;
  private verificationId: string;
  private timer: NodeJS.Timer;
  public resendSuccessfullyIndication: boolean = false;
  public resendThrottleTimerValue: number = RESEND_INDICATION_TTL_TIMER / 1000;
  public dealerApi: DealerApi = new DealerApi();

  constructor(private postLoginStore: PostLoginStore) {
    makeObservable(this, {
      devices: observable,
      selectedDevice: observable,
      authStatus: observable,
      activeStep: observable,
      resendErrorMessage: observable,
      resendSuccessfullyIndication: observable,
      resendThrottleTimerValue: observable,
      onSelectDevice: action,
      enable: action.bound,
      clear: action.bound,
      sendPushToSelectedDevice: action.bound,
      confirmationOKAction: action.bound,
      closeConfirmationModal: action.bound,
      showConfirmationModal: action.bound,
      sendBiEvent: action.bound,
      postponeOffer: action.bound,
      gotoStep: action.bound,
    });
    this.reactToResend();
    this.reactToAuthStatus();
  }

  public async init() {
    this.devices = await this.fetchUserDevices();
    this.selectedDevice = this.devices[0];
  }

  private reactToAuthStatus() {
    reaction(
      () => this.authStatus,
      async () => {
        if (this.authStatus !== ApprovalChallengeStatus.APPROVED) {
          return;
        }
        try {
          await this.enable();
          this.activeStep = Step.Successful;
          return;
        } catch (e) {
          this.postLoginStore.notificationStore.open({
            content: this.postLoginStore.i18n.t(
              'post.login.oa.2fa.error.general'
            ),
            theme: 'error',
          });
        }
      }
    );
  }

  public async enable() {
    await this.postLoginStore.accountSettingsApi.OwnerApp.enable(
      this.verificationId,
      this.selectedDevice?.id
    );
    this.dealerApi.sendDealerMainCtaEvent(
      this.postLoginStore.postLoginOffer.offerGuid,
      this.postLoginStore.targetUrl
    );
    this.clear();
  }

  public clear() {
    this.selectedDevice = this.devices?.[0];
    this.authStatus = ApprovalChallengeStatus.PENDING_APPROVAL;
    this.activeStep = Step.DeviceSelection;
  }

  public fetchUserDevices = async () =>
    this.postLoginStore.deviceRegistry.fetchUserDevices(
      this.postLoginStore.userDetails.guid
    );

  onSelectDevice(id: string | number) {
    this.selectedDevice = this.devices.find((device) => device.id === id)!;
  }

  async sendPushToSelectedDevice(resend?: boolean) {
    try {
      if (this.timer) {
        clearInterval(this.timer);
      }
      if (resend) {
        this.resendSuccessfullyIndication = true;
        this.sendBiEvent(BI_BUTTON_NAMES.RESEND_LOGIN_REQUEST);
      }
      const res =
        await this.postLoginStore.accountSettingsApi.sendVerificationCode({
          deviceId: this.selectedDevice?.id,
        });
      this.verificationId = res.data.verificationId;
      this.checkOwnerAuthStatus(this.verificationId);
    } catch (e) {
      if (resend) {
        return (this.resendErrorMessage = this.postLoginStore.i18n.t(
          'post.login.oa.2fa.error.resend'
        ));
      }
      this.postLoginStore.notificationStore.open({
        content: this.postLoginStore.i18n.t('post.login.oa.2fa.error.general'),
        theme: 'error',
      });
    }
  }

  async checkOwnerAuthStatus(verificationId: string) {
    const TIME_OUT_EXPIRED = 150000;
    const interval = 2000;
    this.authStatus = ApprovalChallengeStatus.PENDING_APPROVAL;

    this.timer = setInterval(async () => {
      const result =
        await this.postLoginStore.accountSettingsApi.OwnerApp.getStatus(
          verificationId
        );
      const status = result.challenge?.approvalChallenge?.status;
      this.authStatus = status ?? ApprovalChallengeStatus.PENDING_APPROVAL;
      if (status !== ApprovalChallengeStatus.PENDING_APPROVAL) {
        clearInterval(this.timer);
      }
    }, interval);

    setTimeout(() => {
      clearInterval(this.timer);
    }, TIME_OUT_EXPIRED);
  }

  public goToConfirmationStep = async () => {
    await this.sendPushToSelectedDevice();
    this.sendBiEvent(BI_BUTTON_NAMES.NEXT, this.selectedDevice.name);
    this.activeStep = Step.Confirmation;
  };

  public confirmationOKAction(close) {
    this.sendBiEvent(BI_BUTTON_NAMES.CANCEL_SETUP);
    this.postLoginStore.proceedToPostAuthUrl(
      'owner app confirmation button clicked'
    );
    this.activeStep = Step.Confirmation;
    close();
  }

  public closeConfirmationModal(biButton: string) {
    this.sendBiEvent(biButton);
    this.activeStep = Step.Confirmation;
    this.postLoginStore.confirmationModalStore.close();
  }

  public showConfirmationModal = (biButtonName: string) => {
    this.sendBiEvent(biButtonName);
    this.postLoginStore.confirmationModalStore.open({
      title: this.postLoginStore.i18n.t('post.login.oa.2fa.confirm.exit.title'),
      content: this.postLoginStore.i18n.t(
        'post.login.oa.2fa.confirm.exit.message'
      ),
      okTitle: this.t('post.login.oa.2fa.cancel.setup'),
      onOk: (close) => this.confirmationOKAction(close),
      onClose: () => this.closeConfirmationModal(BI_BUTTON_NAMES.BACK),
      onX: () => this.closeConfirmationModal(BI_BUTTON_NAMES.X_BUTTON),
      cancelTitle: this.t('post.login.back'),
      newStyleTheme: 'standard',
    });
  };

  public sendBiEvent(buttonName: string, value?: string) {
    this.postLoginStore.biLogger.report(
      postLoginOwnerApp2FaOfferUserHasBackupMethodSrc5Evid9510({
        screen_name: this.getBiScreenName(),
        button_name: buttonName,
        value,
      })
    );
  }

  public postponeOffer(buttonName: string) {
    this.sendBiEvent(buttonName);
    this.dealerApi.sendDealerShowLaterEvent(
      this.postLoginStore.postLoginOffer.offerGuid
    );
    this.postLoginStore.proceedToPostAuthUrl('postponed offer');
  }

  public gotoStep(step: Step, buttonName: string) {
    this.sendBiEvent(buttonName);
    this.activeStep = step;
  }

  public getBiScreenName(): string {
    if (this.postLoginStore.confirmationModalStore.isOpen) {
      return BI_SCREEN_NAMES.CANCEL_SETUP;
    }
    switch (this.activeStep) {
      case Step.DeviceSelection:
        return BI_SCREEN_NAMES.CHOOSE_DEVICE;
      case Step.Confirmation:
        switch (this.authStatus) {
          case ApprovalChallengeStatus.DECLINED:
            return BI_SCREEN_NAMES.LOGIN_REQUEST_DENIED;
          case ApprovalChallengeStatus.EXPIRED:
            return BI_SCREEN_NAMES.FAILED_TO_CONFIRM_DEVICE;
          case ApprovalChallengeStatus.PENDING_APPROVAL:
            return BI_SCREEN_NAMES.CONFIRM_DEVICE;
          default:
            return '';
        }
      default:
        return '';
    }
  }

  private t = (key: string) => this.postLoginStore.i18n.t(key);

  steps: StepDefinitions = {
    [Step.DeviceSelection]: {
      primaryButtonText: this.t('post.login.oa.2fa.primary'),
      primaryButtonAction: this.goToConfirmationStep,
      secondaryButtonText: this.t('post.login.oa.2fa.secondary'),
      secondaryButtonAction: () =>
        this.postponeOffer(BI_BUTTON_NAMES.MAYBE_LATER),
      modalHeight: 432,
    },
    [Step.Confirmation]: {
      primaryButtonText: this.t('post.login.oa.2fa.confirm.primary'),
      primaryButtonAction: () =>
        this.gotoStep(Step.DeviceSelection, BI_BUTTON_NAMES.BACK),
      secondaryButtonText: this.t('post.login.oa.2fa.confirm.secondary'),
      secondaryButtonAction: () =>
        this.showConfirmationModal(BI_BUTTON_NAMES.CANCEL),
    },
    [Step.Successful]: {
      modalHeight: 372,
    },
  };

  stopPolling() {
    clearInterval(this.timer);
  }

  get hasConnectedDevices() {
    return this.devices.length > 0;
  }

  public getActiveStepName() {
    return Object.keys(Step)[Object.values(Step).indexOf(this.activeStep)];
  }

  private setResendThrottleTimer = throttle(async () => {
    const ticks = new Array(RESEND_INDICATION_TTL_TIMER / 1000).fill('');
    Promise.all(
      ticks.map(async (_, i) => {
        await waitsFor(i * 1000);
        this.resendThrottleTimerValue = ticks.length - i;
        if (ticks.length === i + 1) {
          await waitsFor(1000);
          this.resendSuccessfullyIndication = false;
        }
      })
    );
  }, RESEND_INDICATION_TTL_TIMER);

  private reactToResend() {
    reaction(
      () => this.resendSuccessfullyIndication,
      () => {
        if (!this.resendSuccessfullyIndication) {
          return;
        }
        return this.setResendThrottleTimer();
      }
    );
  }
}
