import { emailRegex, phoneRegex } from '@wix/wix-js-validations';

type ValidationFunc = (str: string) => boolean;
type ErrorMessage = string;

export type Validation = string[] | boolean | undefined;
export type Constraint = [ValidationFunc, ErrorMessage];

const regex =
  (reg: RegExp): ValidationFunc =>
  (str: string) =>
    reg.test(str);
const minimum =
  (num: number): ValidationFunc =>
  (str: string) =>
    !!str && str.length >= num;
const maximum =
  (num: number): ValidationFunc =>
  (str: string) =>
    !!str && str.length <= num;
const required = minimum(1);
const email = regex(emailRegex);
const phone = regex(phoneRegex);

export const validate =
  (roles: Constraint[]) =>
  (str: string): (undefined | string)[] | boolean => {
    if (!roles || roles.length === 0) {
      return false;
    }
    const validated = roles.map(([validationFunction, errorMessage]) =>
      validationFunction(str) ? undefined : errorMessage
    );
    // Clear valid items
    const clearedValidated = validated.filter((role) => role);
    return clearedValidated.length > 0 ? clearedValidated : false;
  };

export const constraints = {
  email,
  minimum,
  maximum,
  regex,
  required,
  phone,
};
