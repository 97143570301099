import React, { useContext } from 'react';
import { AppContextProvider } from '../AppLoader';
import {
  Button,
  Heading,
  HeadingProps,
  Text,
  TextButton,
  TextButtonProps,
  TextProps,
  ButtonProps,
} from '@wix/design-system';
import { withTextTheme, withSkinTheme } from './WithTheme';

export const ThemedButton = withSkinTheme<ButtonProps>(Button as any);
export const ThemedTextButton = withSkinTheme<TextButtonProps>(TextButton);
export const ThemedText = withTextTheme<TextProps>(Text);
export const ThemedHeading = withTextTheme<HeadingProps>(Heading);

const skinToInputLabelProps: { [key: string]: Omit<TextProps, 'tagName'> } = {
  standard: {
    light: true,
    secondary: true,
  },
  light: {
    light: true,
  },
  dark: {
    skin: 'standard',
  },
};
export const ThemedInputLabel: React.FC<TextProps> = ({
  children,
  ...props
}) => {
  const {
    rootStore: { displayStore },
  } = useContext(AppContextProvider);
  return (
    <ThemedText
      {...props}
      {...(skinToInputLabelProps[displayStore.preset.skin ?? 'standard'] ?? {})}
    >
      {children}
    </ThemedText>
  );
};
