import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@wix/design-system';
import { AppContextProvider } from '../../AppLoader';
import PostLoginModal from '../PostLoginModal';
import { ChooseVerificationMethodContent } from './ChooseVerificationMethodContent';

export const ChooseVerificationMethodModal: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    rootStore: {
      postLoginStore: { premiumUsers2FaEnforcementStore: store },
    },
  } = useContext(AppContextProvider);

  return (
    <PostLoginModal
      minHeight="100px"
      removeContentPadding={true}
      width={546}
      height={store.modalHeight}
      title={t('premium.users.2fa.enforcement.title')}
      subtitle={t('premium.users.2fa.enforcement.subtitle')}
      primaryButtonText={store.primaryButtonText}
      primaryButtonAction={store.primaryButtonAction}
      footerText="premium.users.2fa.enforcement.footer"
      footerLink={`https://support.wix.com/${store.postLoginStore.rootStore.language.locale}/article/secure-your-account-with-2-step-verification`}
      footerWeight="normal"
      disableLogoClick={true}
      marginTop={10}
    >
      <Box>
        <ChooseVerificationMethodContent />
      </Box>
    </PostLoginModal>
  );
});
