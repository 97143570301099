import React from 'react';
import { Box, Loader } from '@wix/design-system';
import { dataHooks } from '../../dataHooks';
import { observer } from 'mobx-react';
import { DialogContainer } from '../DialogContainer';

export const LoadingDialog: React.FC = observer(() => {
  return (
    <DialogContainer
      dataHook={dataHooks.loadingDialog.container}
      mainHeaderProps={{ showBackButton: false }}
    >
      <Box height="100%" direction="vertical" align="center" marginTop="10%">
        <Loader size="large" />
      </Box>
    </DialogContainer>
  );
});
