import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Box } from '@wix/design-system';
import { dataHooks } from '../../dataHooks';
import { ROUTES } from '../../routes';
import { ILoginFlows, LOGIN_FLOWS } from '../../stores/login';
import { keys } from '../../translationsKeys';
import { AppContextProvider } from '../AppLoader';
import { AuthPage, AuthPageContextProvider } from '../AuthPage/AuthPage';
import { ButtonWithLoader } from '../ButtonWithLoader/ButtonWithLoader';
import {
  InputFieldWithLabel,
} from '../InputField/InputField';
import { PasswordFieldWithLabel } from '../PasswordField/PasswordField';
import { PoliciesContainer } from '../PoliciesContainer';
import { Recaptcha } from '../Recaptcha/Recaptcha';
import { ThemedText, ThemedTextButton } from '../ThemedComponents';
import s from './Login.scss';
import { PASSWORD_MIN_LENGTH_OLD } from '../../utils/validators';

const Login: React.FC = observer(() => {
  const {
    rootStore: {
      displayStore: { isMobile },
    },
  } = useContext(AppContextProvider);

  if (isMobile) {
    return <Mobile />;
  }
  return <Desktop />;
});

const LoginWrapper: React.FC = observer(({ children }) => {
  const {
    rootStore: {
      loginStore,
      displayStore: { isMobile },
    },
  } = useContext(AppContextProvider);

  return (
    <AuthPageContextProvider.Provider value={{ store: loginStore }}>
      <Box
        direction="vertical"
        width="100%"
        align="center"
        marginBottom={!isMobile ? '40px' : undefined}
      >
        <AuthPage
          container={{
            dataHook: dataHooks.login.container,
            mainHeaderProps: {
              showBackButton: loginStore.flow === LOGIN_FLOWS.FROM_SIGNUP,
            },
          }}
        >
          <AuthPage.Header dataHooks={dataHooks.login}>
            <ThemedText>
              <LoginSubTitle flow={loginStore.flow} />
            </ThemedText>
          </AuthPage.Header>
          {children}
        </AuthPage>
        <AuthPage.Policies>
          <PoliciesContainer.Minimal />
        </AuthPage.Policies>
      </Box>
    </AuthPageContextProvider.Provider>
  );
});

const Mobile: React.FC = observer(() => {
  return (
    <LoginWrapper>
      <AuthPage.MobileBody>
        <LoginForm gap={3} marginTop={9} />
      </AuthPage.MobileBody>
    </LoginWrapper>
  );
});

const Desktop: React.FC = observer(() => {
  return (
    <LoginWrapper>
      <AuthPage.Body>
        <LoginForm />
      </AuthPage.Body>
    </LoginWrapper>
  );
});

const LoginForm: React.FC<{ gap?: number; marginTop?: number }> = observer(
  ({ gap, marginTop }) => {
    const {
      rootStore: {
        loginStore,
        emailStepStore,
        displayStore: { sideBySideForm, isMobile, socialButtonsWidth },
      },
    } = useContext(AppContextProvider);
    const { t } = useTranslation();
    const shouldFocusEmail = !loginStore.emailField.value?.length;
    return (
      <Box direction={sideBySideForm.direction} width={sideBySideForm.width}>
        <Box
          width={socialButtonsWidth}
          direction="vertical"
          verticalAlign="middle"
          gap={gap}
          marginTop={marginTop}
        >
          <InputFieldWithLabel
            required
            autoFocus={shouldFocusEmail}
            formField={loginStore.emailField}
            label={t(keys['login.email.label'])}
            type="email"
            autocomplete="email"
            clearButton
            onClear={() => loginStore.clearEmail()}
            marginBottom={0}
            dataHook={dataHooks.login.emailInput}
            border="bottomLine"
          />
          <PasswordFieldWithLabel
            required
            autoFocus={!shouldFocusEmail}
            formField={loginStore.passwordField}
            label={t(keys['login.password.label'])}
            autocomplete="current-password"
            showVisibility={!!loginStore.passwordField.value.length}
            value={loginStore.passwordField.value}
            marginBottom={0}
            dataHook={dataHooks.login.passwordInput}
            minLength={PASSWORD_MIN_LENGTH_OLD}
            border="bottomLine"
            onEnterPressed={loginStore.login}
          />
          <ThemedTextButton
            className={s.a11yFocus}
            onClick={loginStore.onClickForgotPassword}
            dataHook={dataHooks.login.forgotPassword}
            size="small"
            underline="always"
            as="a"
            href={emailStepStore.getAccountRecoveryLink('password')}
          >
            {t(keys['login.forgotPassword.button'])}
          </ThemedTextButton>
          <Box
            marginTop={4}
            gap={1}
            direction="vertical"
            align={!isMobile ? 'left' : undefined}
          >
            <Recaptcha />
            <ButtonWithLoader
              className={classNames(s.a11yFocus, s.border, s.submitButton)}
              type="submit"
              onClick={loginStore.login}
              dataHook={dataHooks.login.submitButton}
              showLoader={loginStore.isLoading}
              skin={isMobile ? 'standard' : 'inverted'}
              fullWidth={isMobile}
            >
              {t(keys['login.submit'])}
            </ButtonWithLoader>
          </Box>
        </Box>
      </Box>
    );
  },
);

const LoginSubTitle: React.FC<{ flow: ILoginFlows }> = ({ flow }) => {
  const location = useLocation();
  const {
    rootStore: { loginStore },
  } = useContext(AppContextProvider);
  const shouldBlockSignup = loginStore.shouldBlockEditorXSignup();
  if (!shouldBlockSignup && flow === LOGIN_FLOWS.DEFAULT) {
    return (
      <ThemedText>
        <Trans i18nKey={keys['emailStep.login.subTitle']}>
          Want to create an account?
          <ThemedTextButton
            as={Link}
            to={ROUTES.SIGNUP_PASSWORD + location.search}
            dataHook={dataHooks.login.switchToSignupBtn}
            onClick={() => loginStore.reportSwitchToSignup()}
            skin="standard"
          >
            Sign Up
          </ThemedTextButton>
        </Trans>
      </ThemedText>
    );
  }

  return <></>;
};

export default Login;
