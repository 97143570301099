export const getEmailDomain = (email: string) => {
  const atIndex = email.lastIndexOf('@');
  return email.substring(atIndex + 1);
};

export const waitForElement = (predicate: () => HTMLCollectionOf<Element>) => {
  return new Promise((resolve) => {
    if (predicate().length) {
      return resolve(true);
    }

    const observer = new MutationObserver(() => {
      if (predicate().length) {
        resolve(true);
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

export const appendSearchParamsToUrl = (url: string, params: any) => {
  try {
    const _url = new URL(url);
    Object.keys(params).forEach((key) =>
      _url.searchParams.set(key, params[key])
    );
    return _url.toString();
  } catch (_) {}
  return url;
};

export const concatParameterToUrl = (
  url: string,
  paramName: string,
  paramValue?: string
) => {
  if (!paramValue) {
    return url;
  }
  try {
    const urlWithParam = new URL(url);
    urlWithParam.searchParams.set(paramName, paramValue);
    return urlWithParam.toString();
  } catch (_) {
    return url;
  }
};

export const isAnswersUrl = (url: string): boolean => {
  const answersPrefixes = [
    'https://www.wix.com/wix-support-apps-webapp/',
    'https://manage.wix.com/wix-support-apps-webapp/',
  ];
  return answersPrefixes.some(
    (answersPrefix) => url.indexOf(answersPrefix) === 0
  );
};

export const postMessageToParent = (message: { [key: string]: any }) => {
  if (!window.parent) {
    return;
  }
  window.parent.postMessage(message, '*');
};
